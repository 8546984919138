import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledAction, StyledContents } from "baseui/card";
import { Input } from "baseui/input";
import { Notification } from "baseui/notification";
import { Label3, Paragraph1 } from "baseui/typography";
import firebase from 'firebase';
import React from "react";
import UserApi from '../../firebase/UserApi';
import TagManager from 'react-gtm-module';

 const greenStyle = {
  "paddingLeft": '15px',
  "marginBottom": '-15px',
  "marginTop": '35px',
  "color": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
}
const MAX_VERIFICATION_TRIES = 5;


const AuthFlow = (props) => {
  const [error, setError] = React.useState(undefined);
  const [success, setSuccess] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [authStep, _setAuthStep] = React.useState({ history: ["isExistingUser"], current: "isExistingUser" });
  const setAuthStep = (newState) => _setAuthStep({ history: [...authStep.history, newState], current: newState });
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verificationTries, setVerificationTries] = React.useState(0);

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.href,
        title: document.title
      }
    });
  }, []);

  const components = {
    "isExistingUser": Login,
    "requestResetPassword": RequestResetPassword,
    "sorry": Sorry

  };

  const setUI = (func) => {
    setError(undefined);
    setIsLoading(true);
    return func();
  };

  const goBack = () => {
    _setAuthStep({
      history: authStep.history.slice(0, -1),
      current: authStep.history[authStep.history.length - 2],
    });
  }

  const fbCatch = (e) => {
    setIsLoading(false);
    setError(e.message);
    return Promise.reject();
  };

  const checkEmail = () => {
    if (props.firebase.emailIsVerified) {
      setEmail(window.localStorage.getItem("emailForSignIn"))
      console.log(email)
      setAuthStep("isNewUser");
      setIsLoading(false);
    } else {
      firebase.auth()
        .fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
          setIsLoading(false);
          const isNewUser = signInMethods.length === 0;
          if (isNewUser) setAuthStep("isNewUser");
          else login();
        })
        .catch(fbCatch);
    }
  }

  const login = () => {
    firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => setIsLoading(false))
      .catch(fbCatch);
  }

  const requestResetPassword = () => {
    return firebase.auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setIsLoading(false);
        setPassword(undefined);
        setTimeout(() => setAuthStep("isExistingUser"), 5000);
        return Promise.resolve();
      })
      .catch(fbCatch);
  }

  const showRequestResetPassword = () => {
    setError(undefined);
    setAuthStep("requestResetPassword");
  };

  const AuthStep = components[authStep.current];
  const internalProps = {
    showRequestResetPassword,
    email,
    setEmail,
    password,
    setPassword,
    error,
    login: () => setUI(login),
    checkEmail: () => setUI(checkEmail),
    requestResetPassword: () => setUI(requestResetPassword),
    isLoading,
    firebase: props.firebase
  };

  return (
    <Block width='100%' maxWidth="400px" margin="auto">
      <AuthStep {...internalProps}>
      </AuthStep>
      <Error errorMessage={error} />
    </Block>
  );
};

const Error = ({ errorMessage }) => < > { errorMessage ? <Notification kind='negative'>{() => errorMessage}</Notification> : null } < />;
const Success = ({ successMessage }) => < > { successMessage ? <Notification kind='positive'>{() => successMessage}</Notification> : null } < />;

const Login = ({ email, setEmail, password, setPassword, login, showRequestResetPassword, isLoading }) => (
  <>
  <Label3 $style = { greenStyle } > Login < /Label3>
  <StyledContents >
    <Input placeholder='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
    <Input placeholder = 'password' type = 'password' value = { password } onChange = { (e) => setPassword(e.target.value) } />
    <Button overrides = { { BaseButton: { style: { width: "100%", marginTop: "10px" } } } } style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick = { () => login() } isLoading = { isLoading } >
      Login
    </Button>
  </StyledContents>
  <StyledAction>
    <Button onClick={() => showRequestResetPassword()} style={{ backgroundColor: "#eda107", color: "#FFFFFF" }}>
      Forgot Password
    </Button> </StyledAction>
  </>
);

const RequestResetPassword = ({ email, setEmail, requestResetPassword, isLoading, children }) => {
  const [notification, setNotification] = React.useState(undefined);
  return ( <
    >
    <
    Label3 $style = { greenStyle } > Recover Password < /Label3> <
    StyledContents >
    <Paragraph1>Get instructions sent to this email that will explain how to reset your password</Paragraph1> <
    Input placeholder = 'email'
    type = 'email'
    value = { email } onChange = {
      (e) => setEmail(e.target.value) }
    /> { children }
    <Button
          overrides={{ BaseButton: { style: { width: "75%", marginTop: "10px", backgroundColor: "#eda107", color: "#FFFFFF" } } }}
          onClick={() => requestResetPassword().then(() => setNotification("Email Sent"))} isLoading={isLoading} disabled={!!notification}>
          Send
        </Button> <
    /StyledContents> { notification ? <Notification>{() => notification}</Notification> : null } <
    />
  );
};


const Sorry = ({ password, setPassword, login, showRequestResetPassword, isLoading, children }) => ( <
  >
  <
  Label3 $style = { greenStyle } > Hmm...we 're having an issue verifying your email address. Please ensure it is spelled correctly. If you'
  re still having trouble, please wait a little
  while and
  try again. < /Label3> <
  />
);

export default AuthFlow;