import { END_CONFIRM_MODAL, SET_BILLING_STATUS, SET_ERROR, SET_LOADING, SET_MENU_OPEN, START_CONFIRM_MODAL } from '../actions/ui';

function getDefaultState() {
    return {
        loading: true,
        billing: { status: 'active' },
        confirmingAction: false,
        error: false,
        menuOpen: false,
    };
}

const ui = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.open
            };
        case SET_BILLING_STATUS:
            return {
                ...state,
                billing: action.billing,
            }
        case START_CONFIRM_MODAL:
            return {
                ...state,
                confirmingAction: {
                    text: action.text,
                    actionToDispatch: action.actionToDispatch,
                    actionToDispatchCancel: action.actionToDispatchCancel
                },
            };
        case END_CONFIRM_MODAL:
            return {
                ...state,
                confirmingAction: false,
            };
        case SET_ERROR:
            return { ...state, error: action.error }
        default:
            return state;
    }
}

export default ui;