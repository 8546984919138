import { Button } from 'baseui/button';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAdminMode } from '../../actions/ui';
import BillingApi from '../../firebase/BillingApi';
import UserApi from '../../firebase/UserApi';
import { PROFILE_LINK } from '../flows/linkConstants';

export const UserDetails = ({ userDetails, setAdminMode }) => {
    const history = useHistory();
    const [makeAdminLoading, setMakeAdminLoading] = React.useState(false);

    if (!userDetails.label) {
        return <></>;
    }

    return <div>
        <Button onClick={() => {
            setAdminMode(true, userDetails)
            history.push(PROFILE_LINK)
        }}>Log in as</Button>

        <Button onClick={() => {
            UserApi.copyDataToMine();
        }}>Make data mine</Button>
        <Button onClick={(e) => BillingApi.pay()}>Pay</Button>
        <Button isLoading={makeAdminLoading} onClick={(e) => {
            setMakeAdminLoading(true);
            UserApi.makeAdmin(userDetails?.id).then(e => setMakeAdminLoading(false)).catch(e => setMakeAdminLoading(false))
        }}>Make Administrator</Button>
    </div>
}

export default connect(state => ({}), {
    setAdminMode
})(UserDetails);