import { Button } from 'baseui/button';
import { Delete, Plus } from 'baseui/icon';
import { Input, StatefulInput } from 'baseui/input';
import {
    TableBuilder,
    TableBuilderColumn
} from 'baseui/table-semantic';
import { Label1, Label2, Label3 } from "baseui/typography";
import React from 'react';
import DateInput from './DateInput';

const ExpenseTable = ({ id, setAnswer, currentAnswer, endDateText, omitMonthly }) => {
    let data = currentAnswer;

    function sanitizeStringToNumber(value) {
        return Number(Number(value.replace('$', '').replace(',', '')).toFixed())
    }

    function setMonthly(value, index) {
        value = sanitizeStringToNumber(value)
        if (isNaN(value)) return;
        let newData = [...data];
        newData[index] = { ...newData[index], monthly: value, yearly: value * 12 }
        setAnswer(newData)
    }

    function setYearly(value, index) {
        value = sanitizeStringToNumber(value)
        if (isNaN(value)) return;

        let newData = [...data];
        newData[index] = { ...newData[index], monthly: Number((value / 12).toFixed(0)), yearly: value }
        setAnswer(newData)
    }

    function setEndDate(value, index) {
        let newData = [...data];
        newData[index] = { ...newData[index], endDate: value }
        setAnswer(newData)
    }

    function addExpense() {
        if (currentAnswer.find(value => value.inputName === "")) return; //if there is already a blank one don't add another
        let newData = [
            ...data,
            {
                monthly: 0,
                yearly: 0,
                endDate: null,
                name: ''
            }
        ];
        setAnswer(newData)
    }

    function setExpenseName(value, index) {
        let newData = [...data];
        newData[index].name = value;
        setAnswer(newData);
    }

    function deleteExpense(deductionIndex) {
        let newData = data.filter((_, index) => index !== deductionIndex);
        setAnswer(newData);
    }

    const SpecialMonthlyInput = ({ index }) => {
        const [value, setValue] = React.useState(undefined);
        return <div style={{ minWidth: 125 }}><Input placeholder="Enter Monthly Amount" type="number" value={value !== undefined ? value : data[index].monthly}
            onChange={e => {
                setValue(e.target.value)
            }}
            startEnhancer="$"
            onFocus={(e) => {
                if (e.target.value === '0') {
                    setValue('')
                }
            }}
            onBlur={e => {
                setValue(undefined)
                setMonthly(e.target.value, index)
            }} /></div>
    }

    const SpecialYearlyInput = ({ index }) => {
        const [value, setValue] = React.useState(undefined);
        return <div style={{ minWidth: 125 }}><Input placeholder="Enter Monthly Amount" type="number" value={value !== undefined ? value : data[index].yearly}
            onChange={e => {
                setValue(e.target.value)
            }}
            startEnhancer="$"
            onFocus={(e) => {
                if (e.target.value === '0') {
                    setValue('')
                }
            }}
            onBlur={e => {
                setValue(undefined)
                setYearly(e.target.value, index)
            }} /></div>
    }

    return (
        <div>
            <TableBuilder data={data}>
                <TableBuilderColumn header="Name">
                    {(row, index) =>
                        !data[index].default
                            ? <StatefulInput placeholder="Enter expense name" initialState={{ value: data[index].name }} onBlur={(e) => setExpenseName(e.target.value, index)} />
                            : <div style={{ width: 200 }}>{row.name}</div>
                    }
                </TableBuilderColumn>
                {
                    !omitMonthly ?
                        <TableBuilderColumn header="Monthly">
                            {(row, index) => {
                                return <SpecialMonthlyInput index={index} />
                            }}
                        </TableBuilderColumn>
                        :
                        undefined
                }
                <TableBuilderColumn header="Yearly">
                    {(row, index) => {
                        return <SpecialYearlyInput index={index} />// <Input placeholder="Enter Yearly Amount" value={'$' + data[index].yearly} onChange={(e) => setYearly(e.target.value, index)} />
                    }}
                </TableBuilderColumn>
                {endDateText ? <TableBuilderColumn header={endDateText}>
                    {(row, index) => {
                        return <DateInput placeholder={"Enter " + endDateText} value={data[index].endDate} updateInput={(e) => setEndDate(e, index)} />
                    }}
                </TableBuilderColumn> : ''}
                {data.some(expenses => !expenses.default)
                    ? <TableBuilderColumn>
                        {(row, index) =>
                            !data[index].default
                                ? <Button style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick={() => deleteExpense(index)} shape="pill"><Delete /></Button>
                                : null}
                    </TableBuilderColumn>
                    : null}
            </TableBuilder>
            <Button onClick={() => addExpense()} startEnhancer={Plus} shape="pill" style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} overrides={{ BaseButton: { style: () => ({ float: "right" }) } }}>Add an Expense</Button>
            <br />
            <br />

        </div>
    );
}

function makeDefaultValues() {
    let list = ["HOA Dues", "Utilities", "TV/Int/phone", "Cell Phone", " Insurance: Auto", "Auto Fuel",
        "Auto Maintenance", "Groceries", "Prescriptions", "Vacation/Travel", "Entertainment (Dining, etc.)",
        "Charity", "Memberships", "Health and Beauty", "Gifts (birthday, Christmas, etc.)",
        "Pet Costs", "Miscellaneous", "Discretionary"];

    return list.map(elem => ({ name: elem, monthly: 0, yearly: 0, default: true }))
}

function makeDefaultShortTermDebtsValues() {
    let values = ["College Loan:", "Credit Card:", "Credit Card:", "Credit Card:", "Credit Card:", "Car Loan:", "Car Loan:", "Personal Loan:", "Other:"];

    return values.map(elem => ({ name: elem, monthly: 0, yearly: 0, endDate: null, default: true }))
}

export const makeExpense = (name, amount, deductible, endDate) => {
    let expense = { name, amount: Number(amount), deductible };
    if (endDate) {
        expense['endDate'] = endDate
    }
    return expense;
}

export const GeneralExpenses = (props) => {
    let passIn = { ...props };

    let defaultExpenses = makeDefaultValues();

    if (!passIn.currentAnswer) {
        passIn.currentAnswer = makeDefaultValues()
    }

    passIn.currentAnswer.map(elem => {
        if(elem.name == "Dependent Support") {
            elem.name = "Health and Beauty";
        }
    });

    return <ExpenseTable {...passIn} />
}

export const ShortTermDebts = (props) => {
    let passIn = { ...props };
    if (!passIn.currentAnswer) {
        passIn.currentAnswer = makeDefaultShortTermDebtsValues()
    }
    return <div style={{ textAlign: 'center' }}>
        <Label1>Below are typical debts held by households.
            Enter the balance and the approximate monthly payment.</Label1>
        <Label2>Additionally, consider any temporary expenses that will end at some time in the future. </Label2>
        <Label3 $style={{ fontStyle: 'italic' }}>Example: Bundle expenses associated with your job that will end when you retire
        (Gas for commuting, parking, tolls, dues, dining out for lunch, etc.).
        </Label3>

        <Label3 color='negative'>Do not include credit cards that you pay off each month</Label3>
        <ExpenseTable {...passIn} endDateText="Pay Off Date / End Date" />
    </div>
}