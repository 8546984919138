import moment from "moment";
import { AIMEVals, exemptionAmounts, FRAValues, widowValues, YOCValues, YOCReductionValues, FRAMonths } from "./CalculationData";

export const formatter = new Intl.NumberFormat('en', { style: 'decimal', currency: 'USD', maximumFractionDigits: 0 });
export const formatterDecimal = new Intl.NumberFormat('en', { style: 'decimal', currency: 'USD', maximumFractionDigits: 2, minimumSignificantDigits: 2 });

class SocialSecurityCalculator {
    constructor(userType, answers) {
        let oppositeType = (userType === 'main' ? 'spouse' : 'main');
        this.maritalStatus = answers.maritalStatus[0].id;
        this.fraOptions = {
            fraAmount: answers[userType + 'FRABenefit'],
            spousalFraAmount: answers[oppositeType + 'FRABenefit'],
            substantialYears: answers[userType + 'WorkedForNonCovered'] == false ? answers[userType + 'NumYearsSubstantialEarnings'] : 30
        }

        this.workedForNonCovered = answers[userType + 'WorkedForNonCovered'];

        this.fraAmount = Number(this.fraOptions.fraAmount);
        this.takingAmount = answers[userType + 'Taking'] ? Number(answers[userType + 'TakingAmount']) * 12 : undefined;
        this.spousalFraAmount = answers.maritalStatus[0].id === 'married' ? Number(this.fraOptions.spousalFraAmount) : Number(answers.divorcedMarriageHighestBenefit);
        this.userName = answers[userType + 'UserName']
        this.spouseName = answers[oppositeType + 'UserName']
        this.retireDate = answers[userType + 'RetireDate']
        this.pensionDetails = answers[userType + 'PensionTable'] && answers[userType + 'WorkedForGovernment'] && answers[userType + 'PensionTable'].length > 0 ? answers[userType + 'PensionTable'][0] : { isGPO: true, amount: 0 }
        this.currentSalary = answers[userType + 'IsEmployed'] ? Number(answers[userType + 'GrossIncome']) : 0;
        this.grossIncome = answers[userType + 'IsEmployed'] ? Number(answers[userType + 'GrossIncome']) : 0;
        this.alimony = answers[userType + "AlimonyTable"];
        this.otherIncome = answers[userType + "extraIncomeTable"];

        this.dob = answers[userType + 'Dob'];
        this.userDob = answers[userType + 'Dob'];

        this.deceasedSpouseDob = answers["deceasedSpouseDob"];

        this.yearBorn = this.getYearBorn();
        // this.dob = "01/01/" + this.yearBorn;

        this.fraYear = this.getUserFRAYear();
        this.ageDifference = new Date(answers[userType + 'Dob']).getFullYear() - new Date(answers[oppositeType + 'Dob']).getFullYear()
        this.deceasedFRABenefit = answers.passedAwaySpouseBenefit;
        this.divorcedFraAmount = answers.divorcedMarriageHighestBenefit;
        this.currentYear = moment().format("YYYY");
        this.currentMoment = new moment();
        this.chosenOption = answers[userType + 'SocialSecurity']
        this.fraYearIndex = "";
    }

    getSSTakeAmountAndDate() {
        let returnData = this.chosenOption || {};

        if (this.takingAmount) {
            returnData['takeAmountAnnual'] = this.takingAmount;
            returnData['takeDate'] = new Date();
        }



        return returnData;
    }

    getBdayMoment() {
        return moment(this.dob, 'MM/DD/YYYY');
    }

    getYearBorn() {
        return moment(this.dob, 'MM/DD/YYYY').year();
    }

    getYearBornForDob(userDob) {
        return moment(userDob, 'MM/DD/YYYY').year();
    }

    getMonthBorn() {
        return moment(this.dob, 'MM/DD/YYYY').month();
    }

    getAgeAfter70Year() {
        let userDate = moment(this.dob, "MM/DD/YYYY");

        let ageInYear = userDate.add("70", "year");

        return ageInYear.add(1, "day");
    }

    getAgeAfter70YearForDecease(userDob) {
        return moment(userDob, "MM/DD/YYYY").add("70", "year");
    }

    getCurrentAgeYears() {
        return Math.floor(this.currentMoment.diff(moment(this.dob, 'MM/DD/YYYY'), 'years', true));
    }

    getCurrentAgeMonths() {
        return Math.floor(this.currentMoment.diff(moment(this.dob, 'MM/DD/YYYY'), 'month', true));
    }

    getBenefitStartingInYear(ageStart, currentAge, spousal, showETest, ageIndex) {
        let correctFRAAmount = spousal ? this.maritalStatus === 'divorced' ? this.divorcedFraAmount : this.spousalFraAmount : this.fraAmount;
        let lowerBenefit = this.adjustFRAForMonths(ageStart, Number(correctFRAAmount), spousal, ageIndex) * 12;

        //TODO possible for bruce case, add in year
        let pensionAdjusted = Math.max(this.adjustForGovernment(lowerBenefit, currentAge + this.yearBorn, spousal), 0);
        let postEarningsTest = Math.max(this.earningsTestAnnual(pensionAdjusted, currentAge + this.yearBorn), 0);

        if (this.takingAmount !== undefined) {
            pensionAdjusted = this.takingAmount;
            postEarningsTest = this.takingAmount;
            lowerBenefit = this.takingAmount;
        }

        return {
            amount: showETest ? postEarningsTest / 12 : pensionAdjusted / 12,
            months: this.getMonthBenefitStartingInYear(pensionAdjusted / 12),
            amountYearly: pensionAdjusted,
            pensionReduction: lowerBenefit - pensionAdjusted,
            earningsTestReduction: pensionAdjusted - postEarningsTest,
            raw: lowerBenefit,
            age: currentAge,
            ageYear: currentAge + this.yearBorn,
            postEarningsTest: postEarningsTest / 12,
            exemptionAmounts: this.getExemptionAmounts(this.currentYear)
        }
    }

    getBenefitInYear(ageStart, currentAge, benefitYearData, spousal, calendarYear, userFRA, showETest, monthIndex) {
        let userFraYear = userFRA.year();
        let userFraMonth = userFRA.month();

        let userFraAmount = benefitYearData != undefined ? benefitYearData[0].amount : 0;

        let amountYearly = 0;

        let benefitYearDetail = 0;

        if(benefitYearData != undefined) {
            benefitYearDetail = moment(benefitYearData[0].date, "X").year();

            benefitYearData.map((element) => {
                let benefitYear = moment(element.date, "X").year();
                let benefitMonth = moment(element.date, "X").month();

                // if(monthIndex == undefined && Number(userFraYear) == Number(benefitYear) && Number(userFraMonth) == Number(benefitMonth)) {
                //     userFraAmount = element.amount;
                // }

                if(monthIndex && Number(element.index) == Number(monthIndex)) {
                    userFraAmount = element.amount;
                }


                amountYearly += element.amount;
            })
        }

        let fra_months = [];
        let earningTestApplied = false;

        if(benefitYearData != undefined) {
            for (let i = 0; i < benefitYearData.length; i += 4) {
                benefitYearData.forEach((res) => {
                    if(res.bg_color == "yellow") {
                        earningTestApplied = true;
                    }
                })

                fra_months.push(benefitYearData.slice(i, i + 4));
            }
        }

        let pensionAdjusted = 0;
        let postEarningsTest = 0;

        if (this.takingAmount !== undefined) {
            pensionAdjusted = this.takingAmount;
            postEarningsTest = this.takingAmount;
            amountYearly = this.takingAmount;
        }

        if(benefitYearData == undefined) {
            userFraAmount = 0;
            benefitYearData = [1];
        }

        return {
            amount: userFraAmount,
            months: fra_months,
            amountYearly: showETest ? userFraAmount * parseInt(benefitYearData.length) : pensionAdjusted,
            pensionReduction: 0,
            earningsTestReduction: 0,
            raw: amountYearly,
            monthSize: benefitYearData.length,
            rawMonth: amountYearly / parseInt(benefitYearData.length),
            age: currentAge,
            ageYear: benefitYearDetail,
            postEarningsTest: postEarningsTest / parseInt(benefitYearData.length),
            earningTestApplied: earningTestApplied,
            exemptionAmounts: this.getExemptionAmounts(this.currentYear)
        }
    }

    CalculateSocialSecurity(amount, percentage) {
        let percentageCount = (Number(amount) * percentage) / 100;

        return (Number(amount) + Number(percentageCount, 0))
    }

    getMonthBenefitStartingInYear(amount) {
        let months = [];

        let arrayIndex = { 0: [1, 2, 3, 4], 1: [5, 6, 7, 8], 2: [9, 10, 11, 12] };

        for (let i = 0; i < 3; i++) {
            let partOfMonths = [];

            for(let j = arrayIndex[i][0]; j <= arrayIndex[i][arrayIndex[i].length - 1]; j++) {
                partOfMonths.push({ name: FRAMonths[j].name, amount: this.CalculateSocialSecurity(amount, FRAMonths[j].percentage) })
            }

            months.push(partOfMonths);
        }

        return months;
    }

    getBenefitForAge(age, spousal, showETest, ageIndex) {
        return this.getBenefitStartingInYear(age, age, spousal, showETest, ageIndex);
    }

    // This gets the cumulative amount taken, and allows us to show break even points.
    getCumulativeTaking(startYear, amountTakingYearly, numYears = 40) {
        let output = [];
        let cumulative = 0;
        let currentBase = amountTakingYearly;
        for (let i = 0; i < numYears; i++) {
            let postEarningsTest = Math.max(this.earningsTestAnnual(currentBase, startYear + i), 0);
            cumulative += postEarningsTest;
            output.push({ amount: cumulative });

            currentBase *= 1.02;
        }
        return output;
    }

    getCumulativeDataForChoice(startYear, choice, numYears = 40) {
        let output = [];
        let cumulative = 0;

        for (let i = 0; i < numYears; i++) {
            let yearlyAmount = choice.getYearlyAmount(startYear + i);
            let postEarningsTest = Math.max(this.earningsTestAnnual(yearlyAmount, startYear + i), 0);
            cumulative += postEarningsTest;
            output.push({ amount: cumulative });
        }
        return output;
    }

    getWidowedCumulativeDataForChoice(startYear, choice, numYears = 40) {
        let output = [];
        let survivorCumulative = 0;
        let mainCumulative = 0;

        for (let i = 0; i < numYears; i++) {
            let { yearlyAmount, type } = choice.getYearlyAmount(startYear + i);
            let postEarningsTest = Math.max(this.earningsTestAnnual(yearlyAmount, startYear + i), 0);

            if (type === 'survivor') {
                survivorCumulative += postEarningsTest;
            } else {
                mainCumulative += postEarningsTest;
            }
            output.push({ 'survivorAmount': survivorCumulative, 'mainAmount': mainCumulative })

        }
        return output;
    }

    getFRAAge() {
        if (this.yearBorn <= 1937)
            return FRAValues[1937];
        else if (this.yearBorn >= 1960)
            return FRAValues[1960];
        else
            return FRAValues[this.yearBorn];
    }

    getFRAAgeByYearBorn(yearBorn) {
        if (yearBorn <= 1937)
            return FRAValues[1937];
        else if (yearBorn >= 1960)
            return FRAValues[1960];
        else
            return FRAValues[yearBorn];
    }

    getFRAMoment() {
        let bday = moment(this.dob);
        let fraAge = this.getFRAAge();
        bday.add(fraAge.years, 'years');
        bday.add(fraAge.months, 'months');
        return bday;
    }

    getFRAYear() {
        return this.getFRAMoment().year();
    }

    getUserFRAMoment() {
        let bday = moment(this.userDob);
        let fraAge = this.getFRAAge();
        bday.add(fraAge.years, 'years');
        bday.add(fraAge.months, 'months');
        return bday;
    }

    getAddedFRAMoment(userDob) {
        let bday = moment(userDob);
        let userYearBorn = parseInt(bday.year());

        let fraAge = this.getFRAAgeByYearBorn(userYearBorn);
        bday.add(fraAge.years, 'years');
        bday.add(fraAge.months, 'months');
        bday.add("12", 'hour');
        bday.add("59", 'minutes');
        bday.add("59", 'seconds');
        return bday;
    }

    getUserFRAYear() {
        return this.getUserFRAMoment().year();
    }

    getUserFRAMonth() {
        return this.getUserFRAMoment().month();
    }


    getExemptionAmounts(yearFiled) {
        //Determine which year to use (since we won't know tax rates 20 years in the future).
        return this.getYearOrLastKnownDataFromDict(yearFiled, exemptionAmounts);
    }

    getCurrentSalary(monthDate, fraDate) {
        let currentSalary = this.currentSalary;

        if(this.alimony != undefined) {
            this.alimony.forEach((x, i) => {
                currentSalary += Number(x.amount);
            });
        }

        if(this.otherIncome != undefined) {
            this.otherIncome.forEach((x, i) => {
                currentSalary += Number(x.amount);
            })
        }

        let retirementDate = moment(this.retireDate, "MM/DD/YYYY").format("X");

        if(Number(monthDate) > Number(retirementDate) && Number(monthDate) < Number(fraDate)) {
            currentSalary = Number(currentSalary) - Number(this.grossIncome);
        }

        return currentSalary;
    }

    earningsTestAnnual(annualBenefit, monthDate, spouse, fraDate) {
        let month = moment(monthDate, "X").format("MM");

        let exemptAmounts = this.getExemptionAmounts(moment(monthDate, "X").year());
        let userFRADate = moment(fraDate, "MM/DD/YYYY").format("X");
        let retirementDate = moment(this.retireDate, "MM/DD/YYYY").format("X");

        if (monthDate < retirementDate || monthDate < userFRADate) {
            let currentSalary = this.getCurrentSalary(monthDate, userFRADate);

            if(Number(currentSalary) > Number(exemptAmounts.preFRA)) {
                let adjustedAGI = Math.max(Number(this.currentSalary) - Number(exemptAmounts.preFRA), 0);
                let panelty_amount = (adjustedAGI * 0.5) - (annualBenefit * (Number(month)));

                if(panelty_amount > 0) {
                    let adjustedBenefit = Number(annualBenefit) - Number(panelty_amount);
                    if(adjustedBenefit < 0) {
                        return [0, "yellow"];
                    } else {
                        return [Number(adjustedBenefit), "yellow"];
                    }
                } else {
                    return [Number(annualBenefit), ""];
                }
            } else {
                return [Number(annualBenefit), ""];
            }
        } else {
            return [Number(annualBenefit), ""];
        }
    }

    getFRAInMonths() {
        let fraDate = this.getUserFRAMoment();

        return Math.floor(fraDate.diff(moment(this.dob, 'MM/DD/YYYY'), 'month', true));
    }

    adjustFRAForMonths(age, spousalAdjustedFRAAmount, spousal, ageIndex) {
        let fraAge = this.getFRAAge();
        let monthsFRA = 12 * fraAge.years + fraAge.months;
        let ageInMonths = age === fraAge.years ? monthsFRA : 12 * age;

        let userAgeYear = this.yearBorn + age;

        if(this.fraYearIndex == "" && this.fraYear == userAgeYear) {
            this.fraYearIndex = ageIndex;
        }

        let months62 = 12 * 62;
        let months70 = 12 * 70;
        if (ageInMonths <= months62) {
            return this.getReduction(monthsFRA - months62, spousalAdjustedFRAAmount, monthsFRA, spousal, ageIndex, this.fraYearIndex, userAgeYear);
        } else if (ageInMonths > months62 && ageInMonths < monthsFRA) {
            return this.getReduction(monthsFRA - ageInMonths, spousalAdjustedFRAAmount, monthsFRA, spousal, ageIndex, this.fraYearIndex, userAgeYear);
        } else if (ageInMonths >= monthsFRA && ageInMonths <= months70) {
            return this.getAddition(ageInMonths - monthsFRA, spousalAdjustedFRAAmount, monthsFRA, spousal, ageIndex, this.fraYearIndex, userAgeYear)
        } else {
            return this.getAddition(months70 - monthsFRA, spousalAdjustedFRAAmount, monthsFRA, spousal, ageIndex, this.fraYearIndex, userAgeYear);
        }
    }

    doGPOReduction(spousalSSAmountAnnual, pensionAmt) {
        if (spousalSSAmountAnnual <= 0)
            return 0.0;
        if (pensionAmt <= 0)
            return spousalSSAmountAnnual;

        let pensionReduction = pensionAmt * 0.67;

        let netSSBenefit = spousalSSAmountAnnual - pensionReduction;

        if (netSSBenefit < 0)
            return 0.0;

        return netSSBenefit;
    }

    wepAdjustment(value) {
        let reduction = this.doWEPReduction(value, this.fraOptions.substantialYears)
        return reduction;
    }

    adjustForGovernment(fraAmount, year, spousal) {
        let startPensionYear = this.pensionDetails.startDate ? moment(this.pensionDetails.startDate, 'MM/DD/YYYY').year() : year;

        if (spousal) {
            if (this.pensionDetails.isGPO === false && year >= startPensionYear && this.workedForNonCovered == false) {
                return this.doGPOReduction(fraAmount, this.pensionDetails.amount / 12);
            } else {
                return fraAmount;
            }
        } else {
            if (this.pensionDetails.isGPO === false && year >= startPensionYear && this.workedForNonCovered == false) {
                return this.wepAdjustment(fraAmount);
            } else {
                return fraAmount;
            }
        }

        return fraAmount;
    }

    getYearOrLastKnownDataFromDict(yearFiling, dict) {
        let yearToLookup = yearFiling - 1;
        //Determine which year to use (since we won't know tax rates 20 years in the future).
        let value;
        if (yearToLookup in dict) {
            value = dict[yearToLookup];
        } else {
            //Take latest
            let possibleYears = Object.keys(dict).sort((a, b) => Number(a) - Number(b));
            value = dict[possibleYears[possibleYears.length - 1]];
        }
        return value;
    }

    getAIMEVals() {
        return this.getYearOrLastKnownDataFromDict(this.currentYear, AIMEVals)
    }

    calculateAIMEFromPIA(PIA) {
        let adjustedBenefit = PIA;
        // if (PIA < 864) {
        //     adjustedBenefit = 1.11 * PIA;
        // } else if (PIA < 2408) {
        //     adjustedBenefit = 960 + 3.125 * (PIA - 864);
        // } else {
        //     adjustedBenefit = 5785 + 6.667 * (PIA - 2408);
        // }

        // if (adjustedBenefit < .5 * PIA) {
        //     return .5 * PIA;
        // }

        return adjustedBenefit
    }

    doWEPReduction(AIME, yearsContributed) {
        let reductionAmount = 0;
        if (yearsContributed) {
            if (yearsContributed > 20 && yearsContributed < 30) {
                reductionAmount = YOCReductionValues[this.currentYear][yearsContributed];
            } else if (yearsContributed < 20) {
                reductionAmount = YOCReductionValues[this.currentYear][20];
            }
        }

        return Number(AIME) - Number(reductionAmount);
    }

    getFRAReduction(benefit, spousal, monthIndex, fraMonthIndex, scale, reductionPercent) {
        let pow = Number(monthIndex);
        // scale = 1 + (scale / 100);

        //Spousal Reduction
        if (spousal) {
            benefit = benefit * .5;
        }

        if(Number(monthIndex) == Number(fraMonthIndex)) {
            return benefit;
        }

        scale = reductionPercent[monthIndex];

        return benefit * Number(scale);
    }

    getFRAAddition(isFRA, benefit, spousal, scale, monthIndex, fraMonthIndex) {
        let pow = Number(monthIndex);
        // scale = (scale / 100);

        if(fraMonthIndex != "") {
            pow = Number(monthIndex) - Number(fraMonthIndex);
        }

        //Spousal Reduction
        if (spousal) {
            benefit = benefit * .5;

            return benefit;
        }

        if(Number(monthIndex) == Number(fraMonthIndex)) {
            return benefit;
        }

        let adjustBenefit = Number(benefit) + (Number(benefit) * (pow * scale))

        return adjustBenefit;
    }

    getReduction(monthsBeforeFRA, benefit, fraMonths, spousal, ageIndex, fraYearIndex, userAgeYear) {
        let pow = Number(ageIndex);
        let ageCounter = Number(this.fraYear) - Number(userAgeYear);

        let scale = 1 + (8 / 100);

        //Spousal Reduction
        if (spousal) {
            benefit *= .5;
        }

        if (monthsBeforeFRA <= 0) {
            return benefit;
        }

        const INITIAL_DEDUCTION = (spousal ? 25 / 36 : 5 / 9) * .01;
        const ADDITIONAL_DEDUCTION = 5 / 12 * .01;
        const MAX_MONTHS_BEFORE_FRA = fraMonths - 12 * 62;

        let baseReductionMonths;
        let additionalReductionMonths = 0;

        if (monthsBeforeFRA <= 36) {
            baseReductionMonths = monthsBeforeFRA;
        } else if (monthsBeforeFRA > 36 && monthsBeforeFRA < MAX_MONTHS_BEFORE_FRA) {
            baseReductionMonths = 36;
            additionalReductionMonths = monthsBeforeFRA - 36;
        } else {
            baseReductionMonths = 36;
            additionalReductionMonths = MAX_MONTHS_BEFORE_FRA - baseReductionMonths;
        }

        let percentReduction = INITIAL_DEDUCTION * baseReductionMonths + ADDITIONAL_DEDUCTION * additionalReductionMonths;

        return benefit - (benefit * percentReduction);
    }

    getAddition(monthsAfterFRA, benefit, fraMonths, spousal, ageIndex, fraYearIndex, userAgeYear) {
        let pow = Number(ageIndex);
        let scale = 1 + (8 / 100);

        if(this.fraYearIndex != "") {
            pow = Number(ageIndex) - Number(this.fraYearIndex);
        }

        //Spousal Reduction
        if (spousal) {
            return benefit * .5;
        }

        if (monthsAfterFRA <= 0) {
            return benefit;
        }

        const ADDITIONAL_PERCENT = 2 / 3 * .01;
        const MONTHS_70 = 70 * 12;
        const MAX_MONTHS_AFTER = MONTHS_70 - fraMonths;

        let additionalBenefitPercent = 0;
        if (monthsAfterFRA > (MAX_MONTHS_AFTER)) {
            additionalBenefitPercent = MAX_MONTHS_AFTER * ADDITIONAL_PERCENT;
        } else {
            additionalBenefitPercent = monthsAfterFRA * ADDITIONAL_PERCENT;
        }


        return benefit + (benefit * additionalBenefitPercent);
    }

    getSurvivorsBenefits(forAge) {
        if (forAge < 60) {
            return 0;
        } else if (forAge > 65) {
            return this.deceasedFRABenefit;
        } else {
            return widowValues[forAge] * this.deceasedFRABenefit;
        }
    }
}

export default SocialSecurityCalculator;