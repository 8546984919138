export const scrollTo = (element, to, duration) => {
    if (duration <= 0 || !element) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export const scrollTop = () => scrollTo(document.getElementById('pageBlock'), 0, 100);
export const scrollToError = (elementId) => scrollTo(document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' }), 0, 200);