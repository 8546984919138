import { Label2 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFlowStatus, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { INSURANCE_LINK, SOCIAL_BENEFITS_LINK } from '../flows/linkConstants';
import { validateInvestment } from '../flows/validate/investmentValidator';
import { ConnectedFlow } from './ConnectedFlow';

function typeChooserText() {
  return (
    <>
      <p><b>Types of money</b></p>
      <p>Non-Retirement (also known as 'Non-Qualifed' or 'NQ' pertains to after tax cash that you have accumulated; checking, savings, brokerage account, etc.</p>
      <p>Retirement (also known as 'Qualified or 'Q') pertains to tax deferred accounts; IRA, 401K, SEP, etc</p>
      <p>Roth (also known as 'Tax Free') pertains to Roth accounts (Roth IRA, Roth 401(k), etc.)</p><br /><br />
    </>
  );
}

function additionalAssetSubQuestions(isJoint = false) {
  return [
    {
      questions: [
        { id: 'type', type: 'investmentTypePicker', text: 'What type of account is this?', help: typeChooserText() },
      ],
      shouldShow: () => isJoint === false,
    },
    {
      questions: [
        { id: 'custodian', type: 'string', text: 'What is the name of the financial institution that holds this account?', help: 'Bank, Credit Union, Investent Firm, etc.' },
        { id: 'name', type: 'string', text: 'An easy-to-remember name for this account', help: 'e.g. Emergency Fund' },
        { id: 'amount', type: 'dollar', text: 'Current balance on this account', help: 'How much do you have in this account today?' },
      ],
      shouldShow: () => true,
    }
  ]
}

function rateOfReturnQuestions() {
  return [
    {
      questions: [
        { id: 'cashReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Cash Accounts:</b></p>, description: 'Cash Rate of Return', defaultValue: "1", maxWidth: "125px" },
        { id: 'rothReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Roth Accounts:</b></p>, description: 'Roth Rate of Return', defaultValue: "5", maxWidth: "125px" },
        { id: 'retirementReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Retirement Accounts:</b></p>, description: 'Retirement Rate of Return', defaultValue: "5", maxWidth: "125px" }
      ],
      shouldShow: () => true,
      text: <p>Below are our default <u>Annual Average Rates of Return</u> for your various accounts. Enter a different % at your discretion:</p>,
      descriptor: 'Return Rates'
    }
  ]
}

const PortfolioFlow = ({ maritalStatus, userName, spouseName, setFlowStatus, submitData, navigateErrors }) => {
  const history = useHistory();

  let sections = [
    {
      questions: [
        {
          id: 'jointInvestments', type: 'investmentTable', text: `Enter the balances for any Joint cash accounts: `,
          description: 'Joint Investment', joint: true, questionSections: additionalAssetSubQuestions(true),
        },
      ],
      shouldShow: () => maritalStatus === 'married',
      text: 'Enter any investment, checking, savings, or other accounts in your portfolio:',
      descriptor: 'Joint Investments'
    },
    {
      questions: [
        {
          id: 'mainInvestments', type: 'investmentTable', text: `Enter the balances for ${userName}'s personal investment/cash accounts:`,
          description: 'Individual Investment', questionSections: additionalAssetSubQuestions(false),
          explaination: <Label2 style={{ color: 'red' }}>DO NOT include the Income Annuity in the portfolio as we are calculating the <u>Income Value</u> beginning on your start date. Refer to the Annuity's annual policy statement for cash values.</Label2>,
        },
      ],
      shouldShow: (answers) => answers['mainAnnuity'] === true,
      descriptor: userName + '\'s Portfolio'
    },
    {
      questions: [
        { id: 'mainInvestments', type: 'investmentTable', text: `Enter the balances for ${userName}'s personal investment/cash accounts:`, description: 'Individual Investment', questionSections: additionalAssetSubQuestions(false) },
      ],
      shouldShow: (answers) => answers['mainAnnuity'] === false,
      descriptor: userName + '\'s Portfolio'
    },
    {
      questions: [
        { id: 'spouseInvestments', type: 'investmentTable', text: `Enter the balances for ${spouseName}'s personal investment/cash accounts`, description: 'Individual Investment', questionSections: additionalAssetSubQuestions(false) },
      ],
      shouldShow: (answers) => maritalStatus === 'married' && answers['spouseAnnuity'] === false,
      descriptor: spouseName + '\'s Portfolio'
    },
    {
      questions: [
        {
          id: 'spouseInvestments', type: 'investmentTable',
          text: `Enter the balances for ${spouseName}'s personal investment/cash accounts`,
          description: 'Individual Investment', questionSections: additionalAssetSubQuestions(false),
          explaination: <Label2 style={{ color: 'red' }}>DO NOT include the Income Annuity in the portfolio as we are calculating the <u>Income Value</u> beginning on your start date. Refer to the Annuity's annual policy statement for cash values.</Label2>,
        },
      ],
      shouldShow: (answers) => maritalStatus === 'married' && answers['spouseAnnuity'] === true,
      descriptor: spouseName + '\'s Portfolio'
    },
    {
      questions: [
        { id: 'cashReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Cash Accounts:</b></p>, description: 'Cash Rate of Return', defaultValue: "1", maxWidth: "125px" },
        { id: 'rothReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Roth Accounts:</b></p>, description: 'Roth Rate of Return', defaultValue: "5", maxWidth: "125px" },
        { id: 'retirementReturn', type: 'percentageOnly', text: <p>Enter the expected rate of return % on your <b>Retirement Accounts:</b></p>, description: 'Retirement Rate of Return', defaultValue: "5", maxWidth: "125px" }
      ],
      shouldShow: () => true,
      text: <p>Below are our default <u>Annual Average Rates of Return</u> for your various accounts. Enter a different % at your discretion:</p>,
      descriptor: 'Return Rates'
    }
  ]

  return <div style={{ width: '100%' }}>

    <ConnectedFlow
      questionSections={sections}
      flowMode={true}
      finishFlow={() => {
        history.push(SOCIAL_BENEFITS_LINK);
        scrollTop();
        setFlowStatus({ name: 'portfolio', status: 'finished' });
        setFlowStatus({ name: 'social-security', status: 'pending' });
        setFlowStatus({ name: 'expected-benefits', status: 'pending' });
        submitData()
      }} goBack={() => {
        scrollTop();
        history.push(INSURANCE_LINK);
      }} navigateErrors={navigateErrors} />
  </div>
}

export default connect(state => {  
  return {
    userName: state.userData.answers.mainUserName,
    spouseName: state.userData.answers.spouseUserName,
    maritalStatus: state.userData.answers.maritalStatus[0].id,
  }
}, {
  submitData,
  setFlowStatus
})(PortfolioFlow);
