import { Tab, Tabs } from "baseui/tabs";
import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getMaritalStatus } from "../../calculations/userDataTransformer";
import CumulativeGraph from "./CumulativeGraph";
import CumulativeJointGraph from "./CumulativeJointGraph";


const CumulativeTableGroup = (props) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1280px)' })
    const midSize = useMediaQuery({ query: '(min-width: 600px)' })

    let calculatedWidth = midSize ? isBigScreen ? 1000 : 600 : 300;

    let centerStyle = { justifyContent: "center", width: calculatedWidth };
    if (calculatedWidth > 800) {
        centerStyle['marginLeft'] = new String(calculatedWidth * -.1) + 'px';
    } else {
        centerStyle['margin'] = 'auto';
    }


    const [activeKey, setActiveKey] = React.useState("0");
    return (
        <div style={centerStyle} style={{ display: 'none' }}>
            <Tabs
                onChange={({ activeKey }) => {
                    setActiveKey(activeKey);
                }}
                activeKey={activeKey}
            >
                <Tab title={props.spouseUserName ? "Projected Joint Cumulative" : "Projected Cumulative"}><CumulativeJointGraph width={calculatedWidth} /></Tab>
                {props.showMain ? <Tab title={props.mainUserName + "'s Benefits - Comparison"} key="main"><CumulativeGraph  key="mainGraph" type='main' width={calculatedWidth} /></Tab> : undefined}
                {props.showSpouse ? <Tab title={props.spouseUserName + "'s Benefits - Comparison"} key="spouse"><CumulativeGraph  key="spouseGraph" type='spouse' width={calculatedWidth} /></Tab> : undefined}
            </Tabs>
        </div>

    );
}

export default connect(state => {
    let maritalStatus = getMaritalStatus(state.userData.answers);
    let showSpouse = maritalStatus === 'married' && !state.userData.answers.spouseTaking;
    let showMain = !state.userData.answers.mainTaking;

    return {
        mainUserName: state.userData.answers.mainUserName,
        spouseUserName: state.userData.answers.spouseUserName,
        showSpouse,
        showMain
    }
}, {})(CumulativeTableGroup);