import { MaskedInput } from 'baseui/input';
import React from 'react';

const DateInput = ({ id, updateInput, value, onFocus }) => {
   return (
      <MaskedInput
         onChange={(e) => updateInput(e.target.value)}
         placeholder='mm/dd/yyyy'
         onFocus={onFocus}
         mask='99/99/9999'
         value={value || ''}
         name={id}
      />
   );
}

export default DateInput;
