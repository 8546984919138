import _ from 'lodash';

export const validateHousing = ({ rentOrOwn, ownMortgageAmount }) => {
   let validation = { isValid: true, errors: [] };
   if (!rentOrOwn || _.isEmpty(rentOrOwn)) {
      validation.isValid = false;
      validation.errors.push({ location: 'section', message: 'Please input housing information' });
   } else if (rentOrOwn[0].id === 'own') {
      validation.isValid = !!ownMortgageAmount?.endDate;
      if (!validation.isValid) validation.errors.push({ location: 'ownMortgageAmount', message: 'All inputs must be filled out' });
   }
   return validation;
}

export const validateInsurance = (answers, maritalStatus, userName, spouseName) => {
   let validation = { isValid: true, errors: [] };
   let users = maritalStatus === 'married' ? [['main', userName], ['spouse', spouseName]] : [['main', userName]];

   users.forEach(([prefix, userName]) => {
      if (
         (answers[prefix + 'TakingMedicare'] && (new Date().getFullYear() - new Date(answers[prefix + 'Dob']).getFullYear()) < 65)
         || ((answers[prefix + 'TakingMedicare'] || answers[prefix + 'WillTakeMedicare']) && answers[prefix + 'MedicareTurnOnDate'] && (new Date(answers[prefix + 'MedicareTurnOnDate']).getFullYear() - new Date(answers[prefix + 'Dob']).getFullYear()) < 65)
      ) {
         validation.isValid = validation.isValid && false;
         validation.errors.push({ location: `${prefix}TakingMedicare`, message: `${userName} must be at least 65 to receive medicare` });
      }
   });
   return validation;
}
