import { Modal, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import React from 'react';
import { connect } from 'react-redux';
import { setRecoverableError } from '../../actions/ui';
import { SUPPORT_MAILING_ADDRESS } from './errorConstants';

export const ValidationErrorModal = ({ isOpen, setOpen }) => {
    return <Modal isOpen={isOpen} onClose={(e) => setOpen(false)} unstable_ModalBackdropScroll>
        <ModalHeader>
            Validation Error!
            Please review the issues on the page before navigating away.
        </ModalHeader>
        <ModalFooter>
            <ModalButton onClick={() => setOpen(false)}>Okay</ModalButton>
        </ModalFooter>
    </Modal>
}
