export const styles = {
    "9:160": {
        "top": 468,
        "width": 375,
        "height": 812,
        "position": "absolute",
        "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "9:161": {
        "top": 0,
        "left": 0,
        "width": 375,
        "height": 812,
        "position": "absolute",
        "backgroundColor": "rgba(246.0000005364418, 246.0000005364418, 246.0000005364418, 1)"
    },
    "9:162": {
        "top": 53,
        "paddingLeft": 5,
        "width": "500px",
        "height": "auto",
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "maxWidth": "100vw",
    },
    "9:163": {
        "top": -30,
        "width": 319,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "color": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "9:163Desktop": {
        "top": 0,
        "marginLeft": "auto",
        "marginRight": "auto",
        "paddingBottom": 15,
        "textAlign": "center",
        "color": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "9:164": {
        "top": 35,
        "width": "246px",
        "height": "auto",
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "maxWidth": "100%",
    },
    "9:165": {
        "top": 100,
        "left": 55,
        "width": 265,
        "height": 300,
        "position": "absolute"
    },
    "9:166": {
        "top": -30,
        "width": 265,
        "height": 300,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "boxShadow": "10px 10px 10px rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 1)",
        "backgroundColor": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
    },

    "GreenButton": {
        "width": 265,
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
    },
    "9:166Desktop": {
        "top": 75,
        "left": -50,
        "width": 265,
        "height": 300,
        "position": "absolute",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "boxShadow": "10px 10px 10px rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 1)",
        "backgroundColor": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
    },
    "9:167": {
        "top": 34.44110107421875,
        "width": 240,
        "height": 300,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "color": "rgba(255, 255, 255, 1)"
    },
    "9:168": {
        "top": 680,
        "width": 256,
        "height": 48,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "I9:168;18526:60768": {
        "top": 0,
        "left": 0,
        "width": 1,
        "height": 1,
        "position": "absolute"
    },
    "I9:168;643:23388": {
        "left": 0,
        "width": "100%",
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "backgroundColor": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "I9:168;643:23389": {
        "left": 16,
        "width": "100%",
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "color": "rgba(255, 255, 255, 1)"
    },
    "SubscribeButton": {
        "marginRight": "40px",
        "width": "150px",
        "background": "rgb(111, 160, 94)",
        "color": "rgb(255, 255, 255)",
        "display": "inline-block",
        "position": "relative",
        "top": "-15px"
    }
}
