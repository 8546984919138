import { Label2 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { finishNestedFlow, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { getMaritalStatus } from '../../calculations/userDataTransformer';
import { EMPLOYMENT_INCOME_LINK } from "../flows/linkConstants";
import { validateEmployment } from '../flows/validate/incomeValidator';
import { ConnectedFlow } from './ConnectedFlow';

function makeOtherIncomeQuestionsForUser(prefix, userName) {
    return [
        {
            questions: [
                { id: prefix + 'WorkedForGovernment', type: 'boolean', description: `Worked for the Government`, text: `Is ${userName} eligible for a government pension?`, nestedTable: prefix + "PensionTable" },
            ],
            text: `Other Income for ${userName}`,
            shouldShow: () => true,
            descriptor: 'Other Income'
        },
        {
            questions: [
                { id: prefix + 'PensionTable', type: 'incomeTable', description: `Pension`, text: 'Pension Information', tableType: 'single', buttonDescription: 'Add Government Pension Details +', questionSections: makePensionQuestions(true, userName) },
            ],
            shouldShow: (answers) => answers[prefix + 'WorkedForGovernment'] === true,
        },
        {
            questions: [{
                id: prefix + 'NonGovtPensionEligible',
                type: 'boolean',
                description: `Eligible for non-govt pension`,
                text: `Is ${userName} eligible for a pension from employment?`,
                nestedTable: prefix + "NonGovtPensionTable"
            },
            ],
            shouldShow: () => true,
        },
        {
            questions: [{
                id: prefix + 'NonGovtPensionTable',
                type: 'incomeTable',
                description: `Pension`, text: 'Pension Information',
                tableType: 'single', buttonDescription: 'Add Pension Details +', questionSections: makePensionQuestions(false, userName)
            },
            ],
            shouldShow: (answers) => answers[prefix + 'NonGovtPensionEligible'] === true,
        },
        {
            questions: [
                {
                    id: prefix + 'Annuity', type: 'boolean',
                    description: `Annuity`, text: `Does ${userName} own an annuity that has a Lifetime Income benefit?`,
                    nestedTable: prefix + "AnnuityTable"
                }
            ],
            shouldShow: () => true,
            text: 'Annuity Income',
            descriptor: 'Other Income'
        },
        {
            questions: [
                {
                    id: prefix + 'AnnuityTable',
                    type: 'incomeTable',
                    description: `Annual Annuity Payment`,
                    text: 'Add Annuity Details', buttonDescription: 'Add Income Annuity +',
                    explaination: <Label2 style={{ color: 'red' }}>DO NOT include this annuity in your portfolio as we are calculating the <u>Income Value</u> beginning on your start date.</Label2>,
                    questionSections: annuitySubQuestions(userName)
                },
            ],
            shouldShow: (answers) => answers[prefix + 'Annuity'] === true,
        },
        {
            questions: [
                {
                    id: prefix + 'ReceivesAlimony', type: 'boolean',
                    description: `Alimony`, text: `Does ${userName} receive Alimony payments?`,
                    nestedTable: prefix + "AlimonyTable"
                }
            ],
            shouldShow: () => true,
            text: 'Alimony',
            descriptor: 'Alimony Payments'
        },
        {
            questions: [
                {
                    id: prefix + 'AlimonyTable',
                    type: 'incomeTable',
                    description: `Alimony Payments`,
                    text: `Add Alimony Payments to ${userName}`,
                    help: `Identify source and enter any income for ${userName} that we have not identified`,
                    buttonDescription: 'Add Alimony Income', 
                    questionSections: alimonySubQuestions(userName)
                },
            ],
            shouldShow: (answers) => answers[prefix + 'ReceivesAlimony'] === true,
        },
        {
            questions: [
                {
                    id: prefix + 'extraIncomeTable',
                    type: 'incomeTable',
                    description: `Additional Income`,
                    text: `Add additional sources of income for ${userName}`,
                    help: `Identify source and enter any income for ${userName} that we have not identified`,
                    questionSections: futureIncomeSubQuestions()
                },
            ],
            text: `Additional Income`,
            shouldShow: () => true,
        }
    ]
}

function makeRentalIncomeQuestions(prefix, userName) {
    return [
        {
            questions: [
                {
                    id: 'RentalIncome',
                    type: 'rentalIncomeTable',
                    description: `Additional Rental income`,
                    text: `Add rental income property here: `,
                    buttonDescription: 'Add another rental property',
                    questionSections: RentalIncomeQuestions()
                },
            ],
            text: `Rental Income Properties`,
            shouldShow: () => true,
        }
    ]
}


function makeFutureIncomeQuestionsForUser(prefix, userName) {
    return [

        {
            questions: [
                {
                    id: prefix + 'extraFutureIncomeTable',
                    type: 'futureIncomeTable',
                    description: `Future Income`,
                    text: `Add future income for ${userName}`,
                    help: `Identify source and enter any future income for ${userName} that we have not identified`,
                    questionSections: otherFutureIncomeSubQuestions()
                },
            ],
            text: `Future Income`,
            shouldShow: () => true,
        }
    ]
}

function makePensionQuestions(govt, userName) {
    let govtString = govt ? 'government ' : '';
    return [
        {
            questions: [
                { id: 'currentlyReceiving', type: 'boolean', description: `Currently Receive Pension`, text: `Does ${userName} currently receive a ${govtString}pension?`, help: "Government pensions can affect your Social Security benefits" },
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'amount', type: 'dollar', text: `How much does ${userName} receive annually?` },
            ],
            shouldShow: (answers) => answers.currentlyReceiving,
        },
        {
            questions: [
                { id: 'startDate', type: 'futureDate', description: `Start Date`, text: `When will ${userName} begin receiving this pension?` },
            ],
            shouldShow: (answers) => answers['currentlyReceiving'] === false,
        },
        {
            questions: [
                { id: 'amount', type: 'dollar', text: `How much will ${userName} receive annually?` },
            ],
            shouldShow: (answers) => answers.currentlyReceiving === false,
        },
        {
            questions: [
                { id: 'willEnd', type: 'wrongBoolean', description: `Pension Ends`, text: `Is this a lifetime benefit?`, help: "Will this continue for the rest of your life, or does it have an end date?" },
            ],
            shouldShow: (answers) => answers.currentlyReceiving !== undefined,
        },
        {
            questions: [
                { id: 'endDate', type: 'futureDate', description: `Pension End Date`, text: `When will ${userName}'s pension end?`, help: "Government pensions can affect your Social Security benefits" },
            ],
            shouldShow: (answers) => answers['willEnd'] === true,
        },
        {
            questions: [
                { id: 'isGPO', type: 'boolean', text: `Did ${userName} pay Social Security taxes on the earnings from this job?`, }
            ],
            shouldShow: (answers) => govt ? true : false,
        },
        {
            questions: [
                { id: 'percentageIncrease', type: 'percentage', description: `Percentage Increase`, text: `How much will this benefit increase each year due to cost of living adjustments?` },
            ],
            shouldShow: (answers) => answers.currentlyReceiving !== undefined,
        },
    ]
}

function makeOtherIncomeQuestions(userName, spouseName, maritalStatus) {
    let questions = makeOtherIncomeQuestionsForUser('main', userName);

    if (maritalStatus === 'married') {
        questions = questions.concat(makeOtherIncomeQuestionsForUser('spouse', spouseName));

        questions.push({
            questions: [
                { id: 'jointIncome', type: 'incomeTable', description: `Additional joint income`, text: `Add additional joint income here: `, questionSections: futureIncomeSubQuestions() },
            ],
            text: 'Joint Income',
            shouldShow: () => true,
        });
    }

    return questions;
}

function makeFutureIncomeQuestions(userName, spouseName, maritalStatus) {
    let questions = makeFutureIncomeQuestionsForUser('main', userName);

    if (maritalStatus === 'married') {
        questions = questions.concat(makeFutureIncomeQuestionsForUser('spouse', spouseName));
    }

    return questions;
}

function annuitySubQuestions(userName) {
    return [
        {
            questions: [
                { id: 'currentlyReceiving', type: 'boolean', description: `Currently Receiving Annuity`, text: `Is ${userName} currently receiving income from this annuity?` },
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'amount', type: 'dollar', description: `Amount`, text: `How much does ${userName} receive annually?` },
            ],
            shouldShow: (answers) => answers.currentlyReceiving === true,
        },
        {
            questions: [
                { id: 'name', type: 'string', text: `What is the name of this income source?`, help: 'i.e. Fidelity Income Annuity' },
            ],
            shouldShow: (answers) => answers['currentlyReceiving'] === false,
        },
        {
            questions: [
                { id: 'startDate', type: 'futureDate', description: `Start Date`, text: `When will ${userName} begin to receive income from this annuity?` },
            ],
            shouldShow: (answers) => answers['currentlyReceiving'] === false,
        },
        {
            questions: [
                { id: 'amount', type: 'dollar', description: `Annual Annuity Payment`, text: `How much will ${userName} receive annually?` },
            ],
            shouldShow: (answers) => answers.currentlyReceiving === false,
        },
        {
            questions: [
                { id: 'percentageIncrease', type: 'percentage', description: `Benefit Increase`, text: `How much will this benefit increase each year due to cost of living adjustments?` },
            ],
            shouldShow: (answers) => answers.amount !== undefined,
        },
    ]
}

function alimonySubQuestions(userName) {
    return [
        {
            questions: [
                { id: 'amount', type: 'dollar', text: `How much will ${userName} recieve annually?`, help: 'Enter anticipated amount' },
                { id: 'currentlyReceiving', type: 'boolean', text: 'Have these payments begun yet?', help: 'Have you started receiving alimony payments yet?' },
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'startDate', type: 'futureDate', text: 'When will payments begin?', help: 'Enter the date payments will begin' },
            ],
            shouldShow: (answers) => answers['currentlyReceiving'] === false
        },
        {
            questions: [
                { id: 'willEnd', type: 'boolean', text: 'Will these payments end?' },
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'endDate', type: 'futureDate', text: 'When will payments end?' },
            ],
            shouldShow: (answers) => answers['willEnd']
        },
    ]
}

function futureIncomeSubQuestions() {
    return [
        {
            questions: [
                { id: 'name', type: 'string', text: `What is the name of this income source?`, help: 'i.e. pension, annuity, etc' },
                { id: 'amount', type: 'dollar', text: `How much income do you receive annually?`, help: 'Enter anticipated amount' }
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'willEnd', type: 'boolean', text: 'Will these payments end?' },
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'endDate', type: 'futureDate', text: 'When will payments end?' },
            ],
            shouldShow: (answers) => answers['willEnd']
        },
    ]
}

function RentalIncomeQuestions() {
    return [
        {
            questions: [
                { id: 'name', type: 'string', text: `Enter rental property name`, help: 'Enter Name' }
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'rentalAmount', type: 'MonthlyOrAnnuallySalary', text: `Enter your Gross Rental Income from this property`, help: 'Enter amount' }
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'mortgageAmount', type: 'MonthlyOrAnnuallySalary', text: `Enter your mortgage payment (Principal & Interest only)`, help: 'Enter mortgage amount' }
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'willEnd', type: 'boolean', text: 'Will these payments end?' },
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'endDate', type: 'futureMonthAndYear', text: 'When will this mortgage be paid off?' },
            ],
            shouldShow: (answers) => answers['willEnd']
        },
        {
            questions: [
                { id: 'insurance', type: "MonthlyOrAnnuallySalary", text: "Enter your premium for Homeowners Insurance", help: "Insurance premium amount" }
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'propertyTax', type: "MonthlyOrAnnuallySalary", text: "Enter your Property Tax", help: "Property tax amount" }
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'deductibleExpenses', type: "MonthlyOrAnnuallySalary", text: "Enter other Deductible Expenses", help: "Deductible expenses amount" }
            ],
            shouldShow: () => true
        }
    ]
}



function otherFutureIncomeSubQuestions() {
    return [
        {
            questions: [
                { id: 'name', type: 'string', text: `What is the name of this income source?`, help: 'i.e. pension, annuity, etc' },
                { id: 'amount', type: 'dollar', text: `Enter your estimated annual income after expenses`, help: 'Enter anticipated amount' }
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'startDate', type: 'futureYear', text: 'When will this payment begin', help: 'Enter the date payments will begin' },
            ],
            shouldShow: (answers) => true
        },
        {
            questions: [
                { id: 'willEnd', type: 'boolean', text: 'Will these payments end?' },
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'endDate', type: 'futureYear', text: 'When will this payment end?', help: 'Enter the date payments will end' },
            ],
            shouldShow: (answers) => answers['willEnd'] == true
        },
    ]
}

function makeDeductionsForUser(prefix, userName, answers) {
    return [
        {
            questions: [
                { id: prefix + 'PayrollDeductions', type: 'payrollDeductions', description: `Payroll Deductions`, text: '', help: "Illustrates your NET income to determine how much income is actually supporting your household expenses" },
            ],
            shouldShow: () => answers[prefix + 'IsEmployed'],
            text: 'Enter ' + userName + '\'s Payroll Deductions:',
            descriptor: userName + '\'s Payroll Deductions:',
            warning: `DO NOT enter deductions for Federal or State tax withholdings; we will calculate your estimated taxes. DO NOT enter deductions for contributions to 401K, IRA, etc., you already entered contributions in your Employment Profile.`
        }
    ]
}

function makeEmploymentQuestions(prefix, userName) {
    return [
        {
            questions: [
                { id: prefix + 'IsEmployed', type: 'boolean', description: `${userName} Employment Details`, text: `Is ${userName} presently employed?` }
            ],
            shouldShow: () => true,
            text: 'Enter ' + userName + '\'s employment details:',
            descriptor: userName + '\'s Income:'
        },
        {
            questions: [
                { id: prefix + 'RetireDate', type: 'futureDate', description: `Retire Date`, text: `When will ${userName} retire?`, help: "Illustrates your Cash Flow after retirement. Enter your last date of employment" },
                { id: prefix + 'GrossIncome', type: 'dollar', description: `Gross Income`, text: `What is ${userName}'s annual gross income from employment (W2, K1, 1099...)?`, help: "We need to collect all of your current income" },
                { id: prefix + 'IncomePercentageIncrease', type: 'dollarOrPercentSalary', description: `Income Increase`, text: `How much does ${userName}'s income increase each year?`, help: "Illustrates your future income while employed" },
                {
                    id: prefix + 'RetirementContributions',
                    type: 'dollarOrPercentSalary',
                    description: `Retirement Contributions`,
                    text: `How much does ${userName} contribute annually to a traditional retirement plan with employer i.e. 401K, 403B etc.?`, help: "Illustrates new contributions to your Retirement Plan prior to retirement. (Contribution/Gross Income = %)"
                },
                { id: prefix + 'EmployerMatch', type: 'dollarOrPercentSalary', description: `Retirement Contributions`, text: `How much does ${userName}'s employer contribute to this retirement plan each year?`, help: "Illustrates employer's matching contributions to your Retirement Plan prior to retirement." },
                { id: prefix + 'IRAOrSEPContribution', type: 'dollarOrPercentSalary', description: `IRA / SEP Contributions`, text: `How much does ${userName} contribute annually to a Roth IRA with employer?`, help: "Do you contribute to a Roth IRA with your employer?" },
                { id: prefix + 'RothIRAContribution', type: 'dollarOrPercentSalary', description: `Roth IRA`, text: `How much does ${userName}'s employer contribute to this Roth IRA each year?`, help: "Does your employer contribute to your Roth IRA?" },
            ],
            shouldShow: (answers) => answers[prefix + 'IsEmployed'] === true,
        },
        {
            questions: [
                {
                    id: prefix + 'AnnualTraditionalIRAContribution',
                    type: 'dollar',
                    description: `IRA / SEP Contributions`,
                    text: `How much does ${userName} contribute annually to a Traditional IRA?`,
                    help: "This is different from your employer's Retirement Plan (401K, 403B, etc). Do you contribute to Traditional IRA?" 
                },
                {
                    id: prefix + 'AnnualRothIRAContribution',
                    type: 'dollar',
                    description: `Roth IRA`,
                    text: `How much does ${userName} contribute annually to a Roth IRA?`,
                    help: "This is different from your IRA/SEP. Do you contribute to a Roth IRA?"
                },
            ],
            shouldShow: (answers) => answers[prefix + 'IsEmployed'] === true,
            text: 'Enter ' + userName + '\'s Other Contributions outside employer:',
            descriptor: userName + '\'s Other Contributions:'
        },
    ]
}

function makeDeductions(maritalStatus, userName, spouseName, answers) {
    let startDeductions = maritalStatus === 'married' ? makeDeductionsForUser('main', userName, answers).concat(makeDeductionsForUser('spouse', spouseName, answers)) : makeDeductionsForUser('main', userName, answers)

    let nobodyEmployed = (maritalStatus === 'married' && (!answers['mainIsEmployed'] && !answers['spouseIsEmployed'])) || (maritalStatus !== 'married' && !answers['mainIsEmployed'])
    if (nobodyEmployed) {
        startDeductions.push(
            {
                questions: [],
                shouldShow: () => true,
                text: maritalStatus === 'married' ? `Neither ${userName} nor ${spouseName} are working so this page does not apply. ` : `${userName} is not working so this page does not apply.`,
            })
    }
    return startDeductions
}

export const makeFlows = (answers) => {
    let userName = answers.mainUserName;
    let spouseName = answers.spouseUserName;
    let maritalStatus = getMaritalStatus(answers);

    return {
        employment: {
            questionData: maritalStatus === 'married' ? makeEmploymentQuestions('main', userName).concat(makeEmploymentQuestions('spouse', spouseName)) : makeEmploymentQuestions('main', userName),
            validator: (answers) => validateEmployment(answers, maritalStatus, userName, spouseName),
            next: '/app/income/deductions',
            previous: '/app/profile',
        },
        deductions: {
            questionData: makeDeductions(maritalStatus, userName, spouseName, answers),
            next: '/app/income/other',
            previous: EMPLOYMENT_INCOME_LINK,
        },
        other: {
            questionData: makeOtherIncomeQuestions(userName, spouseName, maritalStatus),
            next: '/app/income/rental',
            previous: '/app/income/deductions',
        },
        rental: {
            questionData: makeRentalIncomeQuestions(userName, spouseName, maritalStatus),
            next: '/app/income/future',
            previous: '/app/income/other',
        },
        future: {
            questionData: makeFutureIncomeQuestions(userName, spouseName, maritalStatus),
            next: '/app/expenses/housing',
            previous: '/app/income/rental',
        }
    }
};

const IncomeFlow = ({ incomeFlows, subTab, submitData, finishNestedFlow, navigateErrors }) => {
    const history = useHistory();

    if (incomeFlows[subTab]) {
        return <ConnectedFlow finishFlow={() => {
            console.log("INCOME SHIIITTT")
            scrollTop();
            history.push(incomeFlows[subTab].next);
            finishNestedFlow('income', incomeFlows, subTab);
            submitData();
        }} flowMode={true}
            questionSections={incomeFlows[subTab].questionData}
            sectionValidator={incomeFlows[subTab].validator}
            goBack={() => {
                scrollTop();
                history.push(incomeFlows[subTab].previous);
                submitData();
            }} navigateErrors={navigateErrors} />
    }

    return <Redirect to={EMPLOYMENT_INCOME_LINK} />
}

export default connect(state => {
    let incomeFlows = makeFlows(state.userData.answers)
    return {
        incomeFlows
    }
}, {
    finishNestedFlow,
    submitData
})(IncomeFlow);
