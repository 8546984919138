import React from 'react';
import PropTypes from 'prop-types';
import ArrowRight from 'baseui/icon/arrow-right';
import ArrowLeft from 'baseui/icon/arrow-left';
import { Button, SHAPE } from "baseui/button";

const ArrowButton = ({ message, action, disabled, type }) => {
   let icon = {}
   if (type === 'right') {
      icon['endEnhancer'] = () => <ArrowRight size={40} />
   } else {
      icon['startEnhancer'] = () => <ArrowLeft size={40} />
   }
   return (
      <Button
         style={{ backgroundColor: "#eda107", color: "#FFFFFF" }}
         disabled={disabled}
         onClick={() => action()}
         shape={SHAPE.pill}
         {...icon}
      >
         {message}
      </Button>
   )
};

ArrowButton.propTypes = {
   message: PropTypes.string,
   action: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
   type: PropTypes.string
}

export default ArrowButton;