import moment from 'moment';

export const getMaritalStatus = (answers) => {
  if (answers.maritalStatus && answers.maritalStatus.length > 0) {
    return answers.maritalStatus[0].id;
  }
}

export const getCurrentAgeYears = (dob) => {
  return moment().diff(moment(dob, "MM/DD/YYYY"), 'years', false);
}

export const getEndOfYearDate = (dob) => {
  return moment().endOf('year').diff(moment(dob, "MM/DD/YYYY"), 'years', false);
}