import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import Alert from 'baseui/icon/alert';
import { PLACEMENT, Popover, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Label1, Label2, Label3, Label4, ParagraphXSmall } from 'baseui/typography';
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setAnswer } from "../../actions/userData";
import SocialSecurityCalculator from '../../calculations/SocialSecurityApi';
import { getMaritalStatus } from "../../calculations/userDataTransformer";


export const SocialSecurityDetailTable = (props) => {
    return <>
        <div className="b4">
            <table style={{ borderCollapse: 'collapse', width: '100%', border: "1px solid lightgrey" }}>
                <tbody>
                    <tr>
                        <th colSpan="8" style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", width: "100%" }}>Full Retirement Age Schedule <br/> <span style={{ fontWeight: "normal" }}>(Based on year of birth)</span></th>
                    </tr>
                    <tr>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Birth Year</th>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>{ "< 1954"}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1955</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1956</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1957</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1958</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1959</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>1960</td>
                    </tr>
                    <tr>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Full Retirement Age</th>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66+2 mos.</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66+4 mos.</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66+6 mos.</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66+8 mos.</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>66+10 mos.</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>67</td>
                    </tr>
                </tbody>
            </table >
        </div>
    </>
}


export default connect(state => {
    let maritalStatus = getMaritalStatus(state.userData.answers);

    return {
        maritalStatus: getMaritalStatus(state.userData.answers)
    }
}, {})(SocialSecurityDetailTable);