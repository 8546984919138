import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import Alert from 'baseui/icon/alert';
import { PLACEMENT, Popover, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Label1, Label2, Label3, Label4, ParagraphXSmall } from 'baseui/typography';
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setAnswer } from "../../actions/userData";
import ScenarioDataBuilder from '../../calculations/social/ScenarioBuilder';
import { formatter } from '../../calculations/SocialSecurityApi';
import { getMaritalStatus } from '../../calculations/userDataTransformer';


export const BreakevenTable = ({ setAnswer, mainChoice, spouseChoice, survivorsChoice, answers, maritalStatus, mainTaking, mainTakeAmount, spouseTaking, spouseTakeAmount, survivorsTaking, survivorsTakingAmount }) => {
    const [hoverMain, setHoverMain] = React.useState(undefined);
    const [hoverSurvivorsWidowed, setHoverSurvivorsWidowed] = React.useState(undefined);
    const [hoverMainSpousal, setHoverMainSpousal] = React.useState(undefined);
    const [hoverSpouse, setHoverSpouse] = React.useState(undefined);
    const [hoverSpouseSpousal, setHoverSpouseSpousal] = React.useState(undefined);
    const [divorcedOpen, setDivorcedOpen] = React.useState(undefined);
    const [widowedOpen, setWidowedOpen] = React.useState(undefined);

    const [choiceMain, setChoiceMain] = React.useState(undefined);
    const [choiceSurvivorWidowed, setChoiceSurvivorWidowed] = React.useState(undefined);
    const [choiceMainSpousal, setChoiceMainSpousal] = React.useState(undefined);
    const [choiceSpouse, setChoiceSpouse] = React.useState(undefined);
    const [choiceSpouseSpousal, setChoiceSpouseSpousal] = React.useState(undefined);


    const [choiceMonthMain, setChoiceMonthMain] = React.useState(undefined);
    const [choiceMonthSurvivorWidowed, setChoiceMonthSurvivorWidowed] = React.useState(undefined);
    const [choiceMonthMainSpousal, setChoiceMonthMainSpousal] = React.useState(undefined);
    const [choiceMonthSpouse, setChoiceMonthSpouse] = React.useState(undefined);
    const [choiceMonthSpouseSpousal, setChoiceMonthSpouseSpousal] = React.useState(undefined);


    const history = useHistory();

    let scenarioBuilder = new ScenarioDataBuilder(answers);

    React.useEffect(() => {
        load()
    }, [mainChoice, spouseChoice, survivorsChoice])

    const load = () => {
        if (mainTaking) {
            mainChoice = { index: 1, monthIndex: 1, takeAmountAnnual: mainTakeAmount * 12, takeDate: moment().format("X") };

            setJack(Number(mainTakeAmount), scenarioBuilder.mainAge, mainChoice.index, mainTaking.type === 'widowed' ? 'widowed' : 'main', true, mainChoice.monthIndex);
        } else if (mainChoice?.takeAmountAnnual !== undefined) {
            setJack(mainChoice.takeAmountAnnual / 12, scenarioBuilder.mainAge, mainChoice.index, mainChoice.type, true, mainChoice.monthIndex);
        } else {
            setChoiceMain(undefined)
            setChoiceMonthMain(undefined)
        }

        if (spouseTaking) {
            spouseChoice = { index: 1, monthIndex: 1, takeAmountAnnual: spouseTakeAmount * 12, takeDate: moment().format("X") };

            setJill(spouseTakeAmount, scenarioBuilder.spouseAge, spouseChoice.index, 'main', true, spouseChoice.monthIndex);
        } else if (spouseChoice?.takeAmountAnnual !== undefined) {
            setJill(spouseChoice.takeAmountAnnual / 12, scenarioBuilder.spouseAge, spouseChoice.index, spouseChoice.type, true, spouseChoice.monthIndex);
        } else {
            setChoiceSpouse(undefined)
            setChoiceMonthSpouse(undefined)
        }

        if (survivorsTaking) {
            survivorsChoice = { index: 1, monthIndex: 1, survivorAmountAnnual: survivorsTakingAmount * 12, survivorTakeDate: moment().format("X") };

            setSurvivors(Number(survivorsTakingAmount), scenarioBuilder.mainAge, survivorsChoice.index, 'survivors', true, survivorsChoice.monthIndex);
        } else if (survivorsChoice?.survivorAmountAnnual !== undefined) {
            setSurvivors(survivorsChoice.survivorAmountAnnual / 12, scenarioBuilder.mainAge, survivorsChoice.index, survivorsChoice.type, true, survivorsChoice.monthIndex);
        }

    }

    const getIndexForTakeDate = (ssCalc, age, takeDate) => {
        let years = Math.floor(moment(takeDate, 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY'), 'years', true));
        return Math.max(1, years - Math.max(age, 62) + 1)
    }

    const reset = () => {
        setAnswer('mainSocialSecurity', {});
        setAnswer('spouseSocialSecurity', {});
        setAnswer('survivorsSocialSecurity', {});
        setChoiceMain(undefined);
        setChoiceMonthMain(undefined);
        setChoiceMainSpousal(undefined);
        setChoiceMonthMainSpousal(undefined);
        setChoiceSpouse(undefined);
        setChoiceMonthSpouse(undefined);
        setChoiceSpouseSpousal(undefined);
        setChoiceMonthSpouseSpousal(undefined);
        setChoiceSurvivorWidowed(undefined);
        setChoiceMonthSurvivorWidowed(undefined);
    }

    let yearWidth = 40;
    const borderRadius = 10;

    const adjustIndexIfUnder62 = (index, isSpouse) => {
        if (maritalStatus !== 'married') return index;
        let mainDiff = Math.max(62 - scenarioBuilder.mainAge, 0);
        let spouseDiff = Math.max(62 - scenarioBuilder.spouseAge, 0);
        return index + (isSpouse ? spouseDiff : mainDiff);
    }

    const setJack = (amt, age, index, type, skipSet, monthIndex, takenDate) => {
        //Amt comes in as monthly
        if (!skipSet) setAnswer('mainSocialSecurity', scenarioBuilder.formatMainChoiceForBackend(age, amt * 12, type, index, monthIndex, takenDate));
        if (type === 'spousal') {
            setChoiceMain(undefined)
            setChoiceMainSpousal(index)
            setChoiceMonthMainSpousal(monthIndex)
        } else if (type === 'survivors') {
            if (survivorsChoice.survivorTakeDate) {
                setChoiceSurvivorWidowed(survivorsChoice.index)
                setChoiceMonthSurvivorWidowed(survivorsChoice.monthIndex)
            } else {
                setChoiceMainSpousal(undefined)
                setChoiceMonthMainSpousal(undefined)
                setChoiceMain(index)
                setChoiceMonthMain(monthIndex)
            }
        } else {
            setChoiceMainSpousal(undefined)
            setChoiceMonthMainSpousal(undefined)
            setChoiceMain(index)
            setChoiceMonthMain(monthIndex)
        }
    }

    const setJill = (amt, age, index, type, skipSet, monthIndex, takenDate) => {
        if (!skipSet) setAnswer('spouseSocialSecurity', scenarioBuilder.formatSpouseChoiceForBackend(age, amt * 12, type, index, monthIndex, takenDate));
        if (type === 'spousal') {
            setChoiceSpouseSpousal(index)
            setChoiceMonthSpouseSpousal(monthIndex)
            setChoiceSpouse(undefined)
            setChoiceMonthSpouse(undefined)
        } else {
            setChoiceSpouse(index)
            setChoiceMonthSpouse(monthIndex)
            setChoiceSpouseSpousal(undefined)
            setChoiceMonthSpouseSpousal(undefined)
        }
    }

    const setSurvivors = (amt, age, index, type, skipSet, monthIndex, takenDate) => {
        //Amt comes in as monthly
        if (!skipSet) setAnswer('survivorsSocialSecurity', scenarioBuilder.formatMainChoiceForBackend(age, amt * 12, type, index, monthIndex, takenDate));
        if (survivorsChoice.survivorTakeDate) {
            setChoiceSurvivorWidowed(survivorsChoice.index)
            setChoiceMonthSurvivorWidowed(survivorsChoice.monthIndex)
        } else {
            setChoiceMainSpousal(undefined)
            setChoiceMonthMainSpousal(undefined)
            setChoiceMain(index)
            setChoiceMonthMain(monthIndex)
        }
    }

    const setWidowedChoice = (amt, age, index, type, skipSet, monthIndex, takenDate) => {
        setAnswer('mainSocialSecurity', scenarioBuilder.updateWidowedChoice(mainChoice, age, amt, type, index, monthIndex, takenDate))

        if (type === 'survivors') {
            setChoiceSurvivorWidowed(index);
            setChoiceMonthSurvivorWidowed(monthIndex);
        } else {
            setChoiceMain(index)
            setChoiceMonthMain(monthIndex)
        }
    }


    const setSurvivorsChoice = (amt, age, index, type, skipSet, monthIndex, takenDate) => {
        setAnswer('survivorsSocialSecurity', scenarioBuilder.updateWidowedChoice(survivorsChoice, age, amt, type, index, monthIndex, takenDate))

        if (type === 'survivors') {
            setChoiceSurvivorWidowed(index);
            setChoiceMonthSurvivorWidowed(monthIndex);
        } else {
            setChoiceMain(index)
            setChoiceMonthMain(monthIndex)
        }
    }
    let bothTaking = (choiceMain || choiceMainSpousal) && (choiceSpouse || choiceSpouseSpousal);
    let anySelected = (choiceMain || choiceMainSpousal) || (choiceSpouse || choiceSpouseSpousal);
    let isAffectedByReduction = (elem) => Math.abs(elem.earningsTestReduction) > 0 || Math.abs(elem.pensionReduction) > 0;
    function DisabledElem() {
        return <th style={{ minWidth: yearWidth }}>
            <StatefulPopover
                content={() => <Label1>You can't choose your own spousal </Label1>}
                triggerType={TRIGGER_TYPE.hover}
                returnFocus
                autoFocus
            >
                <Label1 color="lightgrey">n / a</Label1>
            </StatefulPopover>
        </th>;
    }

    function getETestText(elem) {
        return <>
            {elem.earningTestApplied == true ? <Label2>You are not eligible for benefits in the highlighted months due to the Earnings Test.</Label2> : undefined}
        </>
    }

    function DivorcedWarning({ open }) {
        return <Popover style={{ maxWidth: 600 }} content={() => (<Block padding={"20px"} $style={{ backgroundColor: 'warning200', maxWidth: '600px' }}>
            <ParagraphXSmall margin={0} padding={0}>You cannot take your ex-spouse's benefit here because it has not been at least 2 years since you were divorced.</ParagraphXSmall>
        </Block>)} returnFocus isOpen={open} placement={PLACEMENT.bottom}>
            <div style={{ color: 'grey' }}>N / A</div>
        </Popover>
    }
    function DivorceeAgeWarning({ open }) {
        return <Popover style={{ maxWidth: 600 }} content={() => (<Block padding={"20px"} $style={{ backgroundColor: 'warning200', maxWidth: '600px' }}>
            <ParagraphXSmall margin={0} padding={0}>You cannot take your ex-spouse's benefit here because they won't be 62 at this time.</ParagraphXSmall>
        </Block>)} returnFocus isOpen={open} placement={PLACEMENT.bottom}>
            <div style={{ color: 'grey' }}>N / A</div>
        </Popover>
    }
    function DeceasedAgeWarning({ open }) {
        return <div style={{ color: 'grey' }}>N / A</div>
    }

    function GPOWarning({ elem, setClickIndex, parentIndex, index, type, open }) {
        return <Popover style={{ maxWidth: 600 }} content={() => (<Block padding={"20px"} $style={{ backgroundColor: 'warning200', maxWidth: '600px' }}>
            <div className="b4" style={{textAlign: "center" }}>
                <h2>{elem.ageYear}</h2>
            </div>
            {getETestText(elem)}
            <br />
            <div className="b4">
                <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid lightgrey' }}>
                    <tbody>
                        {elem.months.map((element, monthIndex) => {
                            return <MonthElement elem={element} age={elem.age} setClickIndex={setClickIndex} parentIndex={parentIndex} type={type} />
                        })}
                    </tbody>
                </table>
            </div>
        </Block>)} returnFocus isOpen={open} placement={PLACEMENT.bottom}>
            <div></div>
        </Popover>
    }

    function GPOSuccess({ elem, setClickIndex, parentIndex, index, type, open }) {
        return <Popover style={{ maxWidth: 600 }} content={() => (<Block padding={"20px"} $style={{ backgroundColor: 'warning200', maxWidth: '600px' }}>
            <div className="b4" style={{textAlign: "center" }}>
                <h2>{elem.ageYear}</h2>
            </div>
            {getETestText(elem)}
            <br />
            <div className="b4">
                <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid lightgrey' }}>
                    <tbody>
                        {elem.months.map((element, monthIndex) => {
                            return <MonthElement elem={element} age={elem.age} setClickIndex={setClickIndex} parentIndex={parentIndex} type={type} />
                        })}
                    </tbody>
                </table>
            </div>


        </Block>)} returnFocus isOpen={open} placement={PLACEMENT.bottom}>
            <div></div>
        </Popover>
    }

    const MonthElement = ({ elem, age, setClickIndex, parentIndex, type }) => {
        return <>
            <tr>
                {elem.map((element, index) => {
                    if(element.bg_color == "") {
                        return <MonthValue elem={element} age={age} index={index} setClickIndex={setClickIndex} type={type} parentIndex={parentIndex} />
                    } else {
                        return <NoEventMonthValue elem={element} age={age} index={index} type={type} parentIndex={parentIndex} />
                    }
                })}
            </tr>
        </>
    }

    const MonthValue = ({ elem, age, setClickIndex, type, index, parentIndex }) => {
        return <>
                <th style={{ border: '1px solid lightgrey', padding: "5px" }} onClick={() => setClickIndex(elem.amount, age, parentIndex, type, undefined, elem.index, elem.date)}>
                    <Label1 style={{ background: elem.bg_color }}>{elem.month}</Label1>
                    <Label3 style={{ background: elem.bg_color }}>{'$' + formatter.format(elem.amount)}</Label3>
                </th>
                </>
    }

    const NoEventMonthValue = ({ elem, age, type, index, parentIndex }) => {
        return <>
                <th style={{ border: '1px solid lightgrey', padding: "5px" }}>
                    <Label1 style={{ background: elem.bg_color }}>{elem.month}</Label1>
                    <Label3 style={{ background: elem.bg_color }}>{'$' + formatter.format(elem.amount)}</Label3>
                </th>
                </>
    }

    const YearElement = ({ startIndex, elem, selectIndex, setClickIndex, index, type, hover }) => {
        return <div style={{ minWidth: 50, height: 50, paddingTop: 10 }}>
            <div style={{ width: '100%', display: 'block' }}>
                <Label4 $style={{ color: 'warning200' }}>{'$' + formatter.format(elem.amount)}</Label4>
            </div>
            {
                startIndex === index && !selectIndex ? undefined : undefined
            }
            {isAffectedByReduction(elem) && !selectIndex ? 
            <GPOWarning elem={elem} setClickIndex={setClickIndex} index={index} parentIndex={index} type={type} open={startIndex === index && !selectIndex} /> : 
            <GPOSuccess elem={elem} setClickIndex={setClickIndex} index={index} parentIndex={index} type={type} open={startIndex === index && !selectIndex} />}
        </div>
    }

    const paintYears = (yearList, fn, startIndex, selectIndex, setClickIndex, type, color) => {
        function onHover(index) {
            if (selectIndex) return;

            setHoverMain(undefined);
            setDivorcedOpen(undefined);
            setWidowedOpen(undefined);
            setHoverSurvivorsWidowed(undefined);
            setHoverMainSpousal(undefined);
            setHoverSpouse(undefined);
            setHoverSpouseSpousal(undefined);
            fn(index);
        }

        return <tr onMouseLeave={() => onHover(undefined)}>
            {yearList.map((elem, index) => {
                if (elem.type === 'header') {
                    return <th style={{ minWidth: yearWidth }}>
                        <Label1>{elem.text}</Label1>
                        <Label3>{elem.subtext}</Label3>
                    </th>
                } else if (elem.type === 'disabled') {
                    return <DisabledElem />
                } else if (elem.type === 'not-chosen') {
                    return <th style={{ minWidth: yearWidth, borderBottom: type == "spousal" ? "" : "double" }}></th>
                } else if (elem.type === 'not-eligible-divorced') {
                    return <th style={{ minWidth: yearWidth, backgroundColor: 'lightgrey' }} onMouseLeave={() => setDivorcedOpen(undefined)} onMouseEnter={() => setDivorcedOpen(index)}>
                        <DivorcedWarning open={divorcedOpen !== undefined && divorcedOpen === index} />
                    </th>
                } else if (elem.type === 'not-eligible-deceased-age') {
                    return <th style={{ minWidth: yearWidth, backgroundColor: 'lightgrey' }} onMouseLeave={() => setWidowedOpen(undefined)} onMouseEnter={() => setWidowedOpen(index)}>
                        <DeceasedAgeWarning open={widowedOpen !== undefined && widowedOpen === index} />
                    </th>
                } else if (elem.type === 'not-eligible-divorcee-age') {
                    return <th style={{ minWidth: yearWidth, backgroundColor: 'lightgrey' }} onMouseLeave={() => setDivorcedOpen(undefined)} onMouseEnter={() => setDivorcedOpen(index)}>
                        <DivorceeAgeWarning open={divorcedOpen !== undefined && divorcedOpen === index} />
                    </th>
                }

                let hasIssue = isAffectedByReduction(elem) && !selectIndex;

                return <th
                    colSpan={'1'}
                    onMouseEnter={() => onHover(index)}
                    // onClick={() => {
                    //     if (selectIndex) return;
                    //     console.log('click')
                    //     console.log(index)
                    //     setClickIndex(elem.amount, elem.age, index, type)
                    // }}
                    style={{
                        backgroundColor: selectIndex !== undefined && selectIndex <= index ? color : hasIssue ? '#FFF2D9' : 'whitesmoke',
                        border: '1px solid lightgrey',
                        borderBottomLeftRadius: selectIndex === index ? borderRadius : 0,
                        borderTopLeftRadius: selectIndex === index ? borderRadius : 0,
                        margin: 0, padding: 0,
                        minWidth: yearWidth,
                        borderBottom: type == "spousal" ? "" : "double"
                    }}>

                    <YearElement
                        startIndex={startIndex}
                        elem={elem}
                        selectIndex={selectIndex}
                        index={index}
                        type={type}
                        setClickIndex={setClickIndex}
                        hover={selectIndex} />
                </th >
            })}
        </tr>
    }
    const headerStyling = (elem) => ({ border: '1px solid lightgrey', margin: 0, padding: 0, backgroundColor: elem === 'FRA' ? 'turquoise' : undefined });

    const headingStyling = () => ({ border: '1px solid lightgrey', margin: 0, padding: 0 });

    const YearPicker = () => {
        return <>
            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearMainHeaders().map((elem) => <th style={headerStyling(elem)}>{elem}</th>)}
            </tr>
        </>
    }

    const CalculateSocialSecurity = (elem, percentage) => {
        if(percentage == 0)
            return elem

        let percentageCount = (Number(elem) * percentage) / 100;

        return (Number(elem) + Number(percentageCount))
    }

    const MarriedPicker = () => {
        let mainData = scenarioBuilder.getMarriedMainScenarioData(
            choiceMain ? choiceMain - 1 : undefined,
            choiceMainSpousal ? choiceMainSpousal - 1 : undefined,
            choiceSpouseSpousal ? choiceSpouseSpousal - 1 : choiceSpouse ? choiceSpouse - 1 : undefined,
            choiceSpouse ? 'main' : choiceSpouseSpousal ? 'spousal' : undefined,
            choiceMonthMain ? choiceMonthMain : undefined,
            choiceMonthMainSpousal ? choiceMonthMainSpousal : undefined,
            choiceMonthSpouseSpousal ? choiceMonthSpouseSpousal : choiceMonthSpouse ? choiceMonthSpouse : undefined,
            choiceMonthSpouse ? 'main' : choiceMonthSpouseSpousal ? 'spousal' : undefined);

        let spouseData = scenarioBuilder.getMarriedSpouseScenarioData(
            choiceSpouse ? choiceSpouse - 1 : undefined,
            choiceSpouseSpousal ? choiceSpouseSpousal - 1 : undefined,
            choiceMainSpousal ? choiceMainSpousal - 1 : choiceMain ? choiceMain - 1 : undefined,
            choiceMain ? 'main' : choiceMainSpousal ? 'spousal' : undefined,
            choiceMonthSpouse ? choiceMonthSpouse : undefined,
            choiceMonthSpouseSpousal ? choiceMonthSpouseSpousal : undefined,
            choiceMonthMainSpousal ? choiceMonthMainSpousal : choiceMonthMain ? choiceMonthMain : undefined,
            choiceMonthMain ? 'main' : choiceMonthMainSpousal ? 'spousal' : undefined);

        const TotalHeading = () => {
            if (bothTaking) {
                return <tr>{scenarioBuilder.getTotalHeaders().map((elem, index) => {
                    let amount = (choiceSpouseSpousal ? spouseData[1][index].amount || 0 : spouseData[0][index].amount || 0) +
                        (choiceMainSpousal ? mainData[1][index].amount || 0 : mainData[0][index].amount || 0);

                    return <th style={{ border: '1px solid lightgrey', margin: 0, padding: 0, minWidth: yearWidth }}>
                        {index < 1 ? elem : formatter.format(amount)}
                    </th>
                })}
                </tr>
            } else {
                return <></>
            }
        }

        return <>
            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearMainHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}
            </tr>
            <tr>{scenarioBuilder.getMainHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}</tr>

            {!choiceMainSpousal || !bothTaking ? paintYears(mainData[0], setHoverMain, hoverMain, choiceMain, setJack, 'main', "#8884d8") : undefined}
            {!mainTaking && (!choiceMain || !bothTaking) ? paintYears(mainData[1], setHoverMainSpousal, hoverMainSpousal, choiceMainSpousal, setJack, 'spousal', "#8884d8") : undefined}

            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearSpouseHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}
            </tr>
            <tr>{scenarioBuilder.getSpouseHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}</tr>

            {!choiceSpouseSpousal || !bothTaking ? paintYears(spouseData[0], setHoverSpouse, hoverSpouse, choiceSpouse, setJill, 'main', "#a832a6") : undefined}
            {!spouseTaking && (!choiceSpouse || !bothTaking) ? paintYears(spouseData[1], setHoverSpouseSpousal, hoverSpouseSpousal, choiceSpouseSpousal, setJill, 'spousal', "#a832a6") : undefined}
            <TotalHeading />
        </>
    }

    const DivorcedPicker = () => {
        let mainData = scenarioBuilder.getDivorcedScenarioData(
            choiceMain ? choiceMain - 1 : undefined,
            choiceMainSpousal ? choiceMainSpousal - 1 : undefined,
            choiceMonthMain ? choiceMonthMain : undefined,
            choiceMonthMainSpousal ? choiceMonthMainSpousal : undefined);
        let takingDivorced = choiceMain || choiceMainSpousal
        let takingMonthDivorced = choiceMonthMain || choiceMonthMainSpousal
        return <>
            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearMainHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}
            </tr>
            <tr>{scenarioBuilder.getMainHeaders().map((elem) => <th style={headerStyling(elem)}>{elem}</th>)}</tr>
            {(!takingDivorced || (takingDivorced && choiceMain)) ? paintYears(mainData[0], setHoverMain, hoverMain, choiceMain, setJack, 'main', "#8884d8") : undefined}
            {(answers.prevMarriedLongerThanTenYears === true && (!takingDivorced || (takingDivorced && choiceMainSpousal))) ? paintYears(mainData[1], setHoverMainSpousal, hoverMainSpousal, choiceMainSpousal, setJack, 'spousal', "#8884d8") : undefined}
        </>
    }

    const SinglePicker = () => {
        return <>
            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearMainHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}
            </tr>
            <tr>{scenarioBuilder.getMainHeaders().map((elem) => <th style={headerStyling(elem)}>{elem}</th>)}</tr>
            {paintYears(scenarioBuilder.getSingleScenarioData(
                choiceMain ? choiceMain - 1 : undefined,
                choiceMonthMain ? choiceMonthMain : undefined,
                ), setHoverMain, hoverMain, choiceMain, setJack, 'main', "#8884d8")}
        </>
    }

    const WidowedPicker = () => {
        let widowedScenarioData = scenarioBuilder.getWidowedScenarioData(
            choiceMain ? choiceMain - 1 : undefined,
            choiceSurvivorWidowed ? choiceSurvivorWidowed - 1 : undefined,
            choiceMonthMain ? choiceMonthMain : undefined,
            choiceMonthSurvivorWidowed ? choiceMonthSurvivorWidowed : undefined);

        return <>
            <tr>
                {<th style={headingStyling()}>Year</th>}
                {scenarioBuilder.getYearMainHeaders().map((elem, index) => <th key={index} style={headerStyling(elem)}>{elem}</th>)}
            </tr>
            <tr>{scenarioBuilder.getMainHeaders().map((elem) => <th style={headerStyling(elem)}>{elem}</th>)}</tr>
            {!mainTaking && !choiceMain ? paintYears(widowedScenarioData[0], setHoverSurvivorsWidowed, hoverSurvivorsWidowed, choiceSurvivorWidowed, setSurvivorsChoice, 'survivors', "#82ca9d") : undefined}
            {!survivorsTaking && !choiceSurvivorWidowed ? paintYears(widowedScenarioData[1], setHoverMain, hoverMain, choiceMain, setWidowedChoice, 'mainwidowed', "#8884d8") : undefined}
        </>
    }

    return <>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
                {maritalStatus === 'married' ? <MarriedPicker /> : undefined}
                {maritalStatus === 'widowed' ? <WidowedPicker /> : undefined}
                {maritalStatus === 'divorced' ? <DivorcedPicker /> : undefined}
                {maritalStatus === 'never-married' ? <SinglePicker /> : undefined}
            </tbody>
        </table >


        {(anySelected && (!mainTaking || (maritalStatus === 'married' && !spouseTaking && (choiceSpouse || choiceSpouseSpousal) || !mainTaking && choiceMain))) ||
            (maritalStatus === 'widowed' && (choiceSurvivorWidowed || choiceMain) && !mainTaking) ||
            ((maritalStatus === 'divorced' && (choiceMain || choiceMainSpousal) && !mainTaking) ||
                (maritalStatus === 'never-married' && choiceMain) && !mainTaking) ? <Button onClick={(e) => reset()}>Reset</Button> : undefined}
    </>
}


export default connect(state => ({
    answers: state.userData.answers,
    maritalStatus: getMaritalStatus(state.userData.answers),
    mainTaking: state.userData.answers.mainTaking,
    mainTakeAmount: state.userData.answers.mainTaking ? state.userData.answers.mainTakingAmount : state.userData.answers.mainFRABenefit,
    spouseTaking: state.userData.answers.spouseTaking,
    spouseTakeAmount: state.userData.answers.spouseTaking ? state.userData.answers.spouseTakingAmount : state.userData.answers.spouseFRABenefit,
    mainChoice: state.userData.answers.mainSocialSecurity,
    spouseChoice: state.userData.answers.spouseSocialSecurity,
    survivorsChoice: state.userData.answers.survivorsSocialSecurity,
    survivorsTaking: state.userData.answers.survivorsTaking,
    survivorsTakingAmount: state.userData.answers.survivorsTaking ? state.userData.answers.survivorsTakingAmount : undefined,
}), {
    setAnswer
})(BreakevenTable);

