import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import Alert from 'baseui/icon/alert';
import { PLACEMENT, Popover, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Label1, Label2, Label3, Label4, ParagraphXSmall } from 'baseui/typography';
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setAnswer } from "../../actions/userData";
import SocialSecurityCalculator from '../../calculations/SocialSecurityApi';
import { getMaritalStatus } from "../../calculations/userDataTransformer";


export const FRADetailTable = (props) => {
    const headerStyling = () => ({ border: '1px solid lightgrey', margin: 0, padding: 0, textAlign: "left" });

    const MarriedFRADetail = () => {
        return <>
            <tr>
                <th style={{ width: "40%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.mainUserName}</th>
                <th style={{ width: "60%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.mainFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
            <tr>
                <th style={{ width: "40%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.spouseUserName}</th>
                <th style={{ width: "60%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.spouseFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
        </>
    }

    const DivorcedFRADetail = () => {
        return <>
            <tr>
                <th style={{ width: "40%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.mainUserName}</th>
                <th style={{ width: "60%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.mainFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
            <tr>
                <th style={{ width: "40%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.previousPartnerName}</th>
                <th style={{ width: "60%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.divorcedspouseFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
        </>
    }

    const SingleFRADetail = () => {
        return <>
            <tr>
                <th style={{ width: "40%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.mainUserName}</th>
                <th style={{ width: "60%", padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.mainFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
        </>
    }

    const WidowedFRADetail = () => {
        return <>
            <tr>
                <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.mainUserName}</th>
                <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.mainFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
            <tr>
                <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{props.lateSpouseUserName}</th>
                <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>{moment(props.passedAwaySpouseFRADate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</th>
            </tr>
        </>
    }

    return <>
        <div className="b4">
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                    <tr><th colSpan="2" style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", width: "100%" }}>Your Full Retirement Ages are as follows:</th></tr>
                    {props.maritalStatus === 'married' ? <MarriedFRADetail /> : undefined}
                    {props.maritalStatus === 'widowed' ? <WidowedFRADetail /> : undefined}
                    {props.maritalStatus === 'divorced' ? <DivorcedFRADetail /> : undefined}
                    {props.maritalStatus === 'never-married' ? <SingleFRADetail /> : undefined}
                </tbody>
            </table >
        </div>
    </>
}


export default connect(state => {
    let maritalStatus = getMaritalStatus(state.userData.answers);
    let showSpouse = maritalStatus === 'married' && !state.userData.answers.spouseTaking;
    let showMain = !state.userData.answers.mainTaking;

    let mainDob = state.userData.answers.mainDob;
    let spouseDob = "";
    let divorcedDob = "";
    let deceasedSpouseDob = "";
    if(showSpouse)
        spouseDob = state.userData.answers.spouseDob;

    let mainSSCalc = new SocialSecurityCalculator('main', state.userData.answers);
    let spouseSSCalc = "";
    if(showSpouse) {
        spouseSSCalc = new SocialSecurityCalculator('spouse', state.userData.answers);
    }

    if(maritalStatus == "divorced") {
        divorcedDob = state.userData.answers.divorceeDob;
    }

    if(maritalStatus == "widowed") {
        deceasedSpouseDob = state.userData.answers.deceasedSpouseDob;
    }

    return {
        mainFRADate: mainSSCalc.getAddedFRAMoment(mainDob),
        spouseFRADate: spouseSSCalc != "" ? spouseSSCalc.getAddedFRAMoment(spouseDob) : "",
        divorcedspouseFRADate: divorcedDob != "" ? mainSSCalc.getAddedFRAMoment(divorcedDob) : "",
        passedAwaySpouseFRADate: deceasedSpouseDob != "" ? mainSSCalc.getAddedFRAMoment(deceasedSpouseDob) : "",
        maritalStatus: getMaritalStatus(state.userData.answers),
        mainUserName: state.userData.answers.mainUserName,
        lateSpouseUserName: state.userData.answers.deceasedSpouseUserName,
        spouseUserName: state.userData.answers.spouseUserName,
        showDivorcedOptions: state.userData.answers.prevMarriedLongerThanTenYears && state.userData.answers.divorcedMarriageHighestBenefit && state.userData.answers.divorcedMarriageName,
        previousPartnerName: state.userData.answers.divorcedMarriageName,
        showSpouse,
        showMain
    }
}, {})(FRADetailTable);