import React from 'react';
import Firebase from './Firebase';

export const FirebaseUserContext = React.createContext();

export const FirebaseUserProvider = ({children}) => {
    return <FirebaseUserContext.Provider value={Firebase}>
        {children}
    </FirebaseUserContext.Provider>
}

export const withFirebase = Component => props => (
    <FirebaseUserContext.Consumer>
        {firebase => { return <Component firebase={firebase} {...props}/> }}
    </FirebaseUserContext.Consumer>
);
