import React from 'react';
import UserApi from '../../firebase/UserApi';
import { withFirebase } from '../FirebaseUserContext';
import AuthUserContext from './context';

const withoutAuthentication = Component => {
    class WithoutAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.lastCommitted = undefined;
            this.state = {
                authUser: undefined,
                signedIn: false,
                claims: {
                    inTrials: true,
                    emailSent: false
                },
            }
        }

        async componentDidMount() {
            if (this.props.firebase.auth.isSignInWithEmailLink(window.location.href)) {
                this.props.firebase.emailIsVerified = true;
            }
        }

        componentWillUnmount() {
            window.location.href = "/app/profile";
        }

        render() {

            console.log(this.state.authUser)
            return (
                <AuthUserContext.Provider value={{ authUser: this.state.authUser, isSignedIn: this.state.signedIn }}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            )
        }
    }

    return withFirebase(WithoutAuthentication);
}

export default withoutAuthentication;
