import { Card } from 'baseui/card';
import { HeadingLarge, HeadingSmall, Label4, Paragraph2 } from 'baseui/typography';
import { StyledLink } from "baseui/link";
import React from 'react';
import { connect } from 'react-redux';
import { setFlowStatus, submitData } from '../../actions/userData';
import { getMaritalStatus } from "../../calculations/userDataTransformer";
import BreakevenTable from '../social/BreakevenTable';
import FRADetailTable from '../social/FRADetailTable';
import SocialSecurityDetailTable from '../social/SocialSecurityDetailTable';
import EarningTestDetaillTable from '../social/EarningTestDetaillTable';
import CumulativeTableGroup from '../social/CumulativeTableGroup';
import moment from "moment";
import SocialSecurityCalculator from "../../calculations/SocialSecurityApi";
import { formatter } from '../../calculations/SocialSecurityApi';

const SocialSecurityChoiceFlow = ({ mainSSAmount, spouseFRABenefit, maritalStatus, mainUserName, spouseUserName, showDivorcedOptions, previousPartnerName, currentYear, exemptionAmount }) => {
    function MarriedHeading() {
        return <>
            <Paragraph2>
                Step 1: Identify which filing option provides the highest benefit in the year you plan to file for benefits.
            </Paragraph2>
            <Paragraph2>
                Step 2: <u>Hover over the benefit value ($) below the year</u> each of you plan to file for benefits
            </Paragraph2>
            <Paragraph2>
                Step 3: From the drop-down menu, <u>Select the Month</u> each spouse wants to begin receiving benefits
            </Paragraph2>
            <Paragraph2>
                <i>Note: Spousal Benefits cannot be selected until your spouse selects their own Age Based Benefit</i>
            </Paragraph2>
        </>
    }

    function SingleHeading({ takeAmount }) {
        return <>
            <Paragraph2>
                Step 1: <u>Click on the benefit value ($) below the year</u> you plan to file for benefits
            </Paragraph2>
            <Paragraph2>
                Step 2: From the drop-down menu, <u>Select the Month</u> you want to begin receiving benefits
            </Paragraph2>
        </>
    }
    function WidowedHeading() {
        return <>
            <Paragraph2>
                Survivors have the option of selecting an initial filing option and letting the other option remain in deferral increasing in value up to age 70; switch to the other option at any time.
            </Paragraph2>
            <Paragraph2>
                Step 1: <u>Choose your initial filing option:</u> <u>Age-Based</u> or <u>Survivor</u>
            </Paragraph2>
            <Paragraph2>
                Step 2: <u>Hover over the benefit value ($) below the year</u> you plan to file for benefits
            </Paragraph2>
            <Paragraph2>
                Step 3: From the drop-down menu, <u>Select the Month</u> you want to begin receiving those benefits
            </Paragraph2>
            <Paragraph2>
                Optional: Repeat steps 2 & 3 in the other option to illustrate switching in any subsequent year
            </Paragraph2>
        </>
    }

    function DivorcedHeading() {
        return <>
            <Paragraph2>
                Step 1: Identify which filing option provides the highest benefit in the year you plan to file for benefits.
            </Paragraph2>
            <Paragraph2>
                Step 2: <u>Hover over the benefit value ($) below the year</u> you plan to file for benefits
            </Paragraph2>
            <Paragraph2>
                Step 3: From the drop-down menu, <u>Select the Month</u> you want to begin receiving benefits
            </Paragraph2>
            <Paragraph2>
                <i>Note: Spousal Benefits do not affect your ex-spouse’s benefits</i>
            </Paragraph2>
        </>
    }

    function Heading() {
        return <>
            <HeadingSmall style={{ textAlign: "left", margin: "0" }}>Benefit Filing Options:</HeadingSmall>
            {maritalStatus === 'married' ? <MarriedHeading /> : undefined}
            {maritalStatus === 'widowed' ? <WidowedHeading /> : undefined}
            {maritalStatus === 'divorced' ? <DivorcedHeading /> : undefined}
            {maritalStatus === 'never-married' ? <SingleHeading /> : undefined}
            <HeadingSmall style={{ textAlign: "left", margin: "0" }}>All benefit figures are approximate</HeadingSmall>
            <br/>
        </>
    }

    return <div>
        <div style={{ justifyContent: "center", width: '100%' }}>
            <HeadingLarge style={{ textAlign: "center" }}>Social Security</HeadingLarge>
            <Heading />
        </div>

        <Card>
            <BreakevenTable />
        </Card>
        <br/>
        <CumulativeTableGroup />
    </div>
}

export default connect(state => {
    let maritalStatus = getMaritalStatus(state.userData.answers);
    let mainSSCalc = new SocialSecurityCalculator('main', state.userData.answers);
    let currentYear = moment().year();

    return {
        mainUserName: state.userData.answers.mainUserName,
        spouseUserName: state.userData.answers.spouseUserName,
        maritalStatus,
        mainSSAmount: state.userData.answers.mainTaking ? state.userData.answers.mainTakingAmount : state.userData.answers.mainFRABenefit,
        showDivorcedOptions: state.userData.answers.prevMarriedLongerThanTenYears && state.userData.answers.divorcedMarriageHighestBenefit && state.userData.answers.divorcedMarriageName,
        previousPartnerName: state.userData.answers.divorcedMarriageName,
        currentYear: currentYear,
        spouseFRABenefit: state.userData.answers.spouseFRABenefit,
        exemptionAmount: mainSSCalc.getExemptionAmounts(currentYear)
    }
}, {
    setFlowStatus,
    submitData,
})(SocialSecurityChoiceFlow);





{/*<HeadingSmall>Earnings Test:</HeadingSmall>
<Paragraph2>
    You can receive your Social Security benefits as early as age 62 provided you do not exceed the income limit for that year. <span style={{ color: "blue" }}>The earnings limit in {currentYear} is ${formatter.format(exemptionAmount.preFRA)}</span>. Your benefit will be reduced by 50% of the amount you exceed the annual income limit. Social Security will reduce your <u>monthly benefit</u> by as much as 100% until your cumulative benefit reduction equals 50% of the excess amount. If you retire mid-year your earnings do not count for the months prior to retiring. You can earn unlimited income after your FRA and still receive your full eligible benefit.
</Paragraph2>
<Paragraph2 style={{ textAlign: "center", fontWeight: "bold" }}>
    Example: $50,000 Earned Income - ${formatter.format(exemptionAmount.preFRA)} (limit) = ${formatter.format(50000 - exemptionAmount.preFRA)} (Excess) X 50% = ${formatter.format((50000 - exemptionAmount.preFRA) * 0.5)}
</Paragraph2>
<EarningTestDetaillTable />
<Paragraph2 style={{ textAlign: "center" }}>
    <StyledLink target="_blank" style={{ color: "blue" }} href="https://www.ssa.gov/benefits/retirement/planner/whileworking.html">https://www.ssa.gov/benefits/retirement/planner/whileworking.html</StyledLink>
</Paragraph2>
<HeadingSmall>Full Retirement Age:</HeadingSmall>
<Paragraph2>
    Social Security benefits are based on your <b>Full Retirement Age (FRA)</b> which is determined by the year you were born. <u>Your FRA Benefit is reduced for every month you file prior to your FRA</u> and your <u>FRA Benefit is increased for every month you delay filing beyond your FRA</u> however does not increase beyond age 70.
</Paragraph2>
<SocialSecurityDetailTable />
<br/>
<FRADetailTable />
<br/>
<HeadingSmall style={{ textAlign: "left", margin: "0" }}>Age Based Benefit Options:</HeadingSmall>*/}