import { Button } from 'baseui/button';
import { Delete, Plus } from 'baseui/icon/';
import { Input, StatefulInput } from 'baseui/input';
import {
    TableBuilder,
    TableBuilderColumn
} from 'baseui/table-semantic';
import { Label2 } from 'baseui/typography';
import React from 'react';
import { makeExpense } from '../../onboard/Question/GeneralExpenses';
import TextInput from '../../onboard/Question/TextInput';


const PayrollComponent = ({ question, setAnswer, currentAnswer }) => {
    if (!currentAnswer) {
        currentAnswer = {
            payrollValues: makeDefaultPayrollStuff(),
            payPeriods: undefined,
        }
    }

    const data = currentAnswer.payrollValues;
    const payPeriods = currentAnswer.payPeriods ? Number(currentAnswer.payPeriods) : undefined;
    function sanitizeStringToNumber(value) {
        return Number(value).toFixed(0);// Number(value.replace('$', '').replace(',', ''))
    }

    function setPerPaycheck(amount, index) {
        amount = sanitizeStringToNumber(amount)
        let newData = [...data];
        newData[index] = { ...newData[index], perPeriod: amount }
        setAnswer({ ...currentAnswer, payrollValues: newData })
    }

    function setYearly(amount, index) {
        amount = sanitizeStringToNumber(amount)
        let newData = [...data];
        newData[index] = { ...newData[index], perPeriod: amount / payPeriods }
        setAnswer({ ...currentAnswer, payrollValues: newData })
    }

    const updateKeyValue = (updatedValue, key) => {
        let newValue = { ...currentAnswer };
        newValue[key] = updatedValue;
        setAnswer(newValue);
    }

    function addDeduction() {
        if (currentAnswer.payrollValues.find(value => value.inputName === "")) return; //if there is already a blank one don't add another
        let newData = [
            ...data,
            {
                perPeriod: 0,
                inputName: "",
                default: false,
            }
        ];
        setAnswer({ ...currentAnswer, payrollValues: newData });
    }

    function setDeductionName(value, index) {
        let newData = [...data];
        newData[index].inputName = value;
        setAnswer({ ...currentAnswer, payrollValues: newData });
    }

    function deleteDeduction(deductionIndex) {
        let newData = data.filter((_, index) => index !== deductionIndex);
        setAnswer({ ...currentAnswer, payrollValues: newData });
    }

    const SpecialPerPaycheckInput = ({ index }) => {
        const [value, setValue] = React.useState(undefined);
        return <div style={{ minWidth: 125 }}><Input placeholder="Per-Paycheck Amount" type="number"
            value={value !== undefined ? value : sanitizeStringToNumber(data[index].perPeriod)}
            onChange={e => {
                setValue(e.target.value)
            }}
            startEnhancer="$"
            onFocus={() => {
                if (!data[index].perPeriod) {
                    setValue('')
                }
            }}
            onBlur={e => {
                if (value) {
                    setValue(undefined)
                    setPerPaycheck(value, index)
                }
            }} /></div>
    }

    const SpecialYearlyInput = ({ index }) => {
        const [value, setValue] = React.useState(undefined);
        return <div style={{ minWidth: 125 }}><Input placeholder="Enter Monthly Amount" type="number"
            value={value !== undefined ? value : sanitizeStringToNumber(data[index].perPeriod * payPeriods)}
            onChange={e => {
                setValue(e.target.value)
            }}
            startEnhancer="$"
            onFocus={() => {
                if (!data[index].perPeriod) {
                    setValue('')
                }
            }}
            onBlur={e => {
                if (value) {
                    setValue(undefined)
                    setYearly(value, index)
                }
            }} /></div>
    }


    return (
        <div>
            <Label2>How many pay periods do you get per-year?</Label2>
            <TextInput fieldType='number' updateInput={(e) => updateKeyValue(e.target.value, 'payPeriods')} value={currentAnswer['payPeriods']} />
            {payPeriods ?
                <>
                    <TableBuilder data={data}>
                        <TableBuilderColumn header="Name">
                            {(row, index) =>
                                !data[index].default
                                    ? <StatefulInput placeholder="Enter name" initialState={{ value: data[index].inputName }} onBlur={(e) => setDeductionName(e.target.value, index)} />
                                    : row.name
                            }
                        </TableBuilderColumn>
                        <TableBuilderColumn header="Per-Paycheck Deduction">
                            {(row, index) => {
                                return <SpecialPerPaycheckInput index={index} />
                            }}
                        </TableBuilderColumn>
                        <TableBuilderColumn header="Yearly">
                            {(row, index) => <SpecialYearlyInput index={index} />}
                        </TableBuilderColumn>
                        {data.some(deductions => !deductions.default)
                            ? <TableBuilderColumn>
                                {(row, index) =>
                                    !data[index].default
                                        ? <Button onClick={() => deleteDeduction(index)} shape="pill" style={{ backgroundColor: "#eda107", color: "#FFFFFF" }}><Delete /></Button>
                                        : null}
                            </TableBuilderColumn>
                            : null}
                    </TableBuilder>
                    <Button onClick={() => addDeduction()} startEnhancer={Plus} shape="pill" overrides={{ BaseButton: { style: () => ({ float: "right" }) } }} style={{ backgroundColor: "#eda107", color: "#FFFFFF" }}>Add a deduction</Button>
                    <br />
                    <br />
                </> : undefined}
        </div>
    );
}

export const addExpensesFromPayroll = (payrollComponentOutput, expenses) => {
    for (let expense of payrollComponentOutput.payrollValues) {
        if (expense.perPeriod && expense.perPeriod !== 0) {
            expenses.push(makeExpense(expense.name, expense.perPeriod * payrollComponentOutput.payPeriods, true, expense.endDate));
        }
    }
}

export const Payroll = {
    inputName: 'payroll',
    Component: PayrollComponent,
    defaultValue: {
        payrollValues: makeDefaultPayrollStuff(),
        payPeriods: undefined,
    },
    validator: () => undefined
}

function makeDefaultPayrollStuff() {
    let values = ["Medicare", "Social Security", "Health", "Vision", "Dental", "H.S.A.", "Life", "Life (Spouse)", "Disability", "Charity", "Only Local Tax"];
    return values.map(elem => ({ name: elem, perPeriod: 0, endDate: null, default: true }))
}