import { HeadingMedium, HeadingXSmall, Label1 } from 'baseui/typography';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import AuthFlow from '../components/login/AuthFlow';
import { withAuthentication } from '../firebase/session';
import { styles } from './styles/LoginPage.style';


const Header = ({ width }) => {
  return <div style={{ width: 500, margin: 'auto', textAlign: 'center' }}>
    <HeadingXSmall $style={styles['4:77']}>Welcome to</HeadingXSmall>
    <div id='1:6' style={{ ...styles['1:6'], width }}>
      <img src='/llogo.png' width="100%" />
    </div>
    <Label1 $style={styles['2:2']}>Your Retirement Resource</Label1>
  </div>
}

const MarketingPhone = (props) => {
  return <>
    <Header width={333} />
    <div style={{ width: '100%' }}>
      <br />
      <br />

      <div style={{ margin: 'auto' }}>
        <img src='/macs.png' alt="macs" width="100%" />
      </div>
      <div style={{ marginLeft: '5%', marginRight: '5%', textAlign: 'center', }}>
        <HeadingMedium style={styles['4:72Phone']}>Planning Your Financial Future</HeadingMedium>
        <Label1 style={styles['4:77Phone']}>Retirement happens. Do you have a plan?</Label1>
      </div>
      <div style={{ marginLeft: '5%', marginRight: '5%', }}>

        {props.children}
      </div>
    </div>
  </>
}

const MarketingDesktop = (props) => {
  return <>
    <Header width={500} />
    <div style={{ display: 'flex', marginTop: '50px' }}>
      <div style={{ width: '30%', marginLeft: '5%', marginRight: '5%' }}>
        <HeadingMedium style={styles['4:72']}>Planning Your Financial Future</HeadingMedium>
        <Label1 style={styles['4:77']}>Retirement happens. Do you have a plan?</Label1>
        {props.children}
      </div>
      <div style={{ width: '60%', marginLeft: '5%', marginRight: '5%' }}>
        <img src='/macs.png' alt="macs" width="100%" />
      </div>
    </div>
  </>
}

const AuthView = (props) => {
  const mobileLayout = useMediaQuery({ query: '(max-width: 900px)' })

  return <div style={{ width: '100%' }}>
    {mobileLayout ?
      <MarketingPhone>
        <AuthFlow firebase={props.firebase} />
      </MarketingPhone> :
      <MarketingDesktop>
        <AuthFlow firebase={props.firebase} />
      </MarketingDesktop>}
  </div>
}

export default withAuthentication(AuthView);
