import { setAllAnswers } from "../../../actions/userData";

export const dobChange = (id, newAnswer, answers, setAllAnswersDispatch, startConfirmModal, setAnswer) => {
    let previousAnswer = answers[id];

    let newAnswers = { ...answers };
    delete newAnswers['mainSocialSecurity'];
    delete newAnswers['spouseSocialSecurity'];
    delete newAnswers['mainTaking'];
    delete newAnswers['spouseTaking'];
    newAnswers[id] = newAnswer;

    if (previousAnswer !== newAnswer) {
        startConfirmModal('Since changing your birthday isn\'t a common occurence, this will reset some of your answers for Social Security to make sure we give you the most accurate projections.', setAllAnswers(newAnswers));
    }
}