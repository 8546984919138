import { withStyle } from 'baseui';
import { StyledSpinnerNext } from 'baseui/spinner';
import React from 'react';

const ExtraLargeSpinner = withStyle(StyledSpinnerNext, {
  width: '80px',
  height: '80px',
  borderLeftWidth: '10px',
  borderRightWidth: '10px',
  borderTopWidth: '10px',
  borderBottomWidth: '10px',
  borderTopColor: 'green',
});

export default () => {
  return <div style={{ width: '100vw', height: '100vh', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell' }}>
    <div style={{ display: 'inline-block' }}>
      <ExtraLargeSpinner />
    </div>
  </div>
}