import moment from 'moment';

export const pastValidator = value => {
   const str = value || '';
   const year = +str.slice(-4);
   const currentYear = moment().year();
   if (moment(value, "MM/DD/YYYY").isValid()) {
      if (currentYear - year > 0) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;//is a total of 10 chars long, and is a valid date, is not in the future
   }
}
export const dobValidity = value => {
   const str = value || '';
   const year = +str.slice(-4);
   const currentYear = moment().year();
   if (moment(value, "MM/DD/YYYY").isValid()) {
      if ((currentYear - year) < 115 && (currentYear - year) > 18) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;//is a total of 10 chars long, and is a valid date, is not hella old or timetraveler
   }
}

export const futureDateValidity = (value) => {
   const str = value || '';
   if (str.trim().length !== 10) {
      return true;
   }
   if (moment(value, "MM/DD/YYYY").isValid()) {
      if (moment(value, "MM/DD/YYYY").diff(moment(), 'days') > 0) {
         return true;
      }
   }

   return "Please enter a date in the future."
}

export const futureMonthAndYearValidity = (value) => {
   console.log(value);

   const str = value || '';
   if (str.trim().length !== 7) {
      return true;
   }

   const month = str.split("/")[0];
   const year = str.split("/")[1];;

   value = month + "/01/" + year;

   if (moment(value, "MM/DD/YYYY").isValid()) {
      if (moment(value, "MM/DD/YYYY").diff(moment(), 'days') > 0) {
         return true;
      }
   }

   return "Please enter a date in the future."
}

export const futureYearValidity = (value) => {
   console.log(value);

   const str = value || '';
   if (str.trim().length !== 4) {
      return true;
   }

   const year = moment().year();

   if (Number(value) > Number(year)) {
      return true;
   }

   return "Please enter a year in the future."
}

export const currentValidator = (value) => {
   if (value && value.trim().length === 10) {
      if (!dobValidity(value)) {
         return "Please enter a valid date.";
      }
   }
}
