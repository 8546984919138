import firebase from 'firebase';

const productionConfig = {
    apiKey: "AIzaSyAX8JoQPr5Icq8Ug5InnjVr9F14R31Q8zE",
    authDomain: "living-ledger-web.firebaseapp.com",
    databaseURL: "https://living-ledger-web.firebaseio.com",
    projectId: "living-ledger-web",
    storageBucket: "living-ledger-web.appspot.com",
    messagingSenderId: "986703409449",
    appId: "1:986703409449:web:404650a7d8f4d3364f4738"
};

const developmentConfig = {
    apiKey: "AIzaSyAYCUldK9qZFq-qFhRKEa2koAAdYLMcOWw",
    authDomain: "living-ledger-dev.firebaseapp.com",
    databaseURL: "https://living-ledger-dev.firebaseio.com",
    projectId: "living-ledger-dev",
    storageBucket: "living-ledger-dev.appspot.com",
    messagingSenderId: "1009838579064",
    appId: "1:1009838579064:web:f6dc6261f92472c91efc8d"
};

class Firebase {
    constructor() {
        this.env = this.__getEnv();
        this.config = this.__getConfig();
        this.actionCodeSettings = this.__getActionCodeSettings();

        this.emailIsVerified = false;

        firebase.initializeApp(this.config);

        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.functions = firebase.functions();

        this.__setPersistence();

        if (window.location.hostname === 'localhost') {
            this.functions.useFunctionsEmulator('http://localhost:5001');
            //Comment out if running from dev
            // this.database.settings({ host: 'localhost:8080', ssl: false });
            // this.auth.useEmulator('http://localhost:9099');
        }
    }

    __getConfig() {
        return this.env === 'production' ? productionConfig : developmentConfig;
    }

    __getEnv() {
        if (process.env.NODE_ENV === 'test') {
            return 'test';
        } else if (window.location.hostname === 'localhost') {
            return 'localhost'
        } else if (window.location.hostname.startsWith('living-ledger-dev')) {
            return 'development';
        } else {
            return 'production';
        }
    }

    __getActionCodeSettings() {
        // https://firebase.google.com/docs/auth/web/email-link-auth
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: this.env === 'localhost' ? 'http://localhost:3000/signup' : (this.env === 'development' ? 'https://living-ledger-dev.web.app/signup' : 'https://app.livingledger.com/signup'),
            // This must be true.
            handleCodeInApp: true,
            // dynamicLinkDomain: Used if we had "mobile.livingledger.com", only for mobile-specific stuff
        };
    }

    __setPersistence() {
        if (this.env === 'test') {
            this.auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
        } else {
            this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        }
    }

    // only used in test environment
    setLocalPersistence() {
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    }

    signOut() {
        return this.auth.signOut();
    }

    async fetch(route, params) {
        if (!this.auth.currentUser) throw new Error("User is not logged in");
        const token = await this.auth.currentUser.getIdToken();

        if (params.body) params.body = JSON.stringify(params.body);
        const response = await fetch(this.__getAPIRoute(route), {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            ...params,
        });

        if (response.status >= 200 && response.status < 400) {
            const data = await response.json();
            return data;
        }
        throw new Error("Server Failed. ");
    }

    async fetchCSV(route, params) {
        if (!this.auth.currentUser) throw new Error("User is not logged in");
        const token = await this.auth.currentUser.getIdToken();

        if (params.body) params.body = JSON.stringify(params.body);
        const response = await fetch(this.__getAPIRoute(route), {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            ...params,
        });
        const data = await response.text();

        if (response.statusCode >= 400) throw new Error(data.message);
        return data;
    }

    __getAPIRoute(route) {
        let apiRoute = `/api${route}`;
        if (this.env === 'localhost') {
            apiRoute = 'http://localhost:5000' + apiRoute
        }
        return apiRoute;
    }
}

export default new Firebase();