import { HeadingMedium, Paragraph1 } from 'baseui/typography';
import React from 'react';
import { SUPPORT_MAILING_ADDRESS } from './errorConstants';


export const ErrorSplash = () => {
    return <div style={{ width: '100%', textAlign: 'center' }}>
        <HeadingMedium>Our apologies! There seems to have been an error.</HeadingMedium> <br />
        <Paragraph1>We've been notified and are looking into it. Please try again soon. If this problem persists, please email us
        <a href={"mailto:" + SUPPORT_MAILING_ADDRESS}> here.</a></Paragraph1>
    </div>
}