import React from "react";
import {AuthUserContext} from "../firebase/session";
import Firebase from "../firebase/Firebase";
import {Redirect} from "react-router-dom";

export const Logout = () => {
    let {authUser} = React.useContext(AuthUserContext);
    const [loggingOut, setLoggingOut] = React.useState(true);

    React.useEffect(() => {
        const logout = async () => {
            if (authUser) {
                await Firebase.signOut();
            }
            setLoggingOut(false)
        }

        logout();
    }, []);

    if (!loggingOut) return <Redirect to={'/'}/>

    return <div></div>
}