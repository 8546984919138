import firebase from 'firebase';
import UserApi from './UserApi';


class TableApi {
    async getTableData() {
        try {
            let data = await firebase.functions().httpsCallable('grabTableData')();
            return data.data;
        } catch (e) {
            await UserApi.crashReport("Crashed in Cashflow")
            return Promise.reject(e);
        }
    }
}

export default new TableApi();