import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { setMenuOpen } from '../actions/ui';
import { setFlowStatus, submitData } from '../actions/userData';
import { ValidationErrorModal } from "../components/error/ValidationError";
import {
    hasLinkUnlocked
} from '../components/flows/linkConstants';
import OnboardingFlowView from '../components/interrogate/ProfileFlow';
import { Pages } from "../components/sidebar/Pages";
import Sidebar from '../components/sidebar/Sidebar';

const Profile = ({ answers, profileFlowFinished, flowStatus, submitData, menuOpen, setMenuOpen }) => {
    const { params } = useRouteMatch();
    const location = useLocation();
    const [validationErrorModalOpen, setValidationErrorModalOpen] = React.useState(false);

    const [subTab, linkUnlocked] = hasLinkUnlocked(location, params, flowStatus);
    const [subPageError, setSubPageError] = React.useState(undefined)

    if (!linkUnlocked) return <Redirect to='/app/profile' />;

    const currentPage = Pages(subTab)[params.tab];

    const defaultProps = currentPage ? { ...currentPage.defaultProps, navigateErrors: subPageError } : { navigateErrors: subPageError };

    return profileFlowFinished ?
        <div style={{ display: 'flex', width: '100vw' }}>
            <Sidebar submitData={submitData} flowStatus={flowStatus}
                validate={() => {
                    let results
                    console.log(currentPage);
                    if (currentPage) {
                        results = currentPage.validator(answers)
                        if (results && results.length > 0) {
                            if (results[0] === false) {
                                setSubPageError(results[1])
                                setValidationErrorModalOpen(true)
                            } else {
                                setSubPageError(undefined);
                            }
                        }
                    }
                    return results;
                }}
                menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div id="pageBlock" style={{ width: '100vw', maxHeight: '100vh', overflowX: 'auto' }}>
                {currentPage ? <currentPage.Component {...defaultProps} /> : <div></div>}
            </div>
            <ValidationErrorModal isOpen={validationErrorModalOpen} setOpen={setValidationErrorModalOpen} />
        </div> :
        <OnboardingFlowView />
}

export default connect(state => ({
    answers: state.userData.answers,
    profileFlowFinished: state.userData.flows && state.userData.flows.profile === 'finished',
    flowStatus: state.userData.flows,
    menuOpen: state.ui.menuOpen
}), {
    setFlowStatus,
    submitData,
    setMenuOpen
})(Profile);