import {
   createTheme,
   lightThemePrimitives, ThemeProvider
} from "baseui";
import { Avatar } from "baseui/avatar";
import { Button } from "baseui/button";
import {
   ALIGN, HeaderNavigation,
   StyledNavigationItem, StyledNavigationList
} from "baseui/header-navigation";
import { Menu } from "baseui/icon";
import { StyledLink } from "baseui/link";
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Label4 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useStyletron } from 'styletron-react';
import { useHistory } from "react-router-dom";
import { setAdminMode, setMenuOpen } from "../actions/ui";
import { AuthUserContext } from '../firebase/session';
import ProfileDropdown from './ProfileDropdown';
import BillingApi from '../firebase/BillingApi';
import { styles } from '../billing/styles/BeginSubscription.style';
import { useStripe } from "@stripe/react-stripe-js";
import { PROFILE_LINK } from "../components/flows/linkConstants";
import withStripe from "../billing/withStripe";

const UserAvatar = () => {
   let { loading } = React.useContext(AuthUserContext);
   return !loading ? <Avatar size="scale1400" src={'/profile.png'} /> : '';
}

const Subscribe = () => {
   const history = useHistory();
   const stripe = useStripe();
   const [spinning, setSpinning] = React.useState(false);
   const [error, setError] = React.useState(undefined);

   let { loading } = React.useContext(AuthUserContext);

   return !loading ? <Button $style={styles['SubscribeButton']} isLoading={spinning} onClick={() => {
          setSpinning(true);
          BillingApi.purchase(stripe).then((resp) => {
            setSpinning(false)
            history.push(PROFILE_LINK);
          }).catch((error) => {
            setError(error);
            setSpinning(false);
          })
        }}>Subscribe</Button> : '';
}

const ToolBar = ({ setAdminMode, setMenuOpen, menuOpen }) => {
   const [css] = useStyletron();
   let { authUser } = React.useContext(AuthUserContext);
   let impersonating = authUser && authUser.impersonate;
   const { paid, admin, inTrials, startTrialTimestamp } = authUser.claims;

   const shouldShowHashMenu = useMediaQuery({ query: '(max-width: 600px)' })

   return (
      <HeaderNavigation overrides={{
         Root: {
            style: ({ $theme }) => {
               return {
                  height: '80px',
                  width: '100vw'
               };
            }
         }
      }}>
         <ThemeProvider
            theme={createTheme(lightThemePrimitives, {
               colors: {
                  buttonPrimaryFill: "#F6F6F6F6",
                  buttonPrimaryText: "#000000",
                  buttonPrimaryHover: "#AAAAAA",
                  buttonPrimaryActive: "#777777"
               }
            })}
         >
            <StyledNavigationList $align={ALIGN.left}>
               {shouldShowHashMenu ? <StyledNavigationItem>
                  <Button onClick={(e) => setMenuOpen(!menuOpen)}><Menu size={30} /></Button>
               </StyledNavigationItem> : undefined}
               <StyledNavigationItem>
                  <div style={{ marginTop: 10 }}><img src={'/LivingLedger.png'} width={150} /></div>
               </StyledNavigationItem>
            </StyledNavigationList>
            <div style={{ margin: 'auto' }}></div>
            <StyledNavigationList $align={ALIGN.right}>
               {
                  impersonating && impersonating.email ?
                     <StyledNavigationItem>
                        <div style={{ display: 'flex' }}>
                           <div style={{ flexDirection: 'column' }}>
                              <Label4>Viewing: {impersonating.email}'s account</Label4>
                              <StyledLink onClick={() => {
                                 setAdminMode(false)
                              }}>Log Out</StyledLink>
                           </div>
                        </div>
                     </StyledNavigationItem> : undefined
               }
               <StatefulPopover
                  showArrow
                  content={() => <ProfileDropdown />}
                  placement={PLACEMENT.bottomRight}
                  returnFocus
                  autoFocus
                  ignoreBoundary
                  overrides={{
                     Body: {
                        style: {
                           zIndex: 100,
                           width: 250
                        }
                     }
                  }}
               >
                  <StyledNavigationItem className={css({
                     marginRight: '40px',
                     marginLeft: '20px'
                  })}>
                     { !paid && !admin ? <Subscribe /> : undefined }
                     <UserAvatar />
                  </StyledNavigationItem>
               </StatefulPopover>
            </StyledNavigationList>
         </ThemeProvider>

      </HeaderNavigation>
   )
}

export default connect(state => ({
   menuOpen: state.ui.menuOpen,
}), {
   setAdminMode,
   setMenuOpen
})(withStripe(ToolBar));