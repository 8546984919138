import { Button } from 'baseui/button';
import { HeadingSmall, Paragraph1 } from 'baseui/typography';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setBillingStatus } from '../actions/ui';
import withStripe from "../billing/withStripe";
import BillingApi from '../firebase/BillingApi';
import SubscriptionLayout from './SubscriptionLayout';
import { styled } from '@material-ui/core/styles';
const Account = () => {
   const [isCancelSubscription, setIsCancelSubscription] = useState(false)
   return <>
      {isCancelSubscription ? <>
         <BlurBackground />
         <SubscriptionContainer>
            <SubscriptionLayout onCancel={() => setIsCancelSubscription(!isCancelSubscription)} />
         </SubscriptionContainer>
      </> : <>
         <div style={{ width: "100%" }}>
            <div style={{ maxWidth: 800, margin: 'auto' }}>
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <HeadingSmall>Account Settings</HeadingSmall>
               </div>
               <Paragraph1>To manage account settings and payment details please navigate to the payment portal with the button below.</Paragraph1>
               <Button onClick={() => BillingApi.getPortalLink()} overrides={{ BaseButton: { style: { backgroundColor: "#eda107", color: "#FFFFFF" } } }}>Go To Billing Portal</Button>
               <div style={{ width: '100%', margin: 'atuo' }}>
                  <Button overrides={{ BaseButton: { style: { marginTop: "25px", backgroundColor: "#eda107", color: "#FFFFFF" } } }}
                     onClick={() => setIsCancelSubscription(!isCancelSubscription)}>Cancel Subscription</Button>
               </div>
            </div>
         </div>
      </>}
   </>
};
const BlurBackground = styled('div')({
   width: '100%',
   position: "fixed",
   backgroundClip: 'white',
   top: "10px",
   left: "0px",
   height: "100vh",
   filter: "blur(1px)",
   backdropFilter: "blur(1px)",
   zIndex: "1",
})
const SubscriptionContainer = styled('div')({
   position: "relative",
   width: '100%',
   zIndex: 100,
})
export default connect((state) => {
   return {
      billingStatus: state.ui.billing.status,
   }
}, { setBillingStatus })(withStripe(Account));