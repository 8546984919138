export const validateEmployment = (answers, maritalStatus, userName, spouseName) => {
   let validation = { isValid: true, errors: [] };
   let users = maritalStatus === 'married' ? [['main', userName], ['spouse', spouseName]] : [['main', userName]];
   users.forEach(([prefix, userName]) => {
      if(answers[prefix + 'RothIRAContribution'] > 6000){
         validation.isValid = validation.isValid && false;
         validation.errors.push({ location: `${prefix}RothIRAContribution`, message: `${userName} cannot contribute more than $6000 a year to a Roth IRA account` });
      }
   });
   return validation;
}