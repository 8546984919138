import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { FirebaseUserProvider } from './firebase/FirebaseUserContext';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { LivingLedgerReducers } from './reducers';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

const logger = store => next => action => {
    console.log('Dispatching', action);
    let result = next(action);
    console.log('Next State:', store.getState());
    return result;
}

const store = createStore(LivingLedgerReducers, applyMiddleware(thunk, logger));

ReactDOM.render(
    <Provider store={store}>
        <FirebaseUserProvider>
            <StyletronProvider value={new Styletron()}>
                <BaseProvider theme={LightTheme}>
                    <App />
                </BaseProvider>
            </StyletronProvider>
        </FirebaseUserProvider>
    </Provider>
    ,
    document.getElementById('root'));

//TODO look into enabling the below. It came autogenerated. 
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
