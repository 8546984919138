import React from 'react';
import { connect } from 'react-redux';
import { setFlowStatus, submitData } from '../../actions/userData';
import { getCurrentAgeYears, getMaritalStatus } from '../../calculations/userDataTransformer';
import { ORDER_OF_WITHDRAWAL_LINK, PORTFOLIO_LINK, SOCIAL_BENEFITS_LINK, SOCIAL_CHOICE_LINK } from '../flows/linkConstants';
import ConnectedPageGroup from './ConnectedPageGroup';

function makeSocialSecurityQuestions(prefix, userName, maritalStatus) {
    return [
        {
            questions: [
            ],
            shouldShow: (answers) => (maritalStatus === 'widowed' || maritalStatus === 'divorced'),
            text: 'Enter Social Security benefit:',
            descriptor: 'Social Security Benefits'
        },
        {
            questions: [
            ],
            shouldShow: (answers) => maritalStatus !== 'widowed',
            text: 'Enter details about ' + userName + '\'s estimated Social Security benefit:',
            descriptor: 'Social Security Benefits'
        },
        {
            questions: [
                {
                    id: prefix + 'Health', description: 'Health Condition', type: 'select', text: `How is ${userName}'s health?`, options: [
                        { id: 'poor', label: 'Poor' },
                        { id: 'average', label: 'Average' },
                        { id: 'excellent', label: 'Excellent' },
                    ],
                    help: "Your long term health is a major factor when considering when to begin receiving Social Security benefits",
                }
            ],
            shouldShow: (answers) => true,
            descriptor: 'Health',
        },
        {
            questions: [
                { id: prefix + 'Taking', description: userName + '\'s taking Social Security', type: 'boolean', text: `Is ${userName} currently receiving Social Security benefits?` },
            ],
            shouldShow: (answers) => true,
        },
        {
            questions: [
                { id: prefix + 'WorkedForNonCovered', description: 'Non-Covered Employment', type: 'boolean', text: `Does ${userName} fall under any of these rules?`, explaination: <img style={{ width: "100%", objectFit: "cover" }} src={"/wephelp.png"} alt="Windfall Elemination Provision reference chart" />, },
            ],
            shouldShow: (answers) => answers[prefix + 'Taking'] === false && answers[prefix + "WorkedForGovernment"] == true,
        },
        {
            questions: [
                { id: prefix + 'NumYearsSubstantialEarnings', description: 'Years of Substantial Earnings', type: 'number', text: `How many years of substantial earnings did ${userName} did pay into Social Security?` },
            ],
            shouldShow: (answers) => !answers[prefix + 'Taking'] && answers[prefix + 'WorkedForNonCovered'] === false && answers[prefix + "WorkedForGovernment"] == true,
        },
        {
            questions: [
                { id: prefix + 'FRABenefit', description: userName + '\'s monthly benefit', type: 'dollar', text: `What is ${userName}'s monthly benefit at Full Retirement Age?`, explaination: "" },
            ],
            shouldShow: (answers) => answers[prefix + 'Taking'] == false,
        },
        {
            questions: [
                { id: prefix + 'TakingAmount', description: userName + '\'s monthly benefit', type: 'dollar', text: `How much does ${userName} receive monthly?` },
            ],
            shouldShow: (answers) => answers[prefix + 'Taking'] && getCurrentAgeYears(answers[prefix + 'Dob']) >= 62,
        },
    ]
}

function previouslyMarriedQuestions(userName, maritalStatus) {
    return [
        {
            questions: [],
            shouldShow: (answers) => maritalStatus === 'divorced',
            text: 'Enter details for divorced spouse:'
        },
        {
            questions: [],
            shouldShow: (answers) => maritalStatus === 'divorced',
            text: 'If you were married to one or more spouses for at least 10 years, enter the information for your ex-spouse with the highest benefit. If you need assistance, Social Security will provide your benefit vaues at 800-772-1213, Monday to Friday, from 8:00 am to 7:00 pm local time. If necessory, go back to your profile and update the name and date of birth of your ex-spouse with the highest benefit.'
        },
        {
            questions: [],
            shouldShow: (answers) => maritalStatus === 'divorced',
            text: 'If you were married to a previous spouse for at least 1 year when they passed away and then remarried and got divorced after 10 years you are eligible for Survivor Benefits from your deceased spouse. If this applies to you and only if your Survivor Benefit is highe than your Spousal Benefit, change your profile designation to Widowed and start over.'
        },
        {
            questions: [
                { id: 'prevMarriedLongerThanTenYears', description: 'Were you married for at least 10 years?', type: 'boolean', text: `Were you married for at least 10 years?` },
            ],
            shouldShow: (answers) => maritalStatus === 'divorced',
            descriptor: 'Divorce Details'
        },
        {
            questions: [
                { id: 'divorcedMarriageHighestBenefit', description: 'Highest Benefit', type: 'dollar', text: `What is ${userName}'s (Full Retirement Age)FRA benefit?` },
            ],
            shouldShow: (answers) => getMaritalStatus(answers) === 'divorced' && answers.prevMarriedLongerThanTenYears === true,
        },
        {
            questions: [
                { id: 'divorcedMarriageYear', description: 'Divorce Date', type: 'pastDate', text: 'When was your divorce finalized?' }
            ],
            shouldShow: (answers) => getMaritalStatus(answers) === 'divorced' && answers.prevMarriedLongerThanTenYears === true,
        },
        {
            questions: [],
            shouldShow: (answers) => maritalStatus === 'divorced',
            text: 'You must be divorced for at least 2 years and both spouses must be at least 62 for spousal benefits to apply.'
        }
    ]
}

function widowedQuestions(prefix, userName) {
    return [
        {
            questions: [],
            shouldShow: (answers) => true,
            text: 'Enter details for your deceased Spouse:',
            descriptor: 'Divorce Details'
        },
        {
            questions: [
                { id: prefix + 'Taking', description: 'Are you currently receiving Survivor Benefits?', type: 'boolean', text: `Are you currently receiving Survivor Benefits?` },
            ],
            shouldShow: (answers) => true,
        },
        {
            questions: [
                { id: prefix + 'TakingAmount', description: 'survivors monthly benefit', type: 'dollar', text: `How much you receive monthly?` },
            ],
            shouldShow: (answers) => answers[prefix + 'Taking'],
        },
        {
            questions: [],
            shouldShow: (answers) => true,
            text: 'Social Security will provide your benefit values: 800-772-1213, Monday - Friday 8:00am - 7:00pm local time',
            descriptor: 'Divorce Details'
        },
        {
            questions: [
                { id: 'prevMarriedLongerThanTenYears', description: 'Were you married for at least 1 year when ${userName} passed away?', type: 'boolean', text: `Were you married for at least 1 year when ${userName} passed away?` },
            ],
            shouldShow: (answers) => !answers[prefix + 'Taking']
        },
        {
            questions: [
                { id: 'passedAwaySpouseBenefit', description: 'Late Spouse\'s Benefit', type: 'dollar', text: `What is ${userName}'s Full Retirement Benefit(FRA)?` },
            ],
            shouldShow: (answers) => !answers[prefix + 'Taking']
        }
    ]
}

function makePickerQuestion() {
    return [
        {
            questions: [
                { id: 'socialPicker', description: '', type: 'socialPicker', text: `` },
            ],
            shouldShow: () => true,
        },
    ]
}

export function makeSSQuestions(userName, spouseName, deceasedSpouseUserName, divorcedMarriageName, maritalStatus) {
    let sections = makeSocialSecurityQuestions('main', userName, maritalStatus);
    if (maritalStatus === 'married') {
        sections = sections.concat(makeSocialSecurityQuestions('spouse', spouseName, maritalStatus));
    } else if (maritalStatus === 'divorced') {
        sections = sections.concat(previouslyMarriedQuestions(divorcedMarriageName, maritalStatus));
    } else if (maritalStatus === 'widowed') {
        sections = sections.concat(widowedQuestions("survivors", deceasedSpouseUserName));
    }
    return sections;
}

export const makeFlows = (answers) => {
    let userName = answers.mainUserName;
    let spouseName = answers.spouseUserName;
    let deceasedSpouseUserName = answers.deceasedSpouseUserName;
    let divorcedMarriageName = answers.divorcedMarriageName;
    let maritalStatus = getMaritalStatus(answers);

    return {
        choice: {
            questionData: makePickerQuestion(),
            next: ORDER_OF_WITHDRAWAL_LINK,
            previous: SOCIAL_BENEFITS_LINK,
        },
        'expected-benefits': {
            questionData: makeSSQuestions(userName, spouseName, deceasedSpouseUserName, divorcedMarriageName, maritalStatus),
            next: SOCIAL_CHOICE_LINK,
            previous: PORTFOLIO_LINK,
        }
    }
};

const SocialSecurityFlow = ({ answers, subTab, setFlowStatus }) => {
    function finish(_, subTab) {
        if (subTab !== 'choice') {
            setFlowStatus({ name: subTab, status: 'finished' });
            setFlowStatus({ name: 'choice', status: 'pending' });
        } else {
            setFlowStatus({ name: 'choice', status: 'finished' })
            setFlowStatus({ name: 'cash-flow', status: 'finished' })
            setFlowStatus({ name: 'order-of-withdrawal', status: 'finished' })
            setFlowStatus({ name: 'livingledger', status: 'finished' })
        }
    }

    return <ConnectedPageGroup questionFlows={makeFlows(answers)} subTab={subTab} finish={finish} mainLink={SOCIAL_BENEFITS_LINK} /> //TODO Navigate Errors
}

export default connect(state => {
    return {
        answers: state.userData.answers,
    }
}, {
    setFlowStatus,
    submitData,
})(SocialSecurityFlow);
