import { RegularSSChoice, WidowedSSChoice } from "./SSChoice";

class CumulativeCalculator {
    constructor(scenarioBuilder) {
        this.mainSSCalc = scenarioBuilder.mainSSCalc;
        this.spouseSSCalc = scenarioBuilder.spouseSSCalc;
        this.maritalStatus = scenarioBuilder.maritalStatus;
    }

    buildProjections() {
        if (this.maritalStatus === 'married') {
            return this.buildJointCumulativeProjections();
        } else if (this.maritalStatus === 'widowed') {
            return this.buildWidowedCumulativeProjections();
        } else {
            return this.buildSingleCumulativeProjections()
        }
    }

    buildWidowedCumulativeProjections() {
        let mainAge = this.mainSSCalc.getCurrentAgeYears()
        let yearsTilYoungPersonIs100 = Math.max(100 - mainAge, 40)

        let yearsToShaveOff = Math.max(0, Math.min(62 - mainAge));
        let yearToStartIn = yearsToShaveOff + new Date().getFullYear();
        yearToStartIn = Math.floor(yearToStartIn / 5) * 5 + (yearToStartIn % 5 === 0 ? 0 : 5)

        let mainSelection = this.mainSSCalc.getSSTakeAmountAndDate();

        let mainStartYear = mainSelection.takeDate ? new Date(mainSelection.takeDate).getFullYear() : 3000;
        let survivorStartYear = mainSelection.survivorTakeDate ? new Date(mainSelection.survivorTakeDate).getFullYear() : 3000;

        let startYear = Math.min(mainStartYear, survivorStartYear);

        let choice = new WidowedSSChoice(mainSelection)

        let mainCumulative = this.mainSSCalc.getWidowedCumulativeDataForChoice(startYear, choice)

        let graphData = [];
        //Find minimum of ages, see if either is less than 62
        for (let i = yearToStartIn; i < yearToStartIn + yearsTilYoungPersonIs100; i += 5) {
            let currentElem = { year: i };

            let mainIndex = i - startYear;
            if (mainIndex >= 0 && mainCumulative[mainIndex]) {
                currentElem['mainOption'] = ((mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0) / 12).toFixed(0);
                currentElem['widowedOption'] = ((mainSelection.survivorAmountAnnual !== undefined ? mainSelection.survivorAmountAnnual : 0) / 12).toFixed(0);
            }

            graphData.push(currentElem)
        }

        return {
            mainMonthly: mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0,
            survivorYearly: mainSelection.survivorAmountAnnual !== undefined ? mainSelection.survivorAmountAnnual : 0,
            graphData,
            empty: !(mainSelection && (mainSelection.takeAmountAnnual !== undefined || mainSelection.survivorAmountAnnual !== undefined))
        };
    }

    buildSingleCumulativeProjections() {
        let mainAge = this.mainSSCalc.getCurrentAgeYears()
        let yearsTilYoungPersonIs100 = Math.max(100 - mainAge, 40)

        let yearsToShaveOff = Math.max(0, Math.min(62 - mainAge));
        let yearToStartIn = yearsToShaveOff + new Date().getFullYear();
        yearToStartIn = Math.floor(yearToStartIn / 5) * 5 + (yearToStartIn % 5 === 0 ? 0 : 5)

        let mainSelection = this.mainSSCalc.getSSTakeAmountAndDate();
        let mainStartYear = new Date(mainSelection.takeDate).getFullYear();

        let choice = new RegularSSChoice(mainSelection)

        let mainCumulative = this.mainSSCalc.getCumulativeDataForChoice(mainStartYear, choice)
        let graphData = [];
        //Find minimum of ages, see if either is less than 62

        for (let i = yearToStartIn; i < yearToStartIn + yearsTilYoungPersonIs100; i += 5) {
            let currentElem = { year: i };

            let mainIndex = i - mainStartYear;
            if (mainIndex >= 0 && mainIndex < 40) {
                currentElem['mainOption'] = ((mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0) / 12).toFixed(0);
            }

            graphData.push(currentElem)
        }

        return {
            mainMonthly: mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0,
            graphData,
            empty: !(mainSelection && mainSelection.takeAmountAnnual !== undefined)
        };
    }

    buildJointCumulativeProjections() {
        let mainAge = this.mainSSCalc.getCurrentAgeYears()
        let spouseAge = this.spouseSSCalc.getCurrentAgeYears()
        let yearsTilYoungPersonIs100 = Math.max(100 - Math.min(mainAge, spouseAge), 40)

        let yearsToShaveOff = Math.max(0, Math.min(62 - mainAge, 62 - spouseAge));
        let yearToStartIn = yearsToShaveOff + new Date().getFullYear();
        yearToStartIn = Math.floor(yearToStartIn / 5) * 5 + (yearToStartIn % 5 == 0 ? 0 : 5)

        let mainSelection = this.mainSSCalc.getSSTakeAmountAndDate();
        let spouseSelection = this.spouseSSCalc.getSSTakeAmountAndDate();

        let mainStartYear = new Date(mainSelection.takeDate).getFullYear();
        let spouseStartYear = new Date(spouseSelection.takeDate).getFullYear();

        let mainCumulative = this.mainSSCalc.getCumulativeTaking(mainStartYear, mainSelection.takeAmountAnnual)
        let spouseCumulative = this.spouseSSCalc.getCumulativeTaking(spouseStartYear, spouseSelection.takeAmountAnnual)
        let graphData = [];
        //Find minimum of ages, see if either is less than 62

        for (let i = yearToStartIn; i < yearToStartIn + yearsTilYoungPersonIs100; i += 5) {
            let currentElem = { year: i };

            let mainIndex = i - mainStartYear;
            if (mainIndex >= 0 && mainCumulative[mainIndex]) {
                currentElem['mainOption'] = ((mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0) / 12).toFixed(0);
            }

            let spouseIndex = i - spouseStartYear
            if (spouseIndex >= 0 && spouseCumulative[spouseIndex]) {
                currentElem['spouseOption'] = ((spouseSelection.takeAmountAnnual !== undefined ? spouseSelection.takeAmountAnnual : 0) / 12).toFixed(0);
            }
            graphData.push(currentElem)
        }

        return {
            mainMonthly: mainSelection.takeAmountAnnual !== undefined ? mainSelection.takeAmountAnnual : 0,
            spouseMonthly: spouseSelection.takeAmountAnnual !== undefined ? spouseSelection.takeAmountAnnual : 0,
            graphData,
            empty: !((mainSelection && mainSelection.takeAmountAnnual !== undefined) || (spouseSelection && spouseSelection.takeAmountAnnual !== undefined))
        };
    }
}

export { CumulativeCalculator };
