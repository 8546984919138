import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { finishNestedFlow, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { getMaritalStatus } from '../../calculations/userDataTransformer';
import { getAgeFromDob, getYearsUntil } from '../../utils/age';
import { INSURANCE_LINK } from "../flows/linkConstants";
import { validateHousing, validateInsurance } from '../flows/validate/expenseValidator';
import { ConnectedFlow } from './ConnectedFlow';

export const flows = (answers) => {
    let userName = answers.mainUserName;
    let spouseName = answers.spouseUserName;
    let maritalStatus = getMaritalStatus(answers);

    return {
        housing: {
            questionData: [
                {
                    questions: [
                        {
                            id: 'rentOrOwn', type: 'select', description: 'Rent or Own', text: `Do you own or rent your home?`, options: [
                                { id: 'rent', label: 'Rent' },
                                { id: 'own', label: 'Own' }
                            ]
                        },
                    ],
                    shouldShow: () => true,
                    text: 'Enter details on your cost of housing',
                    descriptor: 'Cost of Housing'
                },
                {
                    questions: [
                        { id: 'ownMortgageAmount', type: 'endableIncome', description: 'Monthly Mortgage Payment', text: `What is your monthly house payment, not including your property taxes and homeowners insurance?`, help: "Enter your principal and interest payment only. If your monthly payment includes your homeowners and property tax subtract them from your payment" },
                        { id: 'ownHomeownersInsurance', type: 'dollar', description: 'Annual Homeowners Insurance', text: `How much do you pay annually for homeowners insurance?`, help: "This payment will continue indefinitely." },
                        { id: 'ownPropertyTax', type: 'dollar', description: 'Annual Property Tax', text: `How much is your property tax annually?`, help: "This payment will continue indefinitely." },
                    ],
                    shouldShow: (answers) => answers.rentOrOwn && answers.rentOrOwn[0]?.id === 'own',
                },
                {
                    questions: [
                        { id: 'rentAmount', type: 'dollar', description: 'Rent Amount', text: `How much do you pay annually to rent?` },
                    ],
                    shouldShow: (answers) => answers.rentOrOwn && answers.rentOrOwn[0]?.id === 'rent',
                },
            ],
            validator: (answers) => validateHousing(answers),
            next: '/app/expenses/short-term-debts-&-expenses',
            previous: '/app/income/future',
        },
        'short-term-debts-&-expenses': {
            questionData: [
                {
                    questions: [
                        { id: 'shortTermDebts', type: 'shortTermDebts', description: 'Short Term Debts', text: ``, help: "Enter your short term obligations and the expected date when the debt will be retired" },
                    ],
                    shouldShow: () => true,
                    text: 'Short Term Debts & Expenses',
                    descriptor: 'Cost of Housing'
                },
            ],
            next: '/app/expenses/household-expenses',
            previous: '/app/expenses/housing',
        },
        'household-expenses': {
            questionData: [
                {
                    questions: [
                        { id: 'generalExpenses', type: 'generalExpenses', description: 'General Expenses', text: `` },
                    ],
                    shouldShow: () => true,
                    text: 'Enter your Household Expenses: ',
                    descriptor: 'Current Cost of Living'
                },
            ],
            next: '/app/expenses/future',
            previous: '/app/expenses/short-term-debts-&-expenses',
        },
        future: {
            questionData: makeFutureExpenseQuestions(userName, spouseName, maritalStatus),
            next: INSURANCE_LINK,
            previous: '/app/expenses/household-expenses',
        },
        insurance: {
            questionData: maritalStatus === 'married' ? makeMedicareQuestions('main', userName).concat(makeMedicareQuestions('spouse', spouseName)) : makeMedicareQuestions('main', userName),
            validator: (answers) => validateInsurance(answers, maritalStatus, userName, spouseName),
            next: '/app/portfolio',
            previous: '/app/expenses/future',
        },
    }
}

function makeMedicareQuestions(prefix, name) {
    /*
    Medicare Flow
    
    If >= 65 {
        if !employed { // NEEDS SET IN ANSWERS
            Set taking via IRMA for current date
        // assuming they are employed
        } else { // CHECKED
            are you enrolled for Medicare?
            - Yes -> Set taking via IRMA for current Date // NEEDS SET IN ANSWERS
            - No -> Set taking via IRMA for retire date // NEEDS SET IN ANSWERS
        }
    } else {
        if !employed {
            Set taking via IRMA at age 65
        }
        // assuming they are employed
        if age(retireDate) >= 65 { 
            Will you turn Medicare on at age 65?? // CHECKED
            - Yes => Age 65 (set taking via IRMA)
                if has health insurance at work
                    Will this replace health insurance through work?
                        Yes => Just set for backend
                        No => How much will new health insurance at work be?
                            Number
            - No => When retire (set taking via IRMA)
        } else { // retire before age 65
            - Set taking via IRMA at age 65
            - Would you like to budget extra between retirement and Medicare coverage
        }
    }
        
    */



    return [
        {
            questions: [
                { id: prefix + 'HasExtraLifeInsurance', type: 'boolean', description: 'Has Extra Life Insurance', text: `Does ${name} have a Private Life Insurance policy outside of employment?`, help: "We need to determine if this expense will continue after retirement" },
            ],
            shouldShow: () => true,
            text: 'Enter details about additional insurance policies ' + name + ' may have:',
            descriptor: 'Extra Insurances'
        },
        {
            questions: [
                { id: prefix + 'LifeInsuranceExpense', type: 'dollar', description: 'Extra Life Insurance', text: `What is your annual premium for this policy?`, help: "We will guide you in determining if this is a prudent expense" },
                { id: prefix + 'LifeInsuranceEndDate', type: 'futureDate', description: 'Extra Life Insurance - End Date', text: `When does this policy mature?` },
                { id: prefix + 'LifeInsuranceDeathBenefit', type: 'dollar', description: 'Extra Life Insurance - Death Benefit', text: `What is the death benefit amount?` },
            ],
            shouldShow: (answers) => answers[prefix + 'HasExtraLifeInsurance'] === true,
        },
        {
            questions: [
                { id: prefix + 'HasHealthInsurance', type: 'boolean', description: 'Has Extra Health Insurance', text: `Does ${name} have a Private Health Insurance policy outside of employment?` },
            ],
            shouldShow: (answers) => true,
        },
        {
            questions: [
                { id: prefix + 'HealthInsurancePremium', type: 'dollar', description: 'Extra Health Insurance Premium', text: `What is the annual premium?` },
            ],
            shouldShow: (answers) => answers[prefix + 'HasHealthInsurance'] === true,
        },
        {
            // > 65 and is employed
            questions: [
                { id: prefix + 'TakingMedicare', type: 'boolean', description: 'Taking Medicare Curently', text: `Is ${name} presently receiving Medicare?` },
            ],
            shouldShow: (answers) => getAgeFromDob(answers[prefix + 'Dob']) >= 65 && answers[prefix + 'IsEmployed'],
        },
        {
            // will be employed during or > age 65
            questions: [
                { id: prefix + 'WillTurnOnAt65', type: 'boolean', description: 'Turn on at 65 despite still working', text: `Will ${name} file for Medicare at age 65?` }
            ],
            shouldShow: (answers) => getAgeFromDob(answers[prefix + 'Dob']) < 65 && answers[prefix + 'IsEmployed'] && getAgeFromDob(answers[prefix + 'Dob']) + getYearsUntil(answers[prefix + 'RetireDate']) >= 65
        },
        {
            // will retire before age 65
            questions: [
                { id: prefix + 'BudgetExtraForUncovered', type: 'boolean', description: 'Budget for Extra', text: `Would ${name} like to budget extra for private health insurance between retirement and Medicare coverage?` },
            ],
            shouldShow: (answers) => answers[prefix + 'IsEmployed'] && getAgeFromDob(answers[prefix + 'Dob']) + getYearsUntil(answers[prefix + 'RetireDate']) < 65,
        },
        {
            // will retire before age 65 and DOES want to budget extra between retire and medicare
            questions: [
                { id: prefix + 'BudgetExtraForUncoveredAmount', type: 'dollar', description: 'Extra Healthcare Cost', defaultValue: 400, text: `We use $400 as the average monthly premium for an interim Health Insurance plan. If ${name} would like to use a different figure, enter it here:` },
            ],
            shouldShow: (answers) => answers[prefix + 'BudgetExtraForUncovered'] === true && answers[prefix + 'IsEmployed'] && getAgeFromDob(answers[prefix + 'Dob']) + getYearsUntil(answers[prefix + 'RetireDate']) < 65,
        },
        {
            questions: [
                { id: prefix + 'SupplementalAmount', type: 'dollar', description: 'New Supplemental Insurance Cost', defaultValue: 150, text: `We use $150 as the average monthly premium for Supplemental Health Insurance along with Medicare at age 65. If ${name} would like to use a different figure, enter it here:` },
            ],
            shouldShow: (answers) => true,
        },
    ]
}

function otherFutureExpenseSubQuestions() {
    return [
        {
            questions: [
                { id: 'name', type: 'string', text: `What is the name of this expense?`, help: 'i.e. pension, annuity, etc' },
                { id: 'amount', type: 'dollar', text: `Enter the expecting expense`, help: 'Enter amount' }
            ],
            shouldShow: () => true,
        },
        {
            questions: [
                { id: 'startDate', type: 'futureYear', text: 'When will this expense begin', help: 'Enter the year payments will begin' },
            ],
            shouldShow: (answers) => true
        },
        {
            questions: [
                { id: 'willEnd', type: 'boolean', text: 'Will these payments end?' },
            ],
            shouldShow: () => true
        },
        {
            questions: [
                { id: 'endDate', type: 'futureYear', text: 'When will this expense end?' },
            ],
            shouldShow: (answers) => answers['willEnd'] == true
        },
    ]
}

function makeFutureExpenseQuestions(userName, spouseName, maritalStatus) {
    return [

        {
            questions: [
                {
                    id: 'extraFutureExpenseTable',
                    type: 'futureExpenseTable',
                    description: `Additional Expense`,
                    text: `Add future planned expenses?`,
                    help: `Identify source and enter any future planned expenses that we have not identified`,
                    buttonDescription: "Add Future Expenses +",
                    questionSections: otherFutureExpenseSubQuestions()
                },
            ],
            text: `Future Expense`,
            shouldShow: () => true,
        }
    ]
}

const ExpenseFlow = ({ expenseFlows, subTab, submitData, finishNestedFlow, navigateErrors }) => {
    const history = useHistory();
    if (expenseFlows[subTab]) {
        return <ConnectedFlow finishFlow={() => {
            scrollTop();
            history.push(expenseFlows[subTab].next);
            finishNestedFlow('expenses', expenseFlows, subTab);
            submitData();
        }} flowMode={true}
            questionSections={expenseFlows[subTab].questionData}
            sectionValidator={expenseFlows[subTab].validator}
            goBack={() => {
                scrollTop();
                history.push(expenseFlows[subTab].previous);
                submitData();
            }} navigateErrors={navigateErrors} />
    }

    return <Redirect to='/app/expenses/housing' />
}

export default connect(state => {
    return {
        expenseFlows: flows(state.userData.answers)
    }
}, {
    finishNestedFlow,
    submitData
})(ExpenseFlow);
