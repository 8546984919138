import React from 'react';
import { connect } from 'react-redux';
import { getEndOfYearDate } from '../../calculations/userDataTransformer';
import TableApi from '../../firebase/TableApi';
import UserApi from '../../firebase/UserApi';
import { ErrorSplash } from '../error/ErrorScreen';
import LoadingScreen from './../LoadingScreen';
import CashFlowTables from './CashFlowTables';


const CashFlow = ({ peepAges, userData }) => {
  let [retrievedData, setRetrievedData] = React.useState(undefined);
  let [error, setError] = React.useState(undefined);

  React.useEffect(() => {
    UserApi.submitData(userData).then(() =>
      TableApi.getTableData().then(data => setRetrievedData(data)).catch(e => console.log(e) || setError(true)));
  }, []);

  if (error) {
    return <ErrorSplash />
  }

  if (!retrievedData) {
    return <LoadingScreen />
  }

  return (
    <div style={{ textAlign: "center", width: "calc(100vw-200px)", height: "100%" }}>
      <CashFlowTables cashFlowData={retrievedData} peepAges={peepAges} />
    </div>
  );
}

export default connect(state => {
  let peepAges = {
    [state.userData.answers.mainUserName]: {
      currentAge: getEndOfYearDate(state.userData.answers.mainDob),
    },
  }

  if (state.userData.answers.maritalStatus[0].id === 'married') {
    peepAges[state.userData.answers.spouseUserName] = {
      currentAge: getEndOfYearDate(state.userData.answers.spouseDob),
    }
  }

  return {
    peepAges,
    userData: state.userData
  }
})(CashFlow); 