import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { KIND, Notification } from 'baseui/notification';
import { StatefulPopover } from 'baseui/popover';
import { HeadingSmall, Label1, Label2 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { startConfirmModal } from '../../actions/ui';
import { setAllAnswers, setAnswer, setFlowStatus } from '../../actions/userData';
import { scrollToError } from '../../animation/scrollTo';
import ArrowButton from '../../onboard/ArrowButton';
import { defaultFlowValidator } from "../flows/validate/defaultValidator";
import ConfirmChangeModal from './ConfirmChangeModal';
import { QuestionInputs } from "./FlowInputs";

const HelpButton = ({ content }) => {
    return <StatefulPopover placement='left' content={() => <Block padding={"20px"} maxWidth={"30vw"}>{content}</Block>}><Button shape='round' overrides={{ BaseButton: { style: () => ({ marginTop: 0, marginRight: 0, marginBottom: 10, paddingTop: 12, paddingRight: 16, paddingBottom: 12, paddingLeft: 16 }) } }}>?</Button></StatefulPopover>;
}

const Flow = ({ questionSections, sectionValidator, answers, setAnswer, setAllAnswers, flowMode, finishFlow, goBack, startConfirmModal, navigateErrors, flows, setFlowStatus }) => {
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        setErrors(navigateErrors || {});
    }, [navigateErrors])

    const validate = () => {
        let [passed, errors, firstErrorLocation] = defaultFlowValidator(questionSections, sectionValidator, answers);
        console.log(passed, errors, firstErrorLocation)
        if (!passed) {
            scrollToError(firstErrorLocation)
            setErrors(errors)
        }
        return passed;
    }

    return <div style={{ width: '100%' }}>
        <div style={{ maxWidth: '800px', width: '95%', margin: 'auto' }}>
            {questionSections.map((section, sectionIndex) => {
                if (!section.shouldShow(answers)) {
                    return undefined;
                }

                let sectionText = flowMode ? section.text : section.descriptor;
                let smallDescriptor = section.smallDescriptor ? section.smallDescriptor : "";
                let sectionWarning = section.warning ? section.warning : "";
                return <div id={'section-' + sectionIndex}>
                    {smallDescriptor ? <Label1 style={{marginTop: "20px", fontSize: "13px" }}>{smallDescriptor}</Label1> : undefined}
                    {sectionText ? <HeadingSmall>{sectionText}</HeadingSmall> : undefined}
                    {sectionWarning ? <Label2 style={{ color: 'red', border: "4px solid #EEEEEE", marginBottom: "20px" }}>{sectionWarning}</Label2> : undefined}
                    {section.questions.map((question, index) => {
                        if (QuestionInputs[question.type]) {
                            let InputComponent = QuestionInputs[question.type].Component;
                            return <div style={{ width: '100%', display: "flex", flexDirection: "column" }} key={index + '-flow-' + question.id}>
                                {question.text || question.description ? <div style={{ width: '100%', display: "flex", justifyContent: "space-between" }}>
                                    <Label1 $style={{ float: 'left', marginTop: '15px', marginBottom: '5px' }}>{flowMode ? question.text : question.description}</Label1>
                                    <div style={{ float: 'right', marginTop: '15px', marginBottom: '5px' }}>{question.help ? <HelpButton content={question.help} /> : undefined}</div>
                                </div> : undefined}
                                {errors[question.id] ? <Notification kind={KIND.negative}>{() => errors[question.id]}</Notification> : undefined}
                                {question.explaination ? <div style={{ border: "4px solid #EEEEEE", marginBottom: "20px" }}>{question.explaination}</div> : null}
                                <div><InputComponent question={question} currentAnswer={answers[question.id]} setAnswer={(v) => {
                                    if (question.onChange && answers[question.id] !== undefined) {
                                        question.onChange(question.id, v, answers, setAllAnswers, startConfirmModal, setAnswer, flows, setFlowStatus);
                                    } else {
                                        setAnswer(question.id, v);

                                        if(v == false && question.nestedTable != undefined)
                                            setAnswer(question.nestedTable, []);
                                    }
                                }} />
                                </div>
                            </div>
                        }

                        return undefined;
                    })}
                </div>
            })
            }
            {errors.section ? <Notification kind={KIND.negative}>{() => errors.section}</Notification> : null}
        </div>
        <div style={{ maxWidth: '800px', width: '95%', margin: 'auto', textAlign: 'right' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', margin: '15px 0px' }}>
                {goBack !== undefined ? <ArrowButton
                    action={() => {
                        if (validate()) {
                            goBack()
                        }
                    }}
                    disabled={false}
                    type='left'
                /> : undefined}
                <ArrowButton
                    message={'Next'}
                    action={() => {
                        // console.log('titls')
                        // console.log(validate())
                        if (validate()) {
                            // console.log('validate')
                            finishFlow()
                        }
                    }}
                    disabled={false}
                    type='right'
                />
                <ConfirmChangeModal />
            </div>
        </div>
    </div>
}

export const FlowWithModal = ({ flowInfo, questionSections, initialValue, handleOpen, handleFinish }) => {
    initialValue = initialValue ? initialValue : {};
    const [answer, setAnswer] = React.useState();
    const [isOpen, setIsOpen] = React.useState(false);
    let stringifiedValue = JSON.stringify(initialValue);
    // eslint-disable-next-line 
    React.useEffect(() => setAnswer(initialValue), [stringifiedValue]);
    const handleClose = () => {
        if (answer !== initialValue) setAnswer(initialValue);
        setIsOpen(false);
    }

    const handleSetAnswer = (key, value) => {
        setAnswer({
            ...answer,
            [key]: value,
        });
    }

    return (
        <>
            <Button style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick={() => {
                if (handleOpen) handleOpen();
                setIsOpen(true);
            }}>{flowInfo.buttonText}</Button>
            <Modal size="large" onClose={handleClose} isOpen={isOpen} unstable_ModalBackdropScroll>
                <ModalHeader>{flowInfo.name}</ModalHeader>
                <ModalBody>
                    <Flow questionSections={questionSections} answers={answer} finishFlow={() => {
                        handleFinish(answer);
                        handleClose();
                    }} setAnswer={handleSetAnswer} flowMode />
                </ModalBody>
                <ModalFooter>
                    <ModalButton kind="tertiary" onClick={handleClose}>
                        Cancel
                </ModalButton>
                </ModalFooter>
            </Modal>
        </>
    );
}

export const ConnectedFlow = connect((state, ownProps) => {
    return {
        answers: state.userData.answers,
        flows: state.userData.flows
    };
}, {
    setAnswer,
    setAllAnswers,
    startConfirmModal,
    setFlowStatus
})(Flow);