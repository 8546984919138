import { useStripe } from "@stripe/react-stripe-js";
import { Button } from "baseui/button";
import { Notification } from 'baseui/notification';
import { Label1, Label2, HeadingSmall, Paragraph1 } from "baseui/typography";
import React from 'react';
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { PROFILE_LINK } from "../components/flows/linkConstants";
import Toolbar from '../components/Toolbar';
import BillingApi from '../firebase/BillingApi';
import { styles } from './styles/BeginSubscription.style';
import withStripe from "./withStripe";

const MobileMarketing = () => {
  return <>

    <div id='9:162' style={styles['9:162']}>
      <HeadingSmall style={styles['9:163']}>Congratulations! Your future’s looking brighter already. </HeadingSmall>
      <Paragraph1 style={{textAlign: 'justify'}}>
        Living Ledger will serve as your personal road map to retirement. You have full access to all the features of Living Ledger <u>FREE for 7 days</u>. Save your plan before your trial ends and subscribe for only $39 for the first year with unlimited access.
      </Paragraph1>
      <Paragraph1 style={{textAlign: 'justify'}}>
        Create variations to your plan including different retirement dates, Social Security claiming options, downsize your home, etc.
      </Paragraph1>
    </div>

  </>
}

const DesktopMarketing = () => {
  return <>

    <div id='9:162' style={styles['9:162']}>
      <HeadingSmall style={styles['9:163Desktop']}>Congratulations! Your future’s looking brighter already. </HeadingSmall>
      <Paragraph1 style={{textAlign: 'justify'}}>
        Living Ledger will serve as your personal road map to retirement. You have full access to all the features of Living Ledger <u>FREE for 7 days.</u> Save your plan before your trial ends and subscribe for only $39 for the first year with unlimited access.
      </Paragraph1>
      <Paragraph1 style={{textAlign: 'justify'}}>
        Create variations to your plan including different retirement dates, Social Security claiming options, downsize your home, etc.
      </Paragraph1>
      <br></br>
      <br></br>
    </div>
  </>
}

const BeginSubscription = () => {
  const history = useHistory();
  const stripe = useStripe();
  const [error, setError] = React.useState(undefined);
  const [spinning, setSpinning] = React.useState(false);
  const mobileLayout = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <div>
      <Toolbar />
      {mobileLayout ? <MobileMarketing /> : <DesktopMarketing />}
      <div style={{ width: 375, paddingTop: 75, display: 'flex', margin: 'auto' }}>
        <Button $style={styles['GreenButton']} isLoading={spinning} onClick={() => {
          setSpinning(true);
          BillingApi.purchase(stripe).then((resp) => {
            setSpinning(false)
            history.push(PROFILE_LINK);
          }).catch((error) => {
            setError(error);
            setSpinning(false);
          })
        }}>Subscribe</Button>
      </div>
      {error ? <Notification kind='negative'>{() => error.message}</Notification> : null}

    </div>
  );
}

export default withStripe(BeginSubscription);
