import { KIND as ButtonKind } from "baseui/button";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import React from 'react';
import { connect } from 'react-redux';
import { finishConfirmModal } from '../../actions/ui';

export const ConfirmChangeModal = ({ isOpen, text, confirm }) => {
    return <Modal isOpen={isOpen} onClose={(e) => confirm(e)} unstable_ModalBackdropScroll>
        <ModalHeader>Are you sure you want to proceed?</ModalHeader>
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
            <ModalButton kind={ButtonKind.tertiary} onClick={() => confirm(false)}>Cancel</ModalButton>
            <ModalButton onClick={() => confirm(true)}>Okay</ModalButton>
        </ModalFooter>
    </Modal>
}

export default connect(state => ({
    isOpen: !!state.ui.confirmingAction,
    text: state.ui.confirmingAction ? state.ui.confirmingAction.text : undefined,
}), {
    confirm: finishConfirmModal
})(ConfirmChangeModal);