import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { setFlowStatus, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { ConnectedFlow } from './ConnectedFlow';

const DefaultPage = ({ questionFlows, subTab, submitData, finish, navigateErrors, mainLink }) => {
    const history = useHistory();

    if (questionFlows[subTab]) {
        return <ConnectedFlow
            finishFlow={() => {
                scrollTop();
                history.push(questionFlows[subTab].next);
                finish(questionFlows, subTab);
                submitData();
            }}
            flowMode={true}
            questionSections={questionFlows[subTab].questionData}
            sectionValidator={questionFlows[subTab].validator}
            goBack={() => {
                scrollTop();
                history.push(questionFlows[subTab].previous);
                submitData();
            }}
            navigateErrors={navigateErrors} />
    }

    return <Redirect to={mainLink} />
}

export default connect(state => ({

}), {
    setFlowStatus,
    submitData,
})(DefaultPage);