import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFlowStatus, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { getMaritalStatus } from '../../calculations/userDataTransformer';
import { dobChange } from '../flows/changeHandlers/dateOfBirthday';
import { maritalStatusChangeHandler } from '../flows/changeHandlers/maritalStatusChangeHandler';
import { INCOME_LINK } from '../flows/linkConstants';
import { ConnectedFlow } from './ConnectedFlow';

export const profileQuestions = [
  {
    questions: [],
    shouldShow: () => true,
    text: 'Information',
    smallDescriptor: 'Living Ledger is 100% anonymous with no personal data recorded. Enter only first names and birthdates so you can track your projections by year; enter your state of residency for tax calculating. We do not use account numbers for your portfolio, just account values, type of account (401K, Roth, etc) and custodian for your reference purposes.'
  },
  {
    questions: [
      { id: 'GeneralReceiveNotification', description: 'Receive Update Notification Via Email', type: 'boolean', text: 'Receive update notification via email?', help: 'You will receive update notification via email' },
    ],
    shouldShow: () => true,
    text: 'Tell us a little about yourself:',
    descriptor: 'Receive Update Notification Via Email'
  },
  {
    questions: [
      { id: 'mainUserName', description: 'First Name', type: 'string', text: 'What is your first name?', help: 'Everything is on a first name basis' },
      { id: 'mainDob', description: 'Date of Birth', type: 'dob', text: 'What is your date of birth?', onChange: dobChange },
      {
        id: 'maritalStatus', description: 'Marital Status', type: 'select', text: 'What is your current marital status?', help: "Referenced to determine your tax deductions and your Social Security benefit options.",
        options: [
          { id: 'married', label: 'Married' },
          { id: 'divorced', label: 'Divorced' },
          { id: 'widowed', label: 'Widowed' },
          { id: 'never-married', label: 'Never been married' }],
        onChange: maritalStatusChangeHandler
      },
    ],
    shouldShow: () => true,
    text: 'Tell us a little about yourself:',
    descriptor: 'User Profile'
  },
  {
    questions: [
      { id: 'spouseUserName', description: 'First Name', type: 'string', text: 'What is your spouse\'s name?' },
      { id: 'spouseDob', description: 'Date of Birth', type: 'dob', text: 'What is your spouse\'s date of birth?' },
    ],
    shouldShow: (answers) => getMaritalStatus(answers) === 'married',
    text: 'Enter your spouse\'s details:',
    descriptor: 'Spouse Details',
  },
  {
    questions: [
      { id: 'divorcedMarriageName', description: 'What is your ex-spouse\'s name?', type: 'string', text: 'What is your ex-spouse\'s name?' },
      { id: 'divorceeDob', description: 'What is their date of birth?', type: 'dob', text: 'What is their date of birth?' },
    ],
    shouldShow: (answers) => getMaritalStatus(answers) === 'divorced',
    text: 'Enter your ex-spouse\'s details:',
    descriptor: 'Ex-Spouse Details',
  },
  {
    questions: [
      { id: 'deceasedSpouseUserName', description: 'What is your deceased spouse\'s name?', type: 'string', text: 'What is your deceased spouse\'s name?' },
      { id: 'deceasedSpouseDob', description: 'What is their date of birth?', type: 'dob', text: 'What is their date of birth?' },
    ],
    shouldShow: (answers) => getMaritalStatus(answers) === 'widowed',
    text: 'Enter your deceased spouse\'s details:',
    descriptor: 'Deceased Spouse Details',
  },
  {
    questions: [
      {
        id: 'stateOfResidence', type: 'select', description: 'State you file taxes in', text: `Which state do you file your taxes in?`, options: [
          { id: "Alabama", label: "Alabama" },
          { id: "Alaska", label: "Alaska" },
          { id: "Arizona", label: "Arizona" },
          { id: "Arkansas", label: "Arkansas" },
          { id: "California", label: "California" },
          { id: "Colorado", label: "Colorado" },
          { id: "Connecticut", label: "Connecticut" },
          { id: "Delaware", label: "Delaware" },
          { id: "District of Columbia", label: "District of Columbia" },
          { id: "Florida", label: "Florida" },
          { id: "Georgia", label: "Georgia" },
          { id: "Hawaii", label: "Hawaii" },
          { id: "Idaho", label: "Idaho" },
          { id: "Illinois", label: "Illinois" },
          { id: "Indiana", label: "Indiana" },
          { id: "Iowa", label: "Iowa" },
          { id: "Kansas", label: "Kansas" },
          { id: "Kentucky", label: "Kentucky" },
          { id: "Louisiana", label: "Louisiana" },
          { id: "Maine", label: "Maine" },
          { id: "Maryland", label: "Maryland" },
          { id: "Massachusetts", label: "Massachusetts" },
          { id: "Michigan", label: "Michigan" },
          { id: "Minnesota", label: "Minnesota" },
          { id: "Mississippi", label: "Mississippi" },
          { id: "Missouri", label: "Missouri" },
          { id: "Montana", label: "Montana" },
          { id: "Nebraska", label: "Nebraska" },
          { id: "Nevada", label: "Nevada" },
          { id: "New Hampshire", label: "New Hampshire" },
          { id: "New Jersey", label: "New Jersey" },
          { id: "New Mexico", label: "New Mexico" },
          { id: "New York", label: "New York" },
          { id: "North Carolina", label: "North Carolina" },
          { id: "North Dakota", label: "North Dakota" },
          { id: "Ohio", label: "Ohio" },
          { id: "Oklahoma", label: "Oklahoma" },
          { id: "Oregon", label: "Oregon" },
          { id: "Pennsylvania", label: "Pennsylvania" },
          { id: "Rhode Island", label: "Rhode Island" },
          { id: "South Carolina", label: "South Carolina" },
          { id: "South Dakota", label: "South Dakota" },
          { id: "Tennessee", label: "Tennessee" },
          { id: "Texas", label: "Texas" },
          { id: "Utah", label: "Utah" },
          { id: "Vermont", label: "Vermont" },
          { id: "Virginia", label: "Virginia" },
          { id: "Washington", label: "Washington" },
          { id: "West Virginia", label: "West Virginia" },
          { id: "Wisconsin", label: "Wisconsin" },
          { id: "Wyoming", label: "Wyoming" }

        ]
      },
    ],
    shouldShow: () => true,
    text: 'Tell us where you live:',
    descriptor: 'State of Residence'
  },
]

const ProfileFlow = (props) => {
  const history = useHistory();

  return <ConnectedFlow questionSections={profileQuestions} flowMode={false} finishFlow={() => {
    scrollTop();
    history.push(INCOME_LINK);
    props.setFlowStatus({ name: 'profile', status: 'finished' });
    props.setFlowStatus({ name: 'income', status: 'pending' });
    props.setFlowStatus({ name: 'employment', status: 'pending' });
    submitData()
  }} {...props} />
}

export default connect(undefined, {
  submitData,
  setFlowStatus
})(ProfileFlow);
