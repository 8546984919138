import { withStyles } from "@material-ui/core";
import { Label4 } from "baseui/typography";
import React, { Component } from 'react';
import AgeTables from './subTables/AgeTables';

const formatter = new Intl.NumberFormat('en', { style: 'decimal', currency: 'USD', maximumFractionDigits: 0 });

const openIcons = {
  expand: "M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z",
  close: "M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm-6.5 10h13v1h-13v-1z"
}

let cellWidth = 80;
let smCellWidth = 50;

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    // overflow: 'scroll',
    fontFamily: 'Times New Roman',
  },
  header: {
    fontSize: 32,
    fontWeight: 600
  },
  cashFlowContainer: {
    width: 'fit-content',
    display: 'flex',
    marginTop: 3,
    border: '2px solid gray',
    margin: 'auto',
    background: '#e0e4ea',
  },
  emptyFlow: {
    width: 'fit-content',
    display: 'flex',
    marginTop: 3,
    paddingTop: 3,
    border: '2px solid gray',
    background: '#e0e4ea',
  },
  adj: {
    fontSize: 12,
    fontWeight: 300,
    display: 'table',
    width: 50,
    height: '70%',
    paddingTop: 6,
    margin: 0,
    background: '#b2d0b0'
  },
  adjgreen: {
    fontSize: 12,
    fontWeight: 300,
    display: 'table',
    width: 50,
    height: '70%',
    paddingTop: 6,
    margin: 0,
    color: "inherit",
    background: 'inherit'
  },
  nameBlock: {
    width: 90,
    minHeight: 60,
    fontSize: 15,
    paddingTop: '0.25%',
    paddingBottom: '0.25%',
    display: 'table',
  },
  contentContainer: {
    display: 'flex',
    background: 'white',

  },
  contentRows: {
    display: 'table',
  },
  cashRowContainer: {
    display: 'flex',
    width: '100%',
  },
  rowLabel: {
    width: 200,
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1,
    padding: 2,
    textAlign: 'center',
    margin: 0,
    display: 'table',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: 90
    },
  },
  rowContainer: {
    display: 'flex',
    width: '100%',
  },
  amount: {
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 300,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  negativeAmount: {
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 300,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    color: 'red',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  emphasisRow: {
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  emphasisRowNegative: {
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    color: 'red',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  combinedPortfolioAmount: {
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  negativeCombinedPortfolioAmount: {
    backgroundColor: 'pink',
    width: cellWidth,
    fontSize: 12,
    padding: '6px 2px 0px 2px',
    fontWeight: 600,
    lineHeight: 1,
    textAlign: 'center',
    margin: 0,
    color: 'red',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  icons: {
    width: 24,
    height: 24,
    paddingBottom: 3,
    paddingTop: 3,
    cursor: 'pointer',
    '&:hover': {
      opacity: '40%'
    }
  },
  blockTitle: {
    width: 75,
    margin: 'auto',
    fontSize: 14,
    background: '#e0e4ea',
    display: 'table',
    paddingBottom: 10
  }
});

class CashFlowTables extends Component {
  state = {
    increment: 10,
    prevIncrement: 0,
    years: [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2, new Date().getFullYear() + 3, new Date().getFullYear() + 4, new Date().getFullYear() + 5, new Date().getFullYear() + 6, new Date().getFullYear() + 7, new Date().getFullYear() + 8, new Date().getFullYear() + 9],
    opener: 'open',
    items: {}
  }

  incrementYears = () => {
    let currentInc = this.state.increment;
    let yearsArr = this.state.years;
    let lastYear = yearsArr[yearsArr.length - 1]
    let newYears = [];
    for (let i = 1; i < 11; i++) {
      newYears.push(lastYear + i)
    }

    this.setState({
      increment: currentInc + 10,
      prevIncrement: currentInc,
      years: newYears
    })
  }

  decrementYears = () => {
    let currentInc = this.state.increment;
    let yearsArr = this.state.years;
    let lastYear = yearsArr[0];
    let newYears = [];

    for (let i = - 10; i < 0; i++) {
      newYears.push(lastYear + i)
    }

    this.setState({
      increment: currentInc - 10,
      prevIncrement: currentInc - 20,
      years: newYears
    })
  }

  makeSpecialTopBorder = (type) => {
    if (type == "Roth IRA") {
      return '5px double black';
    } else {
      return null;
    }
  }

  renderCashRows = (type, output, index) => {
    const { classes } = this.props;
    let adjOutput = formatter.format(output.adj);
    let adjOutputValue = adjOutput === 'NaN' ? 0 : adjOutput;

    if (!output.years) {
      return <div></div>
    }
    return (
      <div
        key={index}
        className={classes.cashRowContainer}
        style={{
          background: index % 2 === 0 ? '#e3e5e4' : 'white',
          borderTop: this.makeSpecialTopBorder(type)
        }}
      >
        <h3 className={classes.rowLabel}>{type}</h3>
        <div className={classes.rowContainer}>
          <h3 className={adjOutputValue > 0 ? classes.adj : classes.adjgreen }>{adjOutput === 'NaN' ? '' : (adjOutput > 0 ? adjOutput + '%' : '')}</h3>
          {
            output.years.map((amount, i) => {
              if (i < this.state.increment && i >= this.state.prevIncrement) {
                if (type === 'Total Portfolio Value') {
                  return <div key={i} className={amount >= 0 ? classes.combinedPortfolioAmount : classes.negativeCombinedPortfolioAmount}>{formatter.format(amount)}</div>
                } else if (type === "Net Income Adjustment" || type === "Net Income" || type === "Taxable Income") {
                  return <div key={i} className={amount >= 0 ? classes.emphasisRow : classes.emphasisRowNegative}>{formatter.format(amount)}</div>
                }
                return <div key={i} className={amount >= 0 ? classes.amount : classes.negativeAmount}>{formatter.format(amount)}</div>
              }
              return undefined;
            })
          }
        </div>
      </div>
    )
  }

  cashFlowMapper = (categories, cashTypes, title) => {
    return cashTypes.map((type, index) => {
      // if (this.state.items[title] === 'open' && categories[type].status === 'open') {
      return this.renderCashRows(type, categories[type], index)
      // }
      // else if (this.state.items[title] === 'closed') return this.renderCashRows(type, categories[type], index)
      // return undefined
    })
  }

  expandItem = (title) => {
    let items = this.state.items;
    let current = items[title];
    let next = current === 'closed' ? 'open' : 'closed';
    items[title] = next;
    this.setState({ items })
  }

  findIcon = (index, title) => {
    return this.state.items[title] === 'closed' ? openIcons.close : openIcons.expand
  }

  renderIndividualFlow = (title, index, peepAges) => {
    const { classes, cashFlowData } = this.props;
    let items = this.state.items;

    if (title === 'ASSET BREAK') {
      return (
        <div>
          <h2 className={classes.header}>Investment Portfolio</h2>
          <AgeTables
            increment={this.state.increment}
            incrementYears={this.incrementYears}
            decrementYears={this.decrementYears}
            years={this.state.years}
            peepAges={peepAges}
          />
        </div>
      );
    }

    if (title === 'Summary') {
      console.log(cashFlowData[title])
    }

    if (!items[title]) {
      items[title] = 'closed'
      this.setState({ items })
    }

    let personInfo = cashFlowData[title];
    let cashTypes = Object.keys(personInfo.Label);
    let definedObj = Object.keys(cashTypes).length
    return (
      <div key={index} style={{ width: definedObj > 0 ? '90%' : '76.5%', margin: 'auto' }}>
        {
          definedObj > 0 ? (
            <div className={definedObj > 0 ? classes.cashFlowContainer : classes.emptyFlow}>
              <div className={classes.nameBlock}>
                {/* <svg
                  className={classes.icons}
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  onClick={() => this.expandItem(title)}
                >
                  <path d={this.findIcon(index, title)} />
                </svg> */}
                <p className={classes.blockTitle}>{title}</p>
              </div>
              <div className={classes.contentContainer}>
                <div className={classes.contentRows}>
                  {
                    this.cashFlowMapper(personInfo.Label, cashTypes, title)
                  }
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    )
  }

  renderCashFlows = (cashFlowData, peepAges) => {
    const { classes } = this.props;
    let peeps = Object.keys(cashFlowData);

    return peeps.map((person, index) => {
      return this.renderIndividualFlow(person, index, peepAges)
    })

  };

  render() {
    const { classes, cashFlowData, peepAges } = this.props;

    return (
      <div className={classes.container}>
        <h2 className={classes.header}>Cash Flow</h2>
        <AgeTables
          increment={this.state.increment}
          incrementYears={this.incrementYears}
          decrementYears={this.decrementYears}
          years={this.state.years}
          peepAges={peepAges}
        />
        {
          this.renderCashFlows(cashFlowData, peepAges)
        }
        <Label4>All projections are approximate and/or hypothetical. Target Returns represent hypothetical annualized figures without losses. Figures do not include real estate values, cash value in life insurance policies or cash value of Income Annuities.</Label4>
      </div>
    )
  }
};


export default withStyles(styles)(CashFlowTables);
