import { Button } from 'baseui/button';
import { Card } from 'baseui/card';
import { Notification } from 'baseui/notification';
import {
    TableBuilder,
    TableBuilderColumn
} from 'baseui/table-semantic';
import React from 'react';
import { FlowWithModal } from '../../components/interrogate/ConnectedFlow';

const IncomeTableInput = ({ id, updateInput, value, question: { description, tableType, questionSections, buttonDescription } }) => {
    if (!value) {
        value = [];
    }

    function saveIncome(income, index) {
        let newData = [...value];

        if (income.currentlyReceiving === true) {
            delete income.startDate;
        }

        if (income.willEnd !== true) {
            delete income.endDate;
        }

        index === undefined
            ? newData.push(income)
            : newData[index] = income;
        updateInput(newData);
    }

    function deleteIncome(indexToRemove) {
        let newData = [...value];
        newData.splice(indexToRemove, 1)
        updateInput(newData)
    }

    const buttonText = buttonDescription ? buttonDescription : 'Add Other Income Sources +';
    const Table = () => <>
        <TableBuilder data={value}>
            <TableBuilderColumn header="Name">
                {row => row.name ? row.name : description}
            </TableBuilderColumn>
            <TableBuilderColumn header="Amount">
                {row => '$' + row.amount}
            </TableBuilderColumn>
            <TableBuilderColumn header="Start Date">
                {row => row.startDate ? row.startDate : 'n/a'}
            </TableBuilderColumn>
            <TableBuilderColumn header="End Date">
                {row => row.endDate ? row.endDate : 'n/a'}
            </TableBuilderColumn>
            <TableBuilderColumn header="">
                {(row, index) => <>
                    <FlowWithModal
                        flowInfo={{ name: `Edit details for ${row.name ? row.name : description}`, buttonText: 'Edit' }} questionSections={questionSections}
                        initialValue={value[index]} handleFinish={(income) => saveIncome(income, index)} />
                    <Button ref={id} onClick={() => deleteIncome(index)}>Delete</Button>
                </>}
            </TableBuilderColumn>
        </TableBuilder>
        <br />
    </>;

    const AddButtonModal = () => <FlowWithModal
        flowInfo={{ name: `${description} Information`, buttonText }} questionSections={questionSections}
        handleFinish={(income) => saveIncome(income)} />;

    if (!value.length) {
        return <Card>
            <Notification overrides={{ Body: { style: { width: "auto" } } }}>{() => `No ${description.toLowerCase()} values yet. Click below to get started.`}</Notification>
            <AddButtonModal />
        </Card>;
    }
    return <>
        <Table />
        {value.length > 0 && tableType === 'single' ? null : <AddButtonModal />}
    </>;
}

export default IncomeTableInput;
