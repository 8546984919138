import { setAllAnswersAndFlows } from "../../../actions/userData";
import { getMaritalStatus } from "../../../calculations/userDataTransformer";

export const maritalStatusChangeHandler = (id, newAnswer, answers, setAllAnswersDispatch, startConfirmModal, setAnswer, flows, setFlowStatus) => {
    let willNuke = checkIfWillNukePreviousAnswers(getMaritalStatus(answers), newAnswer, answers);
    if (willNuke) {
        let newAnswers = JSON.parse(JSON.stringify(answers));
        let prevAnswer = getMaritalStatus(answers);
        newAnswers['maritalStatus'] = newAnswer;

        let newFlows = { ...flows };
        newFlows['choice'] = 'not-started';
        newFlows['order-of-withdrawal'] = 'not-started';
        newFlows['livingledger'] = 'not-started';
        newFlows['cash-flow'] = 'not-started';

        if (prevAnswer === 'married') {
            for (let key of Object.keys(answers)) {
                if (key.startsWith('spouse')) {
                    delete newAnswers[key];
                }
            }
        }

        //TODO also need to do if they go to married because we can get in a weird spot if we dont
        if (prevAnswer === 'married') {
            delete newAnswers['cashFlowOrder']; //Reset Cashflow order, but we could probably do this smartly
        }

        startConfirmModal('This will wipe out some of your answers you\'ve supplied that are specific to your previous marital status.', setAllAnswersAndFlows(newAnswers, newFlows), setAnswer('maritalStatus', answers.maritalStatus));
    } else {
        if (newAnswer?.length > 0 && newAnswer[0].id === 'married') {
            setFlowStatus({ name: 'deductions', status: 'not-started' });
            setFlowStatus({ name: 'other', status: 'not-started' });
            setFlowStatus({ name: 'rental', status: 'not-started' });
            setFlowStatus({ name: 'future', status: 'not-started' });
            setFlowStatus({ name: 'portfolio', status: 'not-started' });
            setFlowStatus({ name: 'expenses', status: 'not-started' });
            setFlowStatus({ name: 'short-term-debts-&-expenses', status: 'not-started' });
            setFlowStatus({ name: 'housing', status: 'not-started' });
            setFlowStatus({ name: 'household-expenses', status: 'not-started' });
            setFlowStatus({ name: 'insurance', status: 'not-started' });
            setFlowStatus({ name: 'expected-benefits', status: 'not-started' });

            setFlowStatus({ name: 'choice', status: 'not-started' });
            setFlowStatus({ name: 'order-of-withdrawal', status: 'not-started' });
            setFlowStatus({ name: 'livingledger', status: 'not-started' });
            setFlowStatus({ name: 'cash-flow', status: 'not-started' });
            setFlowStatus({ name: 'social-security', status: 'not-started' });
        }


        let newAnswers = { ...answers };
        delete newAnswers['cashFlowOrder'];
        newAnswers['maritalStatus'] = newAnswer;
        setAllAnswersDispatch(newAnswers);
    }
}

function checkIfWillNukePreviousAnswers(prevAnswer, newAnswer, answers) {
    if (prevAnswer === 'married') {
        for (let key of Object.keys(answers)) {
            if (key.startsWith('spouse')) {
                return true;
            }
        }
    }
    return false;
}