import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Firebase from "../firebase/Firebase";

const withStripe = Component => {
    class WithStripe extends React.Component {
        constructor(props) {
            super(props);
        }

        render() {
            let pk = Firebase.env === 'production' ?
                'pk_live_51I37TyIqfBCIaYUmZBomqcAyd6OLoeP4Hf6YgkXvCeZLIY7t718yihNoLeIH4uw5kuNBBnyZMtbbnRD5I3K8CBqU00kd87bkZz' :
                'pk_test_51I37TyIqfBCIaYUmqWUJ1uKdVpynk0P0fxcqmEudFV8iYyK6prykxdEJPapZxK7eYRj8tR0KZZajrGOVgYaz5U7J00sCY3GZcb';

            return (
                <Elements stripe={loadStripe(pk)}>
                    <Component {...this.props} />
                </Elements>
            )
        }
    }

    return WithStripe;
}

export default withStripe;