import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledContents } from "baseui/card";
import { Input } from "baseui/input";
import { Notification } from "baseui/notification";
import { H3 } from "baseui/typography";
import firebase from "firebase";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";

const ResetPassword = () => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const oobCode = params.get("oobCode");

  const [success, setSuccess] = React.useState();
  const [error, setError] = React.useState();
  const [password, setPassword] = React.useState();
  const [fbResponse, setFBResponse] = React.useState();

  React.useEffect(() => {
    if (oobCode) {
      firebase.auth()
        .verifyPasswordResetCode(oobCode)
        .then((res) => {
          setFBResponse(res);
        })
        .catch((e) => {
          setError(e);
          setFBResponse(e);
        });
    }
  }, [oobCode]);

  const resetPassword = () => {
    firebase.auth()
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        setSuccess("Your password has successfully been reset!");
        setTimeout(() => history.push("/"), 3000);
      })
      .catch((e) => setError(e));
  };

  if (!oobCode || error) return <InvalidParamsMessage />;
  if (!fbResponse) return <LoadingScreen />;
  return (
    <Wrapper>
      <Card overrides={{ Action: { style: () => ({ paddingLeft: "16px", paddingRight: "16px" }) } }}>
        <H3>Reset Password for {fbResponse}</H3>
        <StyledContents>
          <Input
            placeholder='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            overrides={{ BaseButton: { style: { width: "100%", marginTop: "10px" } } }}
            onClick={() => resetPassword()}
            disabled={!password}>
            Reset Password
          </Button>
        </StyledContents>
      </Card>
      {success ? <Notification>{success}</Notification> : null}
      {error ? <Notification kind='negative'>{() => error.message}</Notification> : null}
    </Wrapper>
  );
};

const Wrapper = ({ children }) => (
  <Block width='100vw' height='100vh' display='flex' justifyContent='center' alignItems='center'>
    <Block width='400px'>{children}</Block>
  </Block>
);

const InvalidParamsMessage = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <Card>
        <StyledContents>
          <H3>Reset Your Password</H3>
          <Notification kind='negative' overrides={{ Body: { style: { width: "90%" } } }}>
            Error: <br /> Password link is either invalid or expired, please request another password refresh link below
          </Notification>
          <Button
            overrides={{ BaseButton: { style: { width: "100%", marginTop: "10px" } } }}
            onClick={() => history.push(`/`)}>
            Login
          </Button>
        </StyledContents>
      </Card>
    </Wrapper>
  );
};

export default ResetPassword;
