import React from 'react';
import { Input, SIZE } from 'baseui/input';

const TextInput = ({ id, fieldType, updateInput, value }) => {
   return (
      <Input
         value={value || ''}
         onChange={updateInput}
         name={id}
         size={SIZE.large}
         type={fieldType}
      />
   );
}

export default TextInput;