import moment from 'moment';

export const getAgeFromDob = (dob) => {
    return moment().diff(moment(dob, 'MM/DD/YYYY'), 'years');
};

export const getYearsUntil = (futureDate) => {
    return -moment().diff(moment(futureDate, 'MM/DD/YYYY'), 'years');
}

