import { Button } from 'baseui/button';
import { Card } from 'baseui/card';
import { Notification } from 'baseui/notification';
import {
  TableBuilder,
  TableBuilderColumn
} from 'baseui/table-semantic';
import React from 'react';
import { FlowWithModal } from '../../components/interrogate/ConnectedFlow';

const AssetTableInput = ({ setAnswer, currentAnswer, question: { description, joint, questionSections } }) => {
  if (!currentAnswer) {
    currentAnswer = [];
  } else {
    currentAnswer = currentAnswer.sort((a, b) => (a.custodian.toLowerCase() > b.custodian.toLowerCase()) ? 1 : -1)
  }

  function replaceAsset(asset, indexToRemove) {
    let newData = [...currentAnswer];
    newData.splice(indexToRemove, 1)
    newData.push({ type: joint ? "Non-Qualified" : asset.type, name: asset.name, amount: asset.amount, custodian: asset.custodian })
    setAnswer(newData);
  }

  function addAsset(asset) {
    let newData = [...currentAnswer];
    newData.push({ type: joint ? "Non-Qualified" : asset.type, name: asset.name, amount: asset.amount, custodian: asset.custodian })
    setAnswer(newData)
  }

  function removeAsset(indexToRemove) {
    let newData = [...currentAnswer];
    newData.splice(indexToRemove, 1)
    setAnswer(newData)
  }

  let formatter = new Intl.NumberFormat('en', { style: 'decimal', currency: 'USD', maximumFractionDigits: 0 });

  const Table = () => <>
    <TableBuilder data={currentAnswer}>
      <TableBuilderColumn header="Custodian">
        {row => row.custodian}
      </TableBuilderColumn>
      <TableBuilderColumn header="Name">
        {row => row.name}
      </TableBuilderColumn>
      <TableBuilderColumn header="Amount">
        {row => '$' + formatter.format(row.amount)}
      </TableBuilderColumn>
      <TableBuilderColumn header="">
        {(row, index) =>
          <>
            <FlowWithModal
              flowInfo={{ name: 'Edit Investment Account Details', buttonText: "Edit" }}
              initialValue={currentAnswer[index]}
              questionSections={questionSections}
              handleFinish={(asset) => replaceAsset(asset, index)}
            />
            <Button onClick={() => removeAsset(index)}>Delete</Button>
          </>
        }

      </TableBuilderColumn>
    </TableBuilder>
    <br />
  </>;

  const AddButtonModal = () => <FlowWithModal
    flowInfo={{ name: 'Investment Account Details', buttonText: joint ? "Add Joint Account" : "Add Individual Account" }} questionSections={questionSections}
    handleFinish={(asset) => addAsset(asset)}
  />;

  return <Card>
    {!currentAnswer.length ? <Notification overrides={{ Body: { style: { width: "auto" } } }}>{() => `No ${description.toLowerCase()} values yet. Click below to get started.`}</Notification> : <Table />}
    <AddButtonModal />
  </Card>;
}

export default AssetTableInput;
