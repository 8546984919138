import _ from 'lodash';
import { QuestionInputs } from "../../interrogate/FlowInputs";

export const defaultFlowValidator = (questionSections, sectionValidator, answers) => {
    let errors = {}
    let firstErrorId = undefined;
    questionSections.forEach((section, sectionIndex) => {
        if (section.shouldShow(answers)) {
            section.questions.forEach(question => {
                let inputValidation = QuestionInputs[question.type]?.validator && QuestionInputs[question.type]?.validator(answers[question.id], answers);
                if (inputValidation !== true) {
                    if (!firstErrorId) firstErrorId = 'section-' + sectionIndex
                    errors[question.id] = inputValidation?.length ? 'Error: ' + inputValidation : 'Error';
                }
                if (sectionValidator) {
                    const { isValid, errors } = sectionValidator(answers);
                    if (!isValid) errors.forEach(error => {
                        if (!firstErrorId) firstErrorId = 'section-' + sectionIndex
                        errors[error.location] = error.message
                    });
                }
            });
        }
    });

    if (!_.isEmpty(errors)) {
        return [false, errors, firstErrorId];
    }
    return [true, errors, firstErrorId];
}