import UserApi from '../firebase/UserApi';
import { setIrrecoverableError, setRecoverableError, setUserDataLoading } from './ui';

export const SET_ANSWER = 'SET_ANSWER';
export const SET_ALL_ANSWERS = 'SET_ALL_ANSWERS';
export const SET_ALL_ANSWERS_AND_FLOWS = 'SET_ALL_ANSWERS_AND_FLOWS';
export const SET_FLOW_STATUS = 'SET_FLOW_STATUS';
export const SET_ALL_DATA = 'SET_ALL_DATA';

export const setAnswer = (answerKey, answerValue) => ({
    type: SET_ANSWER,
    answerKey,
    answerValue
});

export const setAllAnswers = (newAnswers) => ({
    type: SET_ALL_ANSWERS,
    newAnswers
});

export const setAllAnswersAndFlows = (newAnswers, flows) => ({
    type: SET_ALL_ANSWERS_AND_FLOWS,
    newAnswers,
    flows
});

export const setAllData = (data) => ({
    type: SET_ALL_DATA,
    data
});

export const setFlowStatusAction = (flow) => ({
    type: SET_FLOW_STATUS,
    name: flow.name,
    status: flow.status,
});

export const setFlowStatus = (flow) => {
    return (dispatch, getState) => {
        dispatch(setFlowStatusAction(flow));
    }
}

export const loadUserData = (user) => {
    return (dispatch, getState) => {
        UserApi.getUserData(user).then(sanitizedData => {
            dispatch(setAllData(sanitizedData));
            dispatch(setUserDataLoading(false));
        }).catch(e => {
            console.error(e)
            dispatch(setIrrecoverableError('irrecoverable'))
            dispatch(setUserDataLoading(false));
        });
    }
}

export const finishNestedFlow = (currentBaseFlow, flows, subTab) => {
    return (dispatch) => {
        const nextFlowURL = flows[subTab].next.split('/');
        const nextBaseFlow = nextFlowURL[2];
        const nextFlowName = nextFlowURL[nextFlowURL.length - 1];
        if (nextBaseFlow !== currentBaseFlow) {
            dispatch(setFlowStatus({ name: currentBaseFlow, status: 'finished' }));
            dispatch(setFlowStatus({ name: nextBaseFlow, status: 'pending' }));
        }
        dispatch(setFlowStatus({ name: subTab, status: 'finished' }));
        dispatch(setFlowStatus({ name: nextFlowName, status: 'pending' }));
    }
}

export const submitData = () => {
    return (dispatch, getState) => {
        let currentState = getState();
        UserApi.submitData(currentState.userData)
            .catch((e) => {
                console.error(e)
                dispatch(setRecoverableError(e));
                UserApi.crashReport(e);
                dispatch(loadUserData());
            });
    }
}