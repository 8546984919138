import { HeadingMedium, Label1 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import ScenarioDataBuilder from '../../calculations/social/ScenarioBuilder';
import { formatterDecimal } from '../../calculations/SocialSecurityApi';
import { colors } from './GraphData';

export const CumulativeGraph = ({ health, data, width = 500 }) => {
    const height = Math.max(270, (3 / 8) * width);

    return <div style={{ width: width, justifyContent: 'center', margin: 0 }}>
        <HeadingMedium>Cumulative Comparison</HeadingMedium>
        <Label1>Illustrated below is a comparison between the cumulative amounts you will have received at each respective year,
        depending on when you choose to turn Social Security on.</Label1>
        <Label1>The red line illustrates the average mortality in the United States. You indicated that you have {' '}
            {health} health, so consider this when you are choosing your benefit.</Label1>
        <br></br>
        <BarChart width={width - 20} height={height} data={data.graphData} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="age" />
            <YAxis tickFormatter={(value) => '$' + formatterDecimal.format(value) + 'M'} />
            <Tooltip formatter={(value) => '$' + formatterDecimal.format(value) + 'M'} />
            <Legend />
            <ReferenceLine x="85" stroke="red" strokeWidth={4} />
            {
                Object.keys(data.raw)
                    .map((elem, index) =>
                        <Bar key={index} dataKey={elem} fill={colors[index]} name={"Start at " + data.raw[elem].age} />)
            }
        </BarChart>
    </div>
}

export default connect((state, ownProps) => {
    //TODO what about already taking
    let scenarioBuilder = new ScenarioDataBuilder(state.userData.answers);
    let data;
    if (ownProps.type === 'main') {
        data = scenarioBuilder.buildMainProjections();
    }
    else {
        data = scenarioBuilder.buildSpouseProjections();
    }

    let health = ownProps.type === 'spouse' ? state.userData.answers.spouseHealth[0]['id'] : state.userData.answers.mainHealth[0]['id']

    return {
        data,
        health,
    }
}, {})(CumulativeGraph);