export const styles = {
    "1:2": {
        "top": 0,
        "left": 0,
        "width": "100vw",
        "height": "100vh",
        "position": "absolute",
        "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "1:5": {
        "top": 0,
        "left": 0,
        "width": 375,
        "height": 812,
        "position": "absolute",
        "backgroundColor": "rgba(246.0000005364418, 246.0000005364418, 246.0000005364418, 1)"
    },
    "49:79": {
        "top": 560,
        "left": 112,
        "width": 149,
        "height": 34,
        "position": "absolute"
    },
    "4:77": {
        "margin": "15px",
        "top": 0,
        "left": 0,
        "width": 375,
        "height": 34,
        "color": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "49:78": {
        "top": 525,
        "left": 35,
        "width": 305,
        "height": 13,
        "position": "absolute",
        "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "4:72Phone": {
        "top": 0,
        "height": 25,
        "textShadow": "5px 5px 5px rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 0.5)",
        "color": "rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 1)"
    },
    "4:77Phone": {
        "color": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "4:72": {
        "top": 0,
        "marginLeft": 15,
        "width": 450,
        "height": 13,
        "textShadow": "5px 5px 5px rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 0.5)",
        "color": "rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 1)"
    },
    "49:77": {
        "top": 229,
        "left": 0,
        "width": 375,
        "height": 331,
        "position": "absolute",
        "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "9:22": {
        "top": 0,
        "width": "375px",
        "height": "auto", margin: 'auto',
        "position": "absolute",
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "maxWidth": "100%",
    },
    "4:13": {
        "top": 616,
        "width": 336,
        "height": 87,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center"
    },
    "I4:13;18526:102387": {
        "top": 0,
        "left": 0,
        "width": 1,
        "height": 1,
        "position": "absolute"
    },
    "I4:13;25025:65408": {
        "top": 0,
        "left": 0,
        "width": "100%",
        "height": 16,
        "position": "absolute",
        "color": "rgba(20.360154882073402, 66.93749696016312, 30.607171431183815, 1)"
    },
    "I4:13;25025:65409": {
        "top": 24,
        "left": 0,
        "width": "100%",
        "height": 36,
        "position": "absolute",
        "backgroundColor": "rgba(238.00000101327896, 238.00000101327896, 238.00000101327896, 1)"
    },
    "I4:13;26088:73577": {
        "top": 6,
        "left": 268,
        "width": 52,
        "height": 24,
        "position": "absolute",
        "backgroundColor": "rgba(39.00000147521496, 110.00000104308128, 241.00000083446503, 1)"
    },
    "I4:13;26088:73577;18526:116546": {
        "top": 0,
        "left": 39,
        "width": 1,
        "height": 1,
        "position": "absolute"
    },
    "I4:13;26088:73577;1244:64": {
        "left": 47,
        "width": "100%",
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "color": "rgba(255, 255, 255, 1)"
    },
    "I4:13;25025:65411": {
        "left": 16,
        "width": 35,
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "color": "rgba(117.00000062584877, 117.00000062584877, 117.00000062584877, 1)"
    },
    "I4:13;25025:65412": {
        "top": 68,
        "left": 0,
        "width": "100%",
        "height": 20,
        "position": "absolute",
        "color": "rgba(117.00000062584877, 117.00000062584877, 117.00000062584877, 1)"
    },
    "4:66": {
        "top": 703,
        "width": 128,
        "height": 45,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
    },
    "I4:66;18526:60768": {
        "top": 0,
        "left": 0,
        "width": 1,
        "height": 1,
        "position": "absolute"
    },
    "I4:66;643:23388": {
        "left": 0,
        "width": "100%",
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "backgroundColor": "rgba(110.96927583217621, 160.43749898672104, 93.58854487538338, 1)"
    },
    "I4:66;643:23389": {
        "left": 16,
        "width": "100%",
        "position": "relative",
        "marginTop": "auto",
        "marginBottom": "auto",
        "color": "rgba(0, 0, 0, 1)"
    },
    "4:75": {
        "top": 86,
        "left": 20,
        "width": 333,
        "height": 121,
        "position": "absolute"
    },
    "2:2": {
        "top": 10,
        "width": 269,
        "height": 20,
        "position": "relative",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "color": "rgba(29.8828125, 40.175795182585716, 132.8124949336052, 1)"
    },
    "1:6": {
        "top": -5,
        "width": "333px",
        "height": "auto",
        "marginLeft": "auto",
        "marginRight": "auto",
        "textAlign": "center",
        "backgroundColor": "rgba(0, 0, 0, 0)",
        "maxWidth": "100%",
    }
}
