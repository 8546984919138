import { Button } from 'baseui/button';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setAllData } from '../actions/userData';
import { FirebaseUserContext } from '../firebase/FirebaseUserContext';
import AuthUserContext from '../firebase/session/context';
import UserApi from '../firebase/UserApi';
import { ACCOUNT_LINK, ADMIN_PAGE_LINK } from './flows/linkConstants';


const ProfileDropdown = ({ setAllData }) => {
   const history = useHistory();
   let fb = useContext(FirebaseUserContext)
   let { authUser } = useContext(AuthUserContext);
   let [retreivedData, setRetreviedData] = React.useState({})


   React.useEffect(() => {
      UserApi.getUserData(authUser).then(sanitizedData => {
         setRetreviedData(sanitizedData)
      }).catch(e => {
         console.error(e)
      });
   }, [])

   return <>
      <div>
         <Button style={{ width: '150px' }}
            onClick={() =>
               history.push(ACCOUNT_LINK)
            }
         >
            Account
            </Button>
      </div>
      <div>
         <Button style={{ width: '150px' }}
            onClick={() => {
               fb.signOut();
            }}
         >
            Log Out
            </Button>
      </div>
      <div>
         <Button style={{ width: '150px' }}
            onClick={() => {
               setAllData({ answers: {}, flows: {} });
            }}>
            Reset Account
            </Button>
      </div>
      {/*<div>
         <Button style={{ width: '150px' }}
            onClick={() => {
               let dataStr = JSON.stringify(retreivedData);
               let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

               let exportFileDefaultName = retreivedData.answers.mainUserName ? retreivedData.answers.mainUserName + '_data.json' : 'myUser_data.json';

               let linkElement = document.createElement('a');
               linkElement.setAttribute('href', dataUri);
               linkElement.setAttribute('download', exportFileDefaultName);
               linkElement.click();
            }}>

            Export Data
         </Button>
      </div>*/}
      {authUser?.claims?.admin === true ?
         <div>
            <Button style={{ width: '150px' }}
               onClick={() => {
                  history.push(ADMIN_PAGE_LINK);
               }}>
               Admin Portal
            </Button>
         </div>
         : undefined
      }
   </>;
}

export default connect(() => ({

}), { setAllData })(ProfileDropdown);