import firebase from 'firebase';
import { v4 } from 'uuid';
import Firebase from "./Firebase";

class UserApi {
  constructor() {
    this.currentUserClaims = undefined;
  }

  async getUserData() {
    try {
      let document = await firebase.firestore().collection('users').doc(this.__getId()).get();
      let sanitizedData = document.data();
      if (sanitizedData === undefined || sanitizedData === null) {
        sanitizedData = { answers: {} };
      }
      if (!sanitizedData.answers) {
        sanitizedData.answers = {};
      }
      if (!sanitizedData.flows) {
        sanitizedData.flows = { 'profile': true };
      }
      return sanitizedData;
    } catch (e) {
      await this.crashReport(e.message);
      return Promise.reject(e);
    }
  }

  async crashReport(stackTrace) {
    try {
      let result = await firebase.firestore().collection('users').doc(this.__getId()).collection('crashes').doc(v4()).set({
        when: firebase.firestore.Timestamp.fromDate(new Date()),
        stackTrace: JSON.stringify(stackTrace)
      });
      return result;
    } catch (e) {
      console.error("We're hopeless...")
    }
  }

  async search(keyword) {
    try {
      let res = await firebase.functions().httpsCallable('search')({ keyword });
      return res.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async impersonate(uuid) {
    try {
      let result = await Firebase.fetch('/user/' + uuid + '/impersonate', {
        method: 'POST'
      });
      return result.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async unimpersonate() {
    try {
      let result = await Firebase.fetch('/user/unimpersonate', {
        method: 'POST'
      });
      return result.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getUserDetails(uuid) {
    try {
      let result = await Firebase.fetch('/user/' + uuid, {
        method: 'GET'
      });
      return result.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateClaims(uuid) {
    try {
      let result = await Firebase.fetch('/user/' + uuid + '/claims', {
        method: 'POST'
      });
      return result.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getAdmins() {
    try {
      let result = await firebase.functions().httpsCallable('getAdmins')();
      return result.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async removeAdmin(uid) {
    try {
      let res = await firebase.functions().httpsCallable('removeAdmin')({ uid });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async makeAdmin(uid) {
    try {
      let res = await firebase.functions().httpsCallable('makeAdmin')({ uid });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async copyDataToMine() {
    if (this.currentUserClaims.admin && this.currentUserClaims.impersonating) {
      let document = await firebase.firestore().collection('users').doc(this.currentUserClaims.impersonating).get();
      const res = await firebase.firestore().collection('users').doc(this.currentUserClaims.user_id).set(document.data());
    }
  }

  async submitData(userData) {
    try {
      if (this.currentUserClaims.admin && this.currentUserClaims.impersonating) {
        console.log('Not submitting data because not ours')
        return;
      }

      const res = await firebase.firestore().collection('users').doc(this.__getId()).set(userData);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getClaims() {
    return firebase.auth().currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        this.currentUserClaims = idTokenResult.claims;
        return idTokenResult.claims
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getCrashReports(uid) {
    try {
      const res = await firebase.firestore().collection('users').doc(uid).collection('crashes').orderBy('when', 'desc').limit(10).get();
      let crashes = [];
      res.forEach(snap => {
        crashes.push(snap.data())
      });
      return crashes;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async claimSnapshotListener(authUser, useClaims) {
    console.log(authUser.uid)

    return firebase.firestore()
      .collection('user-claims')
      .doc(authUser.uid)
      .onSnapshot(async document => {
        const claims = await this.getClaims();
        this.currentUserClaims = claims;
        let impersonate;
        try {
          if (claims.impersonating) {
            impersonate = await this.getUserDetails(claims.impersonating);
          }
          useClaims(claims, impersonate)
        } catch (err) {
          console.error('Something went wrong', err)
          useClaims({}, undefined)
        }
      })
  }

  async getAllUsers() {
    try {
      let result = await Firebase.fetchCSV('/user/list/all', {
        method: 'GET'
      });

      let filename = 'allUsers.csv';

      let blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  __getId() {
    return this.currentUserClaims.impersonating ? this.currentUserClaims.impersonating : this.currentUserClaims.user_id;
  }
}

export default new UserApi();
