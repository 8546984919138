import moment from 'moment';

class RegularSSChoice {
    constructor(savedData) {
        this.takeDate = savedData.takeDate;
        this.takeYear = moment(this.takeDate, 'MM/DD/YYYY').year();
        this.chosenAmount = savedData.takeAmountAnnual;
    }

    getAdjustedTakeAmount(forYear, amountToAdjust) {
        let adjustedTakeAmount = amountToAdjust;
        if (this.survivorTakeYear && forYear > this.survivorTakeYear && forYear < this.takeYear) {
            for (let i = 0; i < forYear - this.survivorTakeYear; i++) {

                adjustedTakeAmount *= 1.02;
            }
        } else {
            for (let i = this.takeYear ? this.takeYear : new Date().getFullYear(); i <= forYear; i++) {

                adjustedTakeAmount *= 1.02;
            }
        }
        return adjustedTakeAmount;
    }

    getYearlyAmount(forYear) {
        let amount = 0;

        if (forYear >= this.takeYear) {
            amount = this.chosenAmount;
        }

        return this.getAdjustedTakeAmount(forYear, amount);
    }
}

class WidowedSSChoice extends RegularSSChoice {
    constructor(savedData) {
        super(savedData);
        if (!this.takeDate) this.takeYear = 3000;
        this.survivorTakeDate = savedData.survivorTakeDate;
        this.survivorTakeYear = moment(this.survivorTakeDate, 'MM/DD/YYYY').year();
        this.survivorTakeAmount = savedData.survivorAmountAnnual;
    }

    getYearlyAmount(forYear) {
        let amount = 0;
        let type = 'main';

        if (this.takeYear < this.survivorTakeYear) {
            if (forYear >= this.survivorTakeYear) {
                amount = this.survivorTakeAmount;
                type = 'survivor';
            } else if (forYear >= this.takeYear) {
                amount = this.chosenAmount;
                type = 'main';
            }
        } else {
            if (forYear >= this.takeYear) {
                amount = this.chosenAmount;
                type = 'main';
            } else if (forYear >= this.survivorTakeYear) {
                amount = this.survivorTakeAmount;
                type = 'survivor'
            }
        }

        return { yearlyAmount: this.getAdjustedTakeAmount(forYear, amount), type };
    }
}

export { RegularSSChoice, WidowedSSChoice };
