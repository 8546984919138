import { Block } from 'baseui/block';
import { Navigation } from "baseui/side-navigation";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { scrollTop } from '../../animation/scrollTo';
import { CASHFLOW_LINK, INSURANCE_LINK, LIVING_LEDGER_LINK, ORDER_OF_WITHDRAWAL_LINK } from '../flows/linkConstants';

export default ({ submitData, flowStatus, validate, menuOpen, setMenuOpen }) => {
    const history = useHistory();
    const location = useLocation();

    const getSidebarItems = () => {
        const sidebar = [
            {
                title: 'Profile',
                itemId: '/app/profile',
            },
            {
                title: 'Income',
                itemId: '/app/income',
                subNav: [
                    { title: "Employment", itemId: "/app/income/employment" },
                    { title: "Deductions", itemId: "/app/income/deductions" },
                    { title: "Other", itemId: "/app/income/other" },
                    { title: "Rental", itemId: "/app/income/rental" },
                    { title: "Future", itemId: "/app/income/future" },
                ]
            },
            {
                title: 'Expenses',
                itemId: '/app/expenses',
                subNav: [
                    { title: "Housing", itemId: "/app/expenses/housing" },
                    { title: "Short Term Debts & Expenses", itemId: "/app/expenses/short-term-debts-&-expenses" },
                    { title: "Household Expenses", itemId: "/app/expenses/household-expenses" },
                    { title: "Future", itemId: "/app/expenses/future" },
                    { title: "Insurance", itemId: INSURANCE_LINK },
                ]
            },
            {
                title: 'Portfolio',
                itemId: '/app/portfolio',
            },
            {
                title: 'Social Security',
                itemId: '/app/social-security',
                subNav: [
                    { title: "Expected Benefits", itemId: "/app/social-security/expected-benefits" },
                    { title: "Choice", itemId: "/app/social-security/choice" },
                ]
            },
            {
                title: 'Cash Flow',
                itemId: CASHFLOW_LINK,
                subNav: [
                    { title: "Order Of Withdrawal", itemId: ORDER_OF_WITHDRAWAL_LINK },
                    { title: "LivingLedger", itemId: LIVING_LEDGER_LINK }
                ]
            },
        ];

        const processSidebarItems = sidebar => {
            return sidebar.map(sideBarItem => {
                const sideBarItemName = sideBarItem.title.toLowerCase().replace(/\s/g, '-');
                sideBarItem.disabled = flowStatus[sideBarItemName] !== "finished" && flowStatus[sideBarItemName] !== "pending";
                if (sideBarItem.subNav) sideBarItem.subNav = processSidebarItems(sideBarItem.subNav);
                return sideBarItem;
            }
            )
        }
        return processSidebarItems(sidebar);
    }

    return <Block
        display={[menuOpen ? 'block' : 'none', menuOpen ? 'block' : 'none', 'block', 'block']}
        minWidth={[menuOpen ? '200px' : '0px', menuOpen ? '200px' : '0px', '200px', '200px']}
        maxHeight={'100vh'}
        overflowY='auto'
        paddingLeft={0}
        paddingRight={0}>
        <Navigation
            items={getSidebarItems()}
            overrides={{
                Root: {
                    style: ({ $theme }) => {
                        return {
                            height: 'calc(100vh - 105px)',
                            paddingRight: '5px',
                            boxShadow: '3px 0px 4px 0px #ccc',
                            minWidth: 200,
                            maxHeight: '100vh',
                            overflowY: 'auto'
                        };
                    },
                },
                NavItem: {
                    style: ({ $active, $theme }) => {
                        if (!$active)
                            return {
                                ':hover': {
                                    color: $theme.colors.positive700,
                                    backgroundColor: $theme.colors.mono400,
                                },
                            };

                        return {
                            // width: 300,
                            backgroundColor: $theme.colors.positive500,
                            borderLeftColor: $theme.colors.mono900,
                            ':hover': {
                                // color: $theme.colors.positive700,
                                backgroundColor: $theme.colors.positive500,
                            },
                        }
                    },
                },
                NavItemContainer: {
                    style: ({ $theme }) => {
                        return {
                            // minBlockSize: 200,
                            // outline: `${$theme.colors.contentInverseTertiary} solid`,
                            // backgroundColor: $theme.colors.contentInverseTertiary,
                        };
                    }
                }
            }}
            activeItemId={location.pathname}
            onChange={({ event, item }) => {
                event.preventDefault(); // prevent page reload

                let validationResults = validate();
                if (!validationResults || (validationResults.length > 0 && validationResults[0])) {
                    scrollTop();
                    history.push(item.itemId);
                    setMenuOpen(false)
                    submitData();
                }
            }}
        />
    </Block>
}