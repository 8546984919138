import { useStripe } from '@stripe/react-stripe-js';
import { Button } from "baseui/button";
import { Card } from 'baseui/card';
import { Notification } from 'baseui/notification';
import { H1, Paragraph1 } from 'baseui/typography';
import React from 'react';
import ToolBar from '../components/Toolbar';
import BillingApi from '../firebase/BillingApi';
import withStripe from "./withStripe";


const ReinstateSubscription = () => {
  const stripe = useStripe();

  const [error, setError] = React.useState(undefined);

  if (!stripe) {
    return <div></div>
  }

  return (
    <div>
      <ToolBar />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: 800, width: 800 }}>
          <H1>Your Account is not Active</H1>
          <Paragraph1>Continue planning your retirement by reinstating your Living Ledger subscription.</Paragraph1>
          {error ? <Notification kind='negative'>{() => error.message}</Notification> : null}
          <Card>
            <Button overrides={{ BaseButton: { style: { backgroundColor: "#eda107", color: "#FFFFFF" } } }} onClick={() => {
              BillingApi.purchase(stripe).then(resp => console.log(resp)).catch(error => setError(error))
            }}>Reinstate</Button>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default withStripe(ReinstateSubscription);