// https://www.investopedia.com/terms/p/primary-insurance-amount.asp
// contains 2020 values, Myle's values are for old 2019 values
export const AIMEVals = {
	2019: [
		{
			"low": 0,
			"high": 926,
			"rate": 0.9
		},
		{
			"low": 926,
			"high": 5583,
			"rate": 0.32
		},
		{
			"low": 5583,
			"high": Number.MAX_VALUE,
			"rate": 0.15
		}
	],
	2020: [
		{
			"low": 0,
			"high": 960,
			"rate": 0.9
		},
		{
			"low": 960,
			"high": 5785,
			"rate": 0.32
		},
		{
			"low": 5785,
			"high": Number.MAX_VALUE,
			"rate": 0.15
		}
	],
	2021: [
		{
		  "low": 0,
		  "high": 996,
		  "rate": 0.9
		},
		{
		  "low": 996,
		  "high": 6002,
		  "rate": 0.32
		},
		{
		  "low": 6002,
		  "high": Number.MAX_VALUE,
		  "rate": 0.15
		}
  ],
  2022: [
		{
		  "low": 0,
		  "high": 1024,
		  "rate": 0.9
		},
		{
		  "low": 1024,
		  "high": 6172,
		  "rate": 0.32
		},
		{
		  "low": 6172,
		  "high": Number.MAX_VALUE,
		  "rate": 0.15
		}
  ],
  2023: [
		{
		  "low": 0,
		  "high": 1115,
		  "rate": 0.9
		},
		{
		  "low": 1115,
		  "high": 6721,
		  "rate": 0.32
		},
		{
		  "low": 6721,
		  "high": Number.MAX_VALUE,
		  "rate": 0.15
		}
  ],
  2024: [
		{
		  "low": 0,
		  "high": 1174,
		  "rate": 0.9
		},
		{
		  "low": 1174,
		  "high": 7078,
		  "rate": 0.32
		},
		{
		  "low": 7078,
		  "high": Number.MAX_VALUE,
		  "rate": 0.15
		}
  ]
};

export const exemptionAmounts = {
	2019: { preFRA: 18240, yearOfFRA: 48600 },
	2020: { preFRA: 18960, yearOfFRA: 50520 },
	2021: { preFRA: 19560, yearOfFRA: 51960 },
	2022: { preFRA: 22320, yearOfFRA: 59520 },
	2023: { preFRA: 22320, yearOfFRA: 59520 }
};

export const FRAValues = {
	1937: { years: 65, months: 0 },
  1938: { years: 65, months: 2 },
  1939: { years: 65, months: 4 },
  1940: { years: 65, months: 6 },
  1941: { years: 65, months: 8 },
  1942: { years: 65, months: 10 },
  1943: { years: 66, months: 0 },
  1944: { years: 66, months: 0 },
  1945: { years: 66, months: 0 },
  1946: { years: 66, months: 0 },
  1947: { years: 66, months: 0 },
  1948: { years: 66, months: 0 },
  1949: { years: 66, months: 0 },
  1950: { years: 66, months: 0 },
  1951: { years: 66, months: 0 },
  1952: { years: 66, months: 0 },
  1953: { years: 66, months: 0 },
  1954: { years: 66, months: 0 },
  1955: { years: 66, months: 2 },
  1956: { years: 66, months: 4 },
  1957: { years: 66, months: 6 },
  1958: { years: 66, months: 8 },
  1959: { years: 66, months: 10 },
  1960: { years: 67, months: 0 }
};

export const YOCValues = {
	30: 0.9,
	29: 0.85,
	28: 0.8,
	27: 0.75,
	26: 0.7,
	25: 0.65,
	24: 0.6,
	23: 0.55,
	22: 0.5,
	21: 0.45,
	20: 0.4
};

export const widowValues = {
	60: .715,
	61: .763,
	62: .81,
	63: .858,
	64: .905,
	65: .953,
};

export const FRAMonths = {
	1: { name: "January", percentage: 0 },
	2: { name: "February", percentage: 0.67 },
	3: { name: "March", percentage: 1.33 },
	4: { name: "April", percentage: 2.00 },
	5: { name: "May", percentage: 2.67 },
	6: { name: "June", percentage: 3.34 },
	7: { name: "July", percentage: 4.00 },
	8: { name: "August", percentage: 4.67 },
	9: { name: "September", percentage: 5.34 },
	10: { name: "October", percentage: 6.00 },
	11: { name: "November", percentage: 6.67 },
	12: { name: "December", percentage: 7.34 }
}

export const YOCReductionValues = {
	2024: {
		30: 0,
		29: 56,
		28: 112,
		27: 167,
		26: 223,
		25: 279,
		24: 335,
		23: 390,
		22: 446,
		21: 502,
		20: 558
	}
};

export const reductionMainPercent = {
	1: 0.99400,
	2: 0.98900,
	3: 0.98300,
	4: 0.97800,
	5: 0.97200,
	6: 0.96700,
	7: 0.96100,
	8: 0.95600,
	9: 0.95000,
	10: 0.94400,
	11: 0.93900,
	12: 0.93300,
	13: 0.92800,
	14: 0.92200,
	15: 0.91700,
	16: 0.91100,
	17: 0.90600,
	18: 0.90000,
	19: 0.89400,
	20: 0.88900,
	21: 0.88300,
	22: 0.87800,
	23: 0.87200,
	24: 0.86700,
	25: 0.86100,
	26: 0.85600,
	27: 0.85000,
	28: 0.84400,
	29: 0.83900,
	30: 0.83300,
	31: 0.82800,
	32: 0.82200,
	33: 0.81700,
	34: 0.81100,
	35: 0.80600,
	36: 0.80000,
	37: 0.79600,
	38: 0.79200,
	39: 0.78800,
	40: 0.78300,
	41: 0.77900,
	42: 0.77500,
	43: 0.77100,
	44: 0.76700,
	45: 0.76300,
	46: 0.75800,
	47: 0.75400,
	48: 0.75000,
	49: 0.74600,
	50: 0.74200,
	51: 0.73800,
	52: 0.73300,
	53: 0.72900,
	54: 0.72500,
	55: 0.72100,
	56: 0.71700,
	57: 0.71300,
	58: 0.70800,
	59: 0.70400,
	60: 0.70000,
	61: 0.69600,
	62: 0.69200,
	63: 0.68800,
	64: 0.68400,
	65: 0.68000,
	66: 0.67600,
	67: 0.67200,
	68: 0.66800,
	69: 0.66400,
	70: 0.66000,
	71: 0.65600,
	72: 0.65200,
	73: 0.64800,
	74: 0.64400
}

export const reductionSpousePercent = {
	1: 0.994,
	2: 0.986,
	3: 0.980,
	4: 0.972,
	5: 0.966,
	6: 0.958,
	7: 0.952,
	8: 0.944,
	9: 0.938,
	10: 0.930,
	11: 0.924,
	12: 0.916,
	13: 0.910,
	14: 0.902,
	15: 0.896,
	16: 0.888,
	17: 0.882,
	18: 0.876,
	19: 0.868,
	20: 0.862,
	21: 0.854,
	22: 0.848,
	23: 0.840,
	24: 0.834,
	25: 0.826,
	26: 0.820,
	27: 0.812,
	28: 0.806,
	29: 0.798,
	30: 0.792,
	31: 0.784,
	32: 0.778,
	33: 0.770,
	34: 0.764,
	35: 0.756,
	36: 0.750,
	37: 0.746,
	38: 0.742,
	39: 0.738,
	40: 0.734,
	41: 0.730,
	42: 0.726,
	43: 0.720,
	44: 0.716,
	45: 0.712,
	46: 0.708,
	47: 0.704,
	48: 0.700,
	49: 0.696,
	50: 0.692,
	51: 0.688,
	52: 0.684,
	53: 0.680,
	54: 0.676,
	55: 0.670,
	56: 0.666,
	57: 0.662,
	58: 0.658,
	59: 0.654,
	60: 0.650,
	61: 0.646,
	62: 0.642,
	63: 0.638,
	64: 0.634,
	65: 0.630,
	66: 0.628,
	67: 0.624,
	68: 0.620,
	69: 0.616,
	70: 0.612,
	71: 0.608,
	72: 0.604,
	73: 0.600
}

export const reductionSurvivorPercent = {
	1: 0.9966,
	2: 0.9932,
	3: 0.9898,
	4: 0.9864,
	5: 0.9830,
	6: 0.9796,
	7: 0.9762,
	8: 0.9728,
	9: 0.9694,
	10: 0.9660,
	11: 0.9626,
	12: 0.9592,
	13: 0.9558,
	14: 0.9524,
	15: 0.9490,
	16: 0.9456,
	17: 0.9422,
	18: 0.9388,
	19: 0.9354,
	20: 0.9320,
	21: 0.9286,
	22: 0.9252,
	23: 0.9218,
	24: 0.9184,
	25: 0.9150,
	26: 0.9116,
	27: 0.9082,
	28: 0.9048,
	29: 0.9014,
	30: 0.8980,
	31: 0.8946,
	32: 0.8912,
	33: 0.8878,
	34: 0.8844,
	35: 0.8810,
	36: 0.8776,
	37: 0.8742,
	38: 0.8708,
	39: 0.8674,
	40: 0.8640,
	41: 0.8606,
	42: 0.8572,
	43: 0.8538,
	44: 0.8504,
	45: 0.8470,
	46: 0.8436,
	47: 0.8402,
	48: 0.8368,
	49: 0.8334,
	50: 0.8300,
	51: 0.8266,
	52: 0.8232,
	53: 0.8198,
	54: 0.8164,
	55: 0.8130,
	56: 0.8096,
	57: 0.8062,
	58: 0.8028,
	59: 0.7994,
	60: 0.7960,
	61: 0.7926,
	62: 0.7892,
	63: 0.7858,
	64: 0.7824,
	65: 0.7790,
	66: 0.7756,
	67: 0.7722,
	68: 0.7688,
	69: 0.7654,
	70: 0.7620,
	71: 0.7586,
	72: 0.7552,
	73: 0.7518,
	74: 0.7484,
	75: 0.7450,
	76: 0.7416,
	77: 0.7382,
	78: 0.7348,
	79: 0.7314,
	80: 0.7280,
	81: 0.7246,
	82: 0.7212,
	83: 0.7178,
	84: 0.7144
}