import { Card, StyledBody } from 'baseui/card';
import React from 'react';
import { connect } from 'react-redux';
import { SUPPORT_MAILING_ADDRESS } from './errorConstants';

export const UnrecoverableError = () => {
    return <Card title="Error" >
        <StyledBody>
            Unfortunately we've hit an error. Please try again in a little while. <br />
        If this problem persists, please contact support <a href={"mailto:" + SUPPORT_MAILING_ADDRESS}>here.</a>
        </StyledBody>
    </Card>
}

export default connect(state => ({}), {})(UnrecoverableError);