import React from 'react';
import UserApi from '../../firebase/UserApi';
import { withFirebase } from '../FirebaseUserContext';
import AuthUserContext from './context';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.lastCommitted = undefined;
            this.state = {
                authUser: undefined,
                signedIn: false,
                claims: {
                    inTrials: true,
                    emailSent: false
                },
            }
        }

        async componentDidMount() {
            if (this.props.firebase.auth.isSignInWithEmailLink(window.location.href)) {
                this.props.firebase.emailIsVerified = true;
            }
            this.authStateListener = this.props.firebase.auth.onAuthStateChanged(
                async authUser => {
                    if (authUser) {
                        console.log(authUser)
                        this.claimsListener = await UserApi.claimSnapshotListener(authUser, (claims, impersonate) => {
                            this.setState({ authUser: { ...authUser, claims: claims, impersonate }, claims: claims })
                        });
                    }
                    authUser = authUser ? { ...authUser, claims: await UserApi.getClaims() } : { claims: {} };
                    this.setState({ signedIn: !!authUser, authUser: authUser ? authUser : null, claims: authUser.claims });
                }
            );
        }

        componentWillUnmount() {
            this.authStateListener();
            if (this.claimsListener) this.claimsListener();
        }

        render() {

            console.log(this.state.authUser)
            return (
                <AuthUserContext.Provider value={{ authUser: this.state.authUser, isSignedIn: this.state.signedIn }}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            )
        }
    }

    return withFirebase(WithAuthentication);
}

export default withAuthentication;
