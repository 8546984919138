import { withStyles } from "@material-ui/core";
import React, { Component } from 'react';


let cellWidth = 80;
let smCellWidth = 50;

const styles = theme => ({
  container: {
    width: '90%',
    maxWidth: 1200,
    margin: 'auto',
    fontFamily: 'Times New Roman',
  },
  equalizer: {
    width: 100,
  },
  adjuster: {
    width: 220,
    [theme.breakpoints.down('sm')]: {
      width: 140,
    }
  },
  ageContainer: {
    width: 'fit-content',
    margin: 'auto',
  },
  ageRow: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  peepContainer: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
  },
  yearContainer: {
    display: 'flex',
    width: '100%',
    marginTop: 16,
  },
  innerAgeContainer: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
  },
  yearStylesHeader: {
    fontSize: 16,
    fontWeight: 600,
    display: 'table',
    border: '0.5px solid black',
    width: 171,
    height: 20,
    margin: 0,
    background: '#e3e5e4',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 90,
    }
  },
  adj: {
    fontSize: 12,
    fontWeight: 600,
    display: 'table',
    border: '0.5px solid black',
    width: 50,
    height: 20,
    margin: 0,
    background: '#b2d0b0'
  },
  name: {
    fontSize: 16,
    display: 'table',
    fontWeight: 600,
    width: cellWidth,
    height: 20,
    textAlign: 'right',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: smCellWidth,
    }
  },
  yearStyles: {
    fontSize: 14,
    fontWeight: 300,
    border: '0.5px solid black',
    width: cellWidth,
    padding: '0px 1px',
    background: '#e3e5e4',
    height: 20,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: smCellWidth
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  innerContainer: {
    display: 'flex',
  },
  year: {
    fontSize: 14,
    fontWeight: 300,
    width: cellWidth,
    textAlign: 'center',
    padding: '0px 1px',
    height: 20,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: smCellWidth,
    },
    [theme.breakpoints.up(1520)]: {
      width: cellWidth,
    }
  },
  showMore: {
    fontSize: 12,
    fontWeight: 300,
    border: '0.5px solid black',
    width: 125,
    minWidth: 125,
    background: '#e3e5e4',
    height: 20,
    margin: '0 0 0 0',
    cursor: 'pointer',
    paddingTop: 5,
    [theme.breakpoints.up('lg')]: {
      width: cellWidth,
    },
    '&:hover': {
      opacity: '40%'
    }
  },
  spacer: { minWidth: 90 }
});

class AgeTables extends Component {
  ageRender = (years, peeps) => {
    const { classes, peepAges } = this.props;

    let count = this.props.years[0] - new Date().getFullYear()
    let outputAge = [];

    // eslint-disable-next-line 
    for (let obj in years) {
      outputAge.push(peepAges[peeps].currentAge + count)
      count++
    }

    return (
      <div className={classes.innerAgeContainer}>
        {
          outputAge.map((age, index) => {
            return (
              <h3 key={index} className={classes.year}>{age}</h3>
            )
          })
        }
      </div>
    )
  }

  renderAgeTable = () => {
    const { classes, peepAges } = this.props;

    let numPeeps = Object.keys(peepAges);
    let years = this.props.years;

    return (
      <div className={classes.ageContainer}>
        <div className={classes.equalizer}></div>
        {
          numPeeps.map((peeps, index) => {
            return (
              <div key={index} className={classes.peepContainer}>
                <div className={classes.equalizer} />
                <h3 className={classes.name}>{peeps}</h3>
                <div className={classes.adjuster} />
                <div className={classes.ageRow}>
                  {
                    this.ageRender(years, peeps)
                  }
                </div>
              </div>
            )
          })
        }
        <div className={classes.innerContainer}>
          <div className={classes.spacer}></div>

          <div className={classes.yearContainer}>
            <h3 className={classes.yearStylesHeader}>Year End</h3>
            <h3 className={classes.adj}>Variable</h3>
            {
              years.map((year, index) => {
                return (
                  <h3
                    key={index}
                    className={classes.yearStyles}
                  >{year}</h3>
                )
              })
            }
          </div>
          <div style={{ display: 'table', marginLeft: 15, marginRight: 'auto', justifyItems: 'left' }}>
            {
              this.props.years[0] > 2020 ? (
                <div
                  className={classes.showMore}
                  onClick={() => this.props.decrementYears()}
                >Previous Decade</div>) : null
            }
            {this.props.increment < 30 ? <div className={classes.showMore} onClick={() => this.props.incrementYears()}>Next Decade</div> : null}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {
          this.renderAgeTable()
        }
      </div>
    )
  }
};

export default withStyles(styles)(AgeTables); 
