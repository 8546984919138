import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { KIND, Notification } from 'baseui/notification';
import { defaultFlowValidator } from "../flows/validate/defaultValidator";
import { scrollToError } from '../../animation/scrollTo';
import { StatefulPopover } from 'baseui/popover';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Input, MaskedInput, SIZE } from "baseui/input";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { Select } from "baseui/select";
import { Label3, Paragraph1, HeadingSmall, Label1 } from "baseui/typography";
import ArrowButton from '../../onboard/ArrowButton';
import ConfirmChangeModal from './ConfirmChangeModal';
import React from "react";
import EndableIncome from "../../onboard/Question/EndableIncome";
import { GeneralExpenses, ShortTermDebts } from "../../onboard/Question/GeneralExpenses";
import IncomeTableInput from "../../onboard/Question/IncomeTableInput";
import FutureExpenseTableInput from "../../onboard/Question/FutureExpenseTableInput";
import FutureIncomeTableInput from "../../onboard/Question/FutureIncomeTableInput";
import RentalIncomeTableInput from "../../onboard/Question/RentalIncomeTableInput";
import InvestmentTableInput from "../../onboard/Question/InvestmentTableInput";
import AssetTableInput from "../../onboard/Question/AssetTableInput";
import { validateBoolean } from "../flows/validate/booleanValidator";
import { dobValidity, futureDateValidity, futureMonthAndYearValidity, futureYearValidity, pastValidator } from "../flows/validate/dateValidator";
import SocialSecurityChooser from "../social/SocialSecurityChooser";
import { Payroll } from "./Payroll";

const ConnectedIncome = ({ setAnswer, currentAnswer, question }) => {
    return <IncomeTableInput updateInput={(v) => setAnswer(v)} value={currentAnswer !== undefined ? currentAnswer : []} id={question.id} question={question} />
}

const ConnectedRentalIncome = ({ setAnswer, currentAnswer, question }) => {
    return <RentalIncomeTableInput updateInput={(v) => setAnswer(v)} value={currentAnswer !== undefined ? currentAnswer : []} id={question.id} question={question} />
}

const ConnectedFutureExpense = ({ setAnswer, currentAnswer, question }) => {
    return <FutureExpenseTableInput updateInput={(v) => setAnswer(v)} value={currentAnswer !== undefined ? currentAnswer : []} id={question.id} question={question} />
}

const ConnectedFutureIncome = ({ setAnswer, currentAnswer, question }) => {
    return <FutureIncomeTableInput updateInput={(v) => setAnswer(v)} value={currentAnswer !== undefined ? currentAnswer : []} id={question.id} question={question} />
}

const PensionComponent = ({ setAnswer, currentAnswer, question }) => {
    return <BooleanInput id={question.id} setAnswer={(v) => setAnswer(!v)} currentAnswer={!currentAnswer} question={question} />
}

const EndableIncomeComponent = ({ setAnswer, currentAnswer, question }) => {
    return <EndableIncome updateInput={(v) => setAnswer(v)} value={currentAnswer !== undefined ? currentAnswer : { incomeAmount: '' }} id={question.id} />
}

const DollarOrPercent = ({ setAnswer, currentAnswer, question, salary }) => {
    const handleSetValue = (value, type) => setAnswer({ [type]: value });
    return <div style={{ width: "100%", display: "flex", flexFlow: "row nowrap" }}>
        <div style={{ width: '100%' }}>
            <PercentageInput setAnswer={(value) => handleSetValue(value, 'percent')} question={question} currentAnswer={currentAnswer?.percent || ''} />
            {salary ? <div style={{ marginLeft: 'auto', textAlign: 'right' }}><Label3>(% of Salary)</Label3></div> : undefined}
        </div>
        <Paragraph1 overrides={{ Block: { style: () => ({ margin: "20px" }) } }}>or</Paragraph1>
        <DollarInput setAnswer={(value) => handleSetValue(value, 'dollar')} question={question} currentAnswer={currentAnswer?.dollar || ''} />
    </div>;
}

const DollarOrPercentSalary = (props) => {
    return <DollarOrPercent {...props} salary={true} />
}

const MonthlyOrAnnually = ({ setAnswer, currentAnswer, question, salary }) => {
    const handleSetValue = (value, type) => setAnswer({ [type]: value });
    return <div style={{ width: "100%", display: "flex", flexFlow: "row nowrap" }}>
        <div style={{ width: '100%' }}>
            <DollarInput setAnswer={(value) => handleSetValue(value, 'monthly')} question={question} currentAnswer={currentAnswer?.monthly || ''} />
            <div style={{ marginLeft: 'auto', textAlign: 'right' }}><Label3>(Monthly)</Label3></div>
        </div>
        <Paragraph1 overrides={{ Block: { style: () => ({ margin: "20px" }) } }}>or</Paragraph1>
        <div style={{ width: '100%' }}>
            <DollarInput setAnswer={(value) => handleSetValue(value, 'annual')} question={question} currentAnswer={currentAnswer?.annual || ''} />
            <div style={{ marginLeft: 'auto', textAlign: 'right' }}><Label3>(Annual)</Label3></div>
        </div>
    </div>;
}

const MonthlyOrAnnuallySalary = (props) => {
    return <MonthlyOrAnnually {...props} salary={true} />
}

const InvestmentTypePicker = ({ setAnswer, currentAnswer, question }) => {
    const makeLabel = value => {
        switch (value) {
            case 'NQ':
                return 'Non-Retirement';
            case 'Q':
                return 'Retirement';
            case 'TF':
                return 'Roth';
            default:
                break;
        }
    }
    const initialValue = currentAnswer ? [{ label: makeLabel(currentAnswer), id: currentAnswer }] : '';
    const [value, setValue] = React.useState(initialValue);
    return (<>
        <Select
            options={[
                { label: "Non-Retirement", id: "NQ" },
                { label: "Retirement", id: "Q" },
                { label: "Roth", id: "TF" },
            ]}
            value={value}
            placeholder="Select Type"

            onChange={(params) => {
                setValue(params.value)
                setAnswer(params.value[0]?.id)
            }}
            size={SIZE.large}
        />
    </>
    );
}

const DateInput = ({ setAnswer, currentAnswer, question }) => {
    let [value, setValue] = React.useState(currentAnswer || '');

    React.useEffect(() => {
        setValue(currentAnswer);
    }, [currentAnswer]);

    return (
        <MaskedInput
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
                if (value !== currentAnswer) {
                    setValue(currentAnswer)
                    setAnswer(value);
                }
            }}
            placeholder='mm/dd/yyyy'
            mask='99/99/9999'
            value={value}
            name={question.id}
        />
    );
}

const MonthAndYearInput = ({ setAnswer, currentAnswer, question }) => {
    let [value, setValue] = React.useState(currentAnswer || '');

    React.useEffect(() => {
        setValue(currentAnswer);
    }, [currentAnswer]);

    return (
        <MaskedInput
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
                if (value !== currentAnswer) {
                    setValue(currentAnswer)
                    setAnswer(value);
                }
            }}
            placeholder='mm/yyyy'
            mask='99/9999'
            value={value}
            name={question.id}
        />
    );
}

const YearInput = ({ setAnswer, currentAnswer, question }) => {
    let [value, setValue] = React.useState(currentAnswer || '');

    React.useEffect(() => {
        setValue(currentAnswer);
    }, [currentAnswer]);

    return (
        <MaskedInput
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
                if (value !== currentAnswer) {
                    setValue(currentAnswer)
                    setAnswer(value);
                }
            }}
            placeholder='yyyy'
            mask='9999'
            value={value}
            name={question.id}
        />
    );
}

export const BooleanInput = ({ updateInput, setAnswer, currentAnswer, question }) => {
    currentAnswer === true && (currentAnswer = 'true');
    currentAnswer === false && (currentAnswer = 'false');

    return (
        <RadioGroup
            onChange={e => {
                let newValue = e.target.value;
                newValue === 'true' && (newValue = true);
                newValue === 'false' && (newValue = false);
                setAnswer(newValue)
            }}
            name={question.id}
            align={ALIGN.horizontal}
            value={currentAnswer || undefined}
        >
            <Radio value="true">Yes</Radio>
            <Radio
                value={"false"}
            >
                No
            </Radio>
        </RadioGroup>
    );

}

const SelectInput = ({ setAnswer, currentAnswer, question }) => {
    return <Select options={question.options} value={currentAnswer} onChange={(e) => setAnswer(e.value)} />
}

export const TextInput = ({ setAnswer, currentAnswer, question }) => {
    return <Input
        value={currentAnswer || ''}
        onChange={(e) => setAnswer(e.target.value)}
        name={question.id}
        size={SIZE.large}
    />
}


const NumberInput = ({ setAnswer, currentAnswer, question }) => {
    React.useEffect(() => {
        if (currentAnswer === undefined) {
            setAnswer('0')
        }
    }, [])

    return <Input
        value={currentAnswer || ''}
        onChange={(e) => setAnswer(e.target.value)}
        name={question.id}
        size={SIZE.large}
        type='number'
    />
}

const PercentageInput = ({ setAnswer, currentAnswer, question }) => {
    React.useEffect(() => {
        if (currentAnswer === undefined && question.defaultValue) {
            setAnswer(question.defaultValue)
        }
    }, [])

    return (
        <FlexGrid flexGridColumnGap='scale90' flexGridRowGap={20} maxWidth={question.maxWidth ? question.maxWidth : "800px"}>
            <FlexGridItem>
                <Input
                    value={currentAnswer || ''}
                    onChange={(e) => setAnswer(e.target.value)}
                    name={question.id}
                    size={SIZE.large}
                    type='number'
                    endEnhancer='%'
                />
            </FlexGridItem>
        </FlexGrid>
    );
}

const PercentageWithoutPercentInput = ({ setAnswer, currentAnswer, question }) => {
    React.useEffect(() => {
        if (currentAnswer === undefined && question.defaultValue) {
            setAnswer(question.defaultValue)
        }
    }, [])

    return (
        <FlexGrid flexGridColumnGap='scale90' flexGridRowGap={20} maxWidth={question.maxWidth ? question.maxWidth : "800px"}>
            <FlexGridItem>
                <Input
                    value={currentAnswer || ''}
                    onChange={(e) => setAnswer(e.target.value)}
                    name={question.id}
                    size={SIZE.large}
                    type='number'
                    endEnhancer='%'
                />
            </FlexGridItem>
        </FlexGrid>
    );
}


const PercentageInputChange = ({ setAnswer, currentAnswer, question }) => {
    React.useEffect(() => {
        if (currentAnswer === undefined && question.defaultValue) {
            setAnswer(question.defaultValue)
        }
    }, [])

    return (
        <FlexGrid flexGridColumnGap='scale90' flexGridRowGap={20} maxWidth={question.maxWidth ? question.maxWidth : "800px"}>
            <FlexGridItem>
                <Input
                    value={currentAnswer || ''}
                    onChange={(e) => setAnswer(e.target.value)}
                    name={question.id}
                    size={SIZE.large}
                    type='number'
                    endEnhancer='%'
                />
            </FlexGridItem>
        </FlexGrid>
    );
}
const DollarInput = ({ setAnswer, currentAnswer, question }) => {
    let [value, setValue] = React.useState(currentAnswer || question.defaultValue || '0');
    React.useEffect(() => {
        if (currentAnswer === undefined) {
            if (question.defaultValue) {
                setAnswer(question.defaultValue)
            } else {
                setAnswer("0")
            }
        } else if (currentAnswer !== value) {
            setValue(currentAnswer)
        }
    }, [currentAnswer])

    return <Input
        value={value}
        onChange={(e) => { setValue(e.target.value)} }
        onBlur={() => {
            setAnswer(value)
        }}
        onFocus={() => {
            if (value === '0') {
                setValue('')
            }
        }}
        name={question.id}
        size={SIZE.large}
        type='number'
        endEnhancer='.00'
        startEnhancer='$'
    />
}

const DollarInputChange = ({ setAnswer, currentAnswer, question }) => {
    let [value, setValue] = React.useState(currentAnswer || question.defaultValue || '0');
    React.useEffect(() => {
        if (currentAnswer === undefined) {
            if (question.defaultValue) {
                setAnswer(question.defaultValue)
            } else {
                setAnswer("0")
            }
        } else if (currentAnswer !== value) {
            setValue(currentAnswer)
        }
    }, [currentAnswer])

    return <Input
        value={value}
        onChange={(e) => { setValue(e.target.value)} }
        onBlur={() => {
            setAnswer(value);
        }}
        onFocus={() => {
            if (value === '0') {
                setValue('')
            }
        }}
        name={question.id}
        size={SIZE.large}
        type='number'
        endEnhancer='.00'
        startEnhancer='$'
    />
}

const HelpButton = ({ content }) => {
    return <StatefulPopover placement='left' content={() => <Block padding={"20px"} maxWidth={"30vw"}>{content}</Block>}><Button shape='round' overrides={{ BaseButton: { style: () => ({ marginTop: 0, marginRight: 0, marginBottom: 10, paddingTop: 12, paddingRight: 16, paddingBottom: 12, paddingLeft: 16 }) } }}>?</Button></StatefulPopover>;
}

const Flow = ({ questionSections, sectionValidator, answers, setAnswer, setAllAnswers, flowMode, finishFlow, goBack, startConfirmModal, navigateErrors, flows, setFlowStatus }) => {
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        setErrors(navigateErrors || {});
    }, [navigateErrors])

    const validate = () => {
        let [passed, errors, firstErrorLocation] = defaultFlowValidator(questionSections, sectionValidator, answers);
        console.log(passed, errors, firstErrorLocation)
        if (!passed) {
            scrollToError(firstErrorLocation)
            setErrors(errors)
        }
        return passed;
    }

    return <div style={{ width: '100%' }}>
        <div style={{ maxWidth: '800px', width: '95%', margin: 'auto' }}>
            {questionSections.map((section, sectionIndex) => {
                if (!section.shouldShow(answers)) {
                    return undefined;
                }

                let sectionText = flowMode ? section.text : section.descriptor;
                return <div id={'section-' + sectionIndex}>
                    {sectionText ? <HeadingSmall>{sectionText}</HeadingSmall> : undefined}
                    {section.questions.map((question, index) => {
                        if (QuestionInputs[question.type]) {
                            let InputComponent = QuestionInputs[question.type].Component;
                            return <div style={{ width: '100%', display: "flex", flexDirection: "column" }} key={index + '-flow-' + question.id}>
                                {question.text || question.description ? <div style={{ width: '100%', display: "flex", justifyContent: "space-between" }}>
                                    <Label1 $style={{ float: 'left', marginTop: '15px', marginBottom: '5px' }}>{flowMode ? question.text : question.description}</Label1>
                                    <div style={{ float: 'right', marginTop: '15px', marginBottom: '5px' }}>{question.help ? <HelpButton content={question.help} /> : undefined}</div>
                                </div> : undefined}
                                {errors[question.id] ? <Notification kind={KIND.negative}>{() => errors[question.id]}</Notification> : undefined}
                                {question.explaination ? <div style={{ border: "4px solid #EEEEEE", marginBottom: "20px" }}>{question.explaination}</div> : null}
                                <div><InputComponent question={question} currentAnswer={answers[question.id]} setAnswer={(v) => {
                                    if (question.onChange && answers[question.id] !== undefined) {
                                        question.onChange(question.id, v, answers, setAllAnswers, startConfirmModal, setAnswer, flows, setFlowStatus);
                                    } else {
                                        setAnswer(question.id, v);

                                        if(v == false && question.nestedTable != undefined)
                                            setAnswer(question.nestedTable, []);
                                    }
                                }} />
                                </div>
                            </div>
                        }

                        return undefined;
                    })}
                </div>
            })
            }
            {errors.section ? <Notification kind={KIND.negative}>{() => errors.section}</Notification> : null}
        </div>
        <div style={{ maxWidth: '800px', width: '95%', margin: 'auto', textAlign: 'right' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', margin: '15px 0px' }}>
                {goBack !== undefined ? <ArrowButton
                    action={() => {
                        if (validate()) {
                            goBack()
                        }
                    }}
                    disabled={false}
                    type='left'
                /> : undefined}
                <ArrowButton
                    message={'Next'}
                    action={() => {
                        // console.log('titls')
                        // console.log(validate())
                        if (validate()) {
                            // console.log('validate')
                            finishFlow()
                        }
                    }}
                    disabled={false}
                    type='right'
                />
                <ConfirmChangeModal />
            </div>
        </div>
    </div>
}

export const QuestionInputs = {
    'boolean': {
        Component: BooleanInput,
        validator: validateBoolean
    },
    'string': {
        Component: TextInput,
        validator: (value) => value && value.length > 0
    },
    'number': {
        Component: NumberInput,
        validator: (value) => true
    },
    'dollar': {
        Component: DollarInput,
        validator: (value) => value !== undefined && value !== '' && value >= 0 ? true : "Invalid Entry"
    },
    'dollarChange': {
        Component: DollarInputChange,
        validator: (value) => value !== undefined && value !== '' && value >= 0 ? true : "Invalid Entry"
    },
    'percentage': {
        Component: PercentageInput,
        validator: (value) => true
    },
    'percentageOnly': {
        Component: PercentageWithoutPercentInput,
        validator: (value) => true
    },
    'dollarOrPercent': {
        Component: DollarOrPercent,
        validator: (value) => true
    },
    'dollarOrPercentSalary': {
        Component: DollarOrPercentSalary,
        validator: (value) => true
    },
    'MonthlyOrAnnuallySalary': {
        Component: MonthlyOrAnnuallySalary,
        validator: (value) => true
    },
    'select': {
        Component: SelectInput,
        validator: (value) => value && value.length > 0 ? true : "No selection"
    },
    'dob': {
        Component: DateInput,
        validator: (value) => dobValidity(value)
    },
    'futureDate': {
        Component: DateInput,
        validator: (value) => futureDateValidity(value)
    },
    'futureMonthAndYear': {
        Component: MonthAndYearInput,
        validator: (value) => futureMonthAndYearValidity(value)
    },
    'futureYear': {
        Component: YearInput,
        validator: (value) => futureYearValidity(value)
    },
    'investmentTable': {
        Component: InvestmentTableInput,
        validator: (value) => true
    },
    'assetTable': {
        Component: AssetTableInput,
        validator: (value) => true
    },
    'investmentTypePicker': {
        Component: InvestmentTypePicker,
        validator: (value) => true
    },
    'payrollDeductions': {
        Component: Payroll.Component,
        validator: (value) => true
    },
    'pension': {
        Component: PensionComponent,
        validator: (value) => true
    },
    'endableIncome': {
        Component: EndableIncomeComponent,
        validator: (value) => true
    },
    'shortTermDebts': {
        Component: ShortTermDebts,
        validator: (value) => true
    },
    'generalExpenses': {
        Component: GeneralExpenses,
        validator: (value) => true
    },
    'incomeTable': {
        Component: ConnectedIncome,
        validator: (value) => true
    },
    'rentalIncomeTable': {
        Component: ConnectedRentalIncome,
        validator: (value) => true
    },
    'futureExpenseTable': {
        Component: ConnectedFutureExpense,
        validator: (value) => true
    },
    'futureIncomeTable': {
        Component: ConnectedFutureIncome,
        validator: (value) => true
    },
    'wrongBoolean': {
        Component: PensionComponent,
        validator: (value) => true
    },
    'pastDate': {
        Component: DateInput,
        validator: pastValidator
    },
    'socialPicker': {
        Component: SocialSecurityChooser,
        validator: () => true
    }
}