import moment from "moment";
import SocialSecurityCalculator from "../SocialSecurityApi";
import { getMaritalStatus } from "../userDataTransformer";
import { reductionMainPercent, reductionSpousePercent, reductionSurvivorPercent } from "../CalculationData";
export const SOCIAL_SECURITY_MIN_AGE = 62;
export const SOCIAL_SECURITY_MAX_AGE = 70;

class ScenarioDataBuilder {
    constructor(answers) {
        this.answers = answers;
        this.maritalStatus = getMaritalStatus(answers);
        this.mainSSCalc = new SocialSecurityCalculator('main', answers);
        this.mainAge = this.mainSSCalc.getCurrentAgeYears();
        this.currentMoment = new moment().startOf('month');
        this.currentMomentNumber = this.currentMoment.format("X");
        this.mainTakingAmount = undefined;
        this.spouseTakingAmount = undefined;
        this.survivorsTakingAmount = undefined;

        if(this.answers.mainTaking)
            this.mainTakingAmount = this.answers.mainTakingAmount;

        if(this.answers.spouseTaking)
            this.spouseTakingAmount = this.answers.spouseTakingAmount;

        if(this.answers.survivorsTaking)
            this.survivorsTakingAmount = this.answers.survivorsTakingAmount;

        if (this.maritalStatus === 'married') {
            this.spouseSSCalc = new SocialSecurityCalculator('spouse', answers);
            this.spouseAge = this.spouseSSCalc.getCurrentAgeYears();
        }

        this.fraOptions = {
            fraAmount: answers['mainFRABenefit'],
            spousalFraAmount: answers['spouseFRABenefit'],
        }

        this.fraAmount = Number(this.fraOptions.fraAmount);

        this.spousalFraAmount = this.maritalStatus === 'married' ? Number(this.fraOptions.spousalFraAmount) : Number(answers.divorcedMarriageHighestBenefit);
        this.divorcedFraAmount = answers.mainFRABenefit;
        this.deceasedFRABenefit = answers.passedAwaySpouseBenefit;

        this.currentYear = this.currentMoment.year();
        this.currentMonth = this.currentMoment.month();
    }

    getSocialSecurityOptions(spouse) {
        if (this.maritalStatus === 'widowed') {
            return [Math.max(60, this.mainAge), Math.max(this.mainAge, 70)];
        } else if (this.maritalStatus === 'divorced' || this.maritalStatus === 'never-married') {
            return [Math.max(SOCIAL_SECURITY_MIN_AGE, this.mainAge), Math.max(this.mainAge, 70)];
        } else if (this.maritalStatus === 'married') {
            return [Math.max(SOCIAL_SECURITY_MIN_AGE, spouse ? this.spouseAge : this.mainAge), Math.max(this.mainAge, 70)];
        }
    }

    getAgeFromTakeDate(takeDate, ssCalc) {
        let takeMoment = new moment(takeDate, 'MM/DD/YYYY');
        let birthMoment = new moment(ssCalc.dob, 'MM/DD/YYYY');

        return takeMoment.diff(birthMoment, 'years', false)
    }

    __getTakeDate(pickAge, ssCalc) {
        if (!pickAge) {
            return this.currentMoment.format('MM/DD/YYYY');
        }

        let fraMoment = ssCalc.getFRAMoment();

        let bdayMoment = ssCalc.getBdayMoment();
        let pickYear = ssCalc.getYearBorn() + pickAge;

        let takeDate = bdayMoment.add(pickAge, 'years').format('MM/DD/YYYY');
        if (pickYear === ssCalc.getFRAYear()) {
            takeDate = fraMoment.startOf('month').format('MM/DD/YYYY');
        }

        let nowBeforePick = this.currentMoment.isBefore(moment(takeDate, 'MM/DD/YYYY'))
        if (!nowBeforePick) {
            return this.currentMoment.startOf('month').format('MM/DD/YYYY');
        }
        return takeDate;
    }

    formatMainChoiceForBackend(pickAge, amount, type, index, monthIndex, takenDate) {
        let takeDate = this.__getTakeDate(pickAge, this.mainSSCalc)

        return {
            takeAmountAnnual: amount,
            takeDate: takenDate ? moment(takenDate, "X").format("MM/DD/YYYY") : takeDate,
            monthIndex: monthIndex,
            index: index,
            type
        }
    }

    formatSpouseChoiceForBackend(pickAge, amount, type, index, monthIndex, takenDate) {
        if (!this.spouseSSCalc) {
            return {}
        }

        let takeDate = this.__getTakeDate(pickAge, this.spouseSSCalc)

        return {
            takeAmountAnnual: amount,
            takeDate: takenDate ? moment(takenDate, "X").format("MM/DD/YYYY") : takeDate,
            index: index,
            monthIndex: monthIndex,
            type
        }
    }

    updateWidowedChoice(previousAnswer, age, amount, type, index, monthIndex, takenDate) {
        let updatedAnswer = { ...previousAnswer };
        if (type === 'survivors') {
            updatedAnswer['survivorAmountAnnual'] = Number(amount * 12);
            updatedAnswer['survivorTakeDate'] = takenDate ? moment(takenDate, "X").format("MM/DD/YYYY") : this.__getTakeDate(age, this.mainSSCalc);
            updatedAnswer['monthIndex'] = monthIndex;
            updatedAnswer['index'] = index;
        } else {
            updatedAnswer['takeAmountAnnual'] = amount * 12;
            updatedAnswer['takeDate'] = takenDate ? moment(takenDate, "X").format("MM/DD/YYYY") : this.__getTakeDate(age, this.mainSSCalc);
            updatedAnswer['monthIndex'] = monthIndex;
            updatedAnswer['index'] = index;
        }

        //In case it's not set yet
        updatedAnswer['type'] = type;

        return updatedAnswer;
    }

    formatWidowedChoiceForBackend(mainPickAge, mainAmount, survivorPickAge, survivorPickAmount) {
        return {
            takeAmountAnnual: mainAmount,
            takeDate: this.__getTakeDate(mainPickAge, this.mainSSCalc),
            survivorAmountAnnual: survivorPickAmount,
            survivorTakeDate: this.__getTakeDate(survivorPickAge, this.mainSSCalc), //TODO fix this up
            type: 'widowed'
        }
    }


    //This returns an array with 2 values:
    // The first is the minimum age where one or both ages would be gequal to minAge, or the min of (age1, age2), whichever is larger
    // The second is the minimum age where both ages would be gequal to maxAge, or the max of (age1, age2), whichever is larger
    constrainAgeRange(minAge, maxAge, age1, age2) {
        let minCurrentAge = Math.min(age1, age2);
        let maxCurrentAge = Math.max(age1, age2);

        if (minCurrentAge < minAge) minCurrentAge = minAge;
        if (maxCurrentAge < maxAge) minCurrentAge = maxAge;

        return [minCurrentAge, maxCurrentAge];
    }

    getAgeRange(spouse) {
        let ssCalc = spouse ? this.spouseSSCalc : this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let topAge = this.currentYear - yearBorn;

        if (this.maritalStatus === 'widowed') {
            return [Math.max(60, topAge), Math.max(this.mainAge, 70)];
        } else if (this.maritalStatus === 'divorced' || this.maritalStatus === 'never-married') {
            return [Math.max(SOCIAL_SECURITY_MIN_AGE, topAge), Math.max(this.mainAge, 70)];
        } else if (this.maritalStatus === 'married') {
            let bottomAge = spouse ? this.spouseAge : this.mainAge;
            let maxAge = Math.max(this.spouseAge, this.mainAge);
            let adjustedBottomAge = bottomAge + Math.max(0, SOCIAL_SECURITY_MIN_AGE - maxAge);
            let difference = maxAge - (spouse ? this.mainAge : this.spouseAge);

            let endAge = SOCIAL_SECURITY_MAX_AGE + difference;
            endAge = Math.min(SOCIAL_SECURITY_MAX_AGE, endAge);

            let mainYearBornAfter = (this.mainSSCalc.getYearBorn() + 62) - this.currentYear;

            let spouseYearBornAfter = (this.spouseSSCalc.getYearBorn() + 62) - this.currentYear;

            let currentYearAge = topAge;

            topAge += Math.min(mainYearBornAfter, spouseYearBornAfter);

            let calculatedTopAge = topAge;

            topAge = Math.max(currentYearAge, calculatedTopAge);

            return [topAge, endAge];
        }
    }

    getMainSocialSecurityAgeOptions() {
        let ageRange = this.getSocialSecurityOptions();
        let outputYears = [];
        for (let i = ageRange[0]; i <= ageRange[1]; i++) {
            outputYears.push(i);
        }
        return outputYears;
    }

    getMainYearsForScenario() {
        let ageRange = this.getAgeRange();
        let outputYears = [];
        for (let i = ageRange[0]; i <= ageRange[1]; i++) {
            outputYears.push(i);
        }
        return outputYears;
    }

    getYearsForMainScenario() {
        let yearBorn = this.mainSSCalc.getYearBorn();
        let ageRange = this.getAgeRange();
        let outputYears = [];

        for (let i = ageRange[0]; i <= ageRange[1]; i++) {
            let calendarYear = Number(yearBorn) + Number(i);
            outputYears.push(calendarYear);
        }
        return outputYears;
    }

    getYearsForSpouseScenario() {
        let yearBorn = this.spouseSSCalc.getYearBorn();
        let ageRange = this.getAgeRange(true);
        let outputYears = [];

        for (let i = ageRange[0]; i <= ageRange[1]; i++) {
            let calendarYear = Number(yearBorn) + Number(i);
            outputYears.push(calendarYear);
        }
        return outputYears;
    }

    getSpouseYearsForScenario() {
        if (!this.spouseAge) {
            return []
        }

        let outputYears = [];
        if (this.maritalStatus === 'married') {
            let ageRange = this.getAgeRange(true);
            for (let i = ageRange[0]; i <= ageRange[1]; i++) {
                outputYears.push(i);
            }
        } else {
            for (let i = Math.max(SOCIAL_SECURITY_MIN_AGE, this.spouseAge); i <= 70; i++) {
                outputYears.push(i);
            }
        }

        return outputYears;
    }

    getDivorcedMainFRAData(spouse, userSpouse, userFRAAmount, takingAmount) {
        let ssCalc = this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let after_70_year = ssCalc.getAgeAfter70Year().format("X");

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let mainHeaders = this.getYearMainHeaders();

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            let year_and_month = { year: i, months: [] };

            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    let fraDate = k + "/01/" + i;

                    if(is_fra == true) {
                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, spouse);
                        let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, month, spouse, userFRA);

                        let benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        if(parseInt(month_index) == 48 && month_48_index == "") {
                            month_48_index = month_index;
                            month_48_amount = benefitAmount;
                        }

                        if(month_48_amount !== "") {
                            benefitAmount = month_48_amount;
                        }

                        if(takingAmount != undefined) {
                            benefitAmount = Number(takingAmount);
                        }

                        additionalYear.push({ date: moment(fraDate, "MM/DD/YYYY").format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        let reductionYearDate = moment(fraDate, "MM/DD/YYYY").format('X');
                        reductionYear.push(reductionYearDate);
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        reductionYear.map((element, index) => {
            let mainDobDeltaYears = moment(moment(element, "X"), 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY'), 'years');

            if(mainDobDeltaYears >= 62) {
                let correctFRAAmount = ssCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.994, reductionMainPercent);

                correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), spouse);
                let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

                let reductionBenefitAmount = Math.max(earningValue[0], 0);
                let bg_color = earningValue[1];

                if(bg_color != "")
                    reductionBenefitAmount = correctFRAAmount;

                if(takingAmount != undefined) {
                    reductionBenefitAmount = Number(takingAmount);
                }

                yearArray.push({ date: element, bg_color: bg_color, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
            }
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getDivorcedScenarioData(personalSelectionIndex, spousalSelectionIndex, personalMonthSelectionIndex, spousalMonthSelectionIndex) {
        let displayAgeRange = this.getAgeRange(false);
        let selected = (personalSelectionIndex !== undefined || spousalSelectionIndex !== undefined);
        let selectedMonth = (personalMonthSelectionIndex !== undefined || spousalMonthSelectionIndex !== undefined);
        let ssCalc = this.mainSSCalc;

        let output = [[{ type: 'header', text: selected ? 'Benefit' : 'Age Based' }],
        [{
            type: 'header',
            text: 'Spousal'
        }]];


        let yearBorn = this.mainSSCalc.getYearBorn();

        let userFRA = this.mainSSCalc.getAddedFRAMoment(ssCalc.dob);

        let  mainReductionGroupResults = this.getDivorcedMainFRAData(false, false, this.divorcedFraAmount, this.mainTakingAmount);
        let  spouseReductionGroupResults = this.getWidowAndDivorcedFRAData(true, this.spousalFraAmount);

        // how many days need to have elapsed before they are eligible for spousal divorced benefits
        const TWO_YEARS_DAYS = 365 * 2;
        // calculate number of days since their divorce, from today's date
        let daysSinceDivorceStart = this.currentMoment.diff(moment(this.answers.divorcedMarriageYear, 'MM/DD/YYYY'), 'days');

        let divorceeMainDobDeltaYears = moment(this.answers.divorceeDob, 'MM/DD/YYYY').diff(moment(this.answers.mainDob, 'MM/DD/YYYY'), 'years');
        let divorceeMainDobDeltaDays = moment(this.answers.divorceeDob, 'MM/DD/YYYY').subtract(divorceeMainDobDeltaYears, 'years').diff(moment(this.answers.mainDob), 'days');

        let j = 0;
        for (let i = displayAgeRange[0], divorceYearIndex = 0; i <= displayAgeRange[1]; i++, divorceYearIndex++) {
            let currentAge = i;
            let currentIndex = currentAge - displayAgeRange[0];

            let calendarYear = Number(yearBorn) + Number(currentAge);
            let mainBenefitYearData = mainReductionGroupResults[calendarYear];
            let spouseBenefitYearData = spouseReductionGroupResults[calendarYear];

            if ((spousalSelectionIndex !== undefined || personalSelectionIndex !== undefined) && (personalSelectionIndex > currentIndex || spousalSelectionIndex > currentIndex)) {
                output[0].push({ type: 'not-chosen' });
                output[1].push({ type: 'not-chosen' });
            } else {

                if (currentAge >= SOCIAL_SECURITY_MIN_AGE) {
                    if (personalSelectionIndex !== undefined) {
                        let ageYear = displayAgeRange[0] + personalSelectionIndex + Number(yearBorn);
                        output[0].push({ ...ssCalc.getBenefitInYear(displayAgeRange[0] + personalSelectionIndex, i, mainReductionGroupResults[ageYear], false, calendarYear, userFRA, true, personalMonthSelectionIndex), age: i });
                    } else {
                        output[0].push({ ...this.mainSSCalc.getBenefitInYear(i, i, mainBenefitYearData, false, calendarYear, userFRA, false), age: i });
                    }

                    if (spousalSelectionIndex !== undefined) {
                        let ageYear = displayAgeRange[0] + spousalSelectionIndex + Number(yearBorn);
                        output[1].push({ ...ssCalc.getBenefitInYear(displayAgeRange[0] + spousalSelectionIndex, i, spouseReductionGroupResults[ageYear], true, calendarYear, userFRA, true, spousalMonthSelectionIndex), age: i });
                    } else if ((daysSinceDivorceStart + (divorceYearIndex * 365)) <= TWO_YEARS_DAYS) {
                        output[1].push({ type: 'not-eligible-divorced' });
                    } else if (currentAge - divorceeMainDobDeltaYears < 62 || (currentAge - divorceeMainDobDeltaYears == 62 && divorceeMainDobDeltaDays != 0)) {
                        output[1].push({ type: 'not-eligible-divorcee-age' });
                    } else {
                        output[1].push({ ...this.mainSSCalc.getBenefitInYear(i, i, spouseBenefitYearData, true, calendarYear, userFRA, false), age: i });
                    }
                } else {
                    output[0].push({ type: 'not-chosen' });
                    output[1].push({ type: 'not-chosen' });
                }
            }
        }

        return output
    }

    getSpousalAmount(spouse, userType) {
        let ssCalc = spouse ? this.mainSSCalc : this.spouseSSCalc;

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let correctFRAAmount = ssCalc.adjustForGovernment(spouse ? this.fraOptions.fraAmount : this.spousalFraAmount, fra_year, false);

        return correctFRAAmount;
    }

    getMarriedMainFRAData(spouse, userSpouse, userFRAAmount, takingAmount) {
        let ssCalc = userSpouse ? this.spouseSSCalc : this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let after_70_year = ssCalc.getAgeAfter70Year().format("X");

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let user_dob = moment(ssCalc.dob, "MM/DD/YYYY").format("X");

        let mainHeaders = userSpouse ? this.getYearSpouseHeaders() : this.getYearMainHeaders();

        // let correctFRAAmount = userSpouse ? this.maritalStatus === 'divorced' ? this.divorcedFraAmount : this.spousalFraAmount : this.fraAmount;

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        console.log(userFRA);

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    let fraDate = k + "/01/" + i;

                    if(is_fra == true) {
                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        let benefitAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, spouse);

                        let earningValue = ssCalc.earningsTestAnnual(benefitAmount, month, spouse, userFRA);

                        benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        if(parseInt(month_index) == 48 && month_48_index == "") {
                            month_48_index = month_index;
                            month_48_amount = benefitAmount;
                        }

                        if(month_48_amount !== "") {
                            benefitAmount = month_48_amount;
                        }

                        if(takingAmount != undefined) {
                            benefitAmount = Number(takingAmount);
                        }

                        additionalYear.push({ date: moment(fraDate, "MM/DD/YYYY").format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        let reductionYearDate = moment(fraDate, "MM/DD/YYYY").format('X');
                        reductionYear.push(reductionYearDate);
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        reductionYear.map((element, index) => {
            let dobMonth = moment(ssCalc.dob, 'MM/DD/YYYY').format("MM");
            let dobYear = moment(ssCalc.dob, 'MM/DD/YYYY').format("DD");
            let mainDobDeltaYears = 0;

            if(Number(dobYear) == 31 && Number(dobMonth) == 12) {
                mainDobDeltaYears = moment(moment(element, "X"), 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY').subtract(1, "month"), 'years');    
            } else {
                mainDobDeltaYears = moment(moment(element, "X"), 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY'), 'years');
            }

            if(mainDobDeltaYears >= 62) {
                let correctFRAAmount = ssCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.994, reductionMainPercent);

                correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), spouse);
                let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

                let reductionBenefitAmount = Math.max(earningValue[0], 0);
                let bg_color = earningValue[1];

                if(bg_color != "") {
                    reductionBenefitAmount = correctFRAAmount;
                }

                if(takingAmount != undefined) {
                    reductionBenefitAmount = Number(takingAmount);
                }

                yearArray.push({ date: element, bg_color: bg_color, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
            }
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getMarriedSpouseFRAData(spouse, userSpouse, userFRAAmount) {
        let ssCalc = userSpouse ? this.spouseSSCalc : this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let after_70_year = ssCalc.getAgeAfter70Year().format("X");

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let user_dob = moment(ssCalc.dob, "MM/DD/YYYY").format("X");

        let mainHeaders = userSpouse ? this.getYearSpouseHeaders() : this.getYearMainHeaders();

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";

        userFRAAmount = this.getSpousalAmount(userSpouse, 'main', userFRAAmount);

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    let fraDate = k + "/01/" + i;

                    if(is_fra == true) {
                        monthIndex++;

                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, spouse);

                        let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, month, spouse, userFRA);

                        let benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        if(parseInt(month_index) == 48 && month_48_index == "") {
                            month_48_index = month_index;
                            month_48_amount = benefitAmount;
                        }

                        if(month_48_amount !== "") {
                            benefitAmount = month_48_amount;
                        }

                        additionalYear.push({ date: moment(fraDate, "MM/DD/YYYY").format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        let reductionYearDate = moment(fraDate, "MM/DD/YYYY").format('X');
                        reductionYear.push(reductionYearDate);
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        reductionYear.map((element, index) => {
            let dobMonth = moment(ssCalc.dob, 'MM/DD/YYYY').format("MM");
            let dobYear = moment(ssCalc.dob, 'MM/DD/YYYY').format("DD");
            let mainDobDeltaYears = 0;

            if(Number(dobYear) == 31 && Number(dobMonth) == 12) {
                mainDobDeltaYears = moment(moment(element, "X"), 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY').subtract(1, "month"), 'years');    
            } else {
                mainDobDeltaYears = moment(moment(element, "X"), 'MM/DD/YYYY').diff(moment(ssCalc.dob, 'MM/DD/YYYY'), 'years');
            }

            if(mainDobDeltaYears >= 62) {
                let correctFRAAmount = ssCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.994, reductionSpousePercent);
                correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), spouse);

                let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

                let reductionBenefitAmount = Math.max(earningValue[0], 0);
                let bg_color = earningValue[1];

                if(bg_color != "") {
                    reductionBenefitAmount = correctFRAAmount;
                }

                yearArray.push({ date: element, bg_color: bg_color, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
            }
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getMarriedMainScenarioData (personalSelectionIndex, spousalSelectionIndex, otherSelectionIndex, otherSelectionType, personalMonthSelectionIndex, spousalMonthSelectionIndex, otherMonthSelectionIndex, otherMonthSelectionType, spouse) {
        let showETest = (personalSelectionIndex !== undefined || spousalSelectionIndex !== undefined);

        let allSelected = (personalSelectionIndex || spousalSelectionIndex) && otherSelectionIndex;
        let displayAgeRange = this.getAgeRange(spouse);
        let age = spouse ? this.spouseAge : this.mainAge;
        let bottomAge = Math.max(SOCIAL_SECURITY_MIN_AGE, age);
        let topAge = Math.max(SOCIAL_SECURITY_MAX_AGE, age);
        let ageDifference = spouse ? this.spouseAge - this.mainAge : this.mainAge - this.spouseAge;

        let ssCalc = spouse ? this.spouseSSCalc : this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let correctFRAAmount = spouse ? this.maritalStatus === 'divorced' ? this.divorcedFraAmount : this.spousalFraAmount : this.fraAmount;

        let output = [[{ type: 'header', text: allSelected ? (spouse ? 'Spousal' : 'Monthly') : 'Age Based' }],
        [{
            type: 'header',
            text: 'Spousal',
            subtext: ""
        }]];

        let  mainReductionGroupResults = this.getMarriedMainFRAData(false, spouse, correctFRAAmount, spouse ? this.spouseTakingAmount : this.mainTakingAmount);
        let  spousereductionGroupResults = this.getMarriedSpouseFRAData(true, spouse, spouse ? this.fraAmount : this.spousalFraAmount);

        let j = 0;
        for (let i = displayAgeRange[0]; i <= displayAgeRange[1]; i++) {
            let currentSpouseAge = i - ageDifference;

            let currentAge = i;
            let currentIndex = currentAge - displayAgeRange[0];

            if (this.maritalStatus === 'divorced') {
                currentSpouseAge = currentAge
            }

            let calendarYear = Number(yearBorn) + Number(i);

            let mainBenefitYearData = mainReductionGroupResults[calendarYear];
            let spouseBenefitYearData = spousereductionGroupResults[calendarYear];

            //If we're outside of bounds
            if ((currentAge > topAge && (personalSelectionIndex === undefined && spousalSelectionIndex === undefined)) || currentAge < bottomAge) {
                output[0].push({ type: 'not-chosen' });
                output[1].push({ type: 'not-chosen' });
                continue;
            }

            if (spousalSelectionIndex !== undefined) {
                output[0].push({ type: 'not-chosen' });
            } else if (personalSelectionIndex !== undefined && currentIndex < personalSelectionIndex) {
                output[0].push({ type: 'not-chosen' });
            } else if (otherSelectionIndex !== undefined &&
                (currentIndex > otherSelectionIndex &&
                    (!(personalSelectionIndex === undefined || spousalSelectionIndex === undefined) || (otherSelectionType === 'spousal' && personalSelectionIndex === undefined)))) {
                output[0].push({ type: 'not-chosen' });
            } else {
                if (personalSelectionIndex !== undefined) {
                    let ageYear = displayAgeRange[0] + personalSelectionIndex + Number(yearBorn);
                    output[0].push({ ...ssCalc.getBenefitInYear(displayAgeRange[0] + personalSelectionIndex, i, mainReductionGroupResults[ageYear], false, calendarYear, userFRA, showETest, personalMonthSelectionIndex), age: i });

                } else {
                    output[0].push({ ...ssCalc.getBenefitInYear(i, i, mainBenefitYearData, false, calendarYear, userFRA, showETest), age: i });
                }
            }

            if (currentSpouseAge >= SOCIAL_SECURITY_MIN_AGE && otherSelectionType !== 'spousal') {
                if (personalSelectionIndex !== undefined) {
                    output[1].push({ type: 'not-chosen' })
                } else if (spousalSelectionIndex !== undefined && currentIndex < spousalSelectionIndex) {
                    output[1].push({ type: 'not-chosen' });
                } else if (otherSelectionIndex !== undefined &&
                    (currentIndex < otherSelectionIndex)) {
                    output[1].push({ type: 'not-chosen' });
                } else {
                    if (spousalSelectionIndex !== undefined) {
                        let ageYear = displayAgeRange[0] + spousalSelectionIndex + Number(yearBorn);

                        output[1].push({ ...ssCalc.getBenefitInYear(displayAgeRange[0] + spousalSelectionIndex, i, spousereductionGroupResults[ageYear], true, calendarYear, userFRA, showETest, spousalMonthSelectionIndex), age: i });
                    } else {
                        output[1].push({ ...ssCalc.getBenefitInYear(i, i, spouseBenefitYearData, true, calendarYear, userFRA, showETest), age: i });
                    }
                }
            } else {
                output[1].push({ type: 'not-chosen' });
            }

            j++;
        }

        return output;
    }

    getTotalHeaders() {
        let ageRange = this.getAgeRange();

        let outputYears = ["Total Monthly Benefit: "];
        for (let i = ageRange[0]; i <= ageRange[1]; i++) {
            outputYears.push(i);
        }
        return outputYears;
    }

    getMarriedSpouseScenarioData(personalSelectionIndex, spousalSelectionIndex, otherSelection, otherSelectionType, personalMonthSelectionIndex, spousalMonthSelectionIndex, otherMonthSelectionIndex, otherMonthSelectionType) {
        return this.getMarriedMainScenarioData(personalSelectionIndex, spousalSelectionIndex, otherSelection, otherSelectionType, personalMonthSelectionIndex, spousalMonthSelectionIndex, otherMonthSelectionIndex, otherMonthSelectionType, true);
    }

    getSingleScenarioData(choiceIndex, choiceMonthIndex) {
        let bottomAge = Math.max(SOCIAL_SECURITY_MIN_AGE, this.mainAge);
        let topAge = Math.max(SOCIAL_SECURITY_MAX_AGE, this.mainAge);

        let displayAgeRange = this.getAgeRange(false);

        let yearBorn = this.mainSSCalc.getYearBorn();

        let userFRA = this.mainSSCalc.getAddedFRAMoment(this.mainSSCalc.dob);

        let output = [{ type: 'header', text: 'Age Based' }];

        let  mainReductionGroupResults = this.getMarriedMainFRAData(false, false, this.fraAmount, this.mainTakingAmount);

        let j = 0;
        for (let i = displayAgeRange[0]; i <= displayAgeRange[1]; i++) {
            let calendarYear = Number(yearBorn) + Number(i);
            let mainBenefitYearData = mainReductionGroupResults[calendarYear];

            if (choiceIndex && i - bottomAge < choiceIndex) {
                output.push({ type: 'not-chosen' });
            } else {
                if (i >= SOCIAL_SECURITY_MIN_AGE) {
                    if (choiceIndex !== undefined) {
                        let ageYear = displayAgeRange[0] + choiceIndex + Number(yearBorn);

                        output.push({ ...this.mainSSCalc.getBenefitInYear(displayAgeRange[0] + choiceIndex, i, mainReductionGroupResults[ageYear], false, calendarYear, userFRA, false, choiceMonthIndex), age: i });

                    } else {
                        output.push({ ...this.mainSSCalc.getBenefitInYear(i, i, mainBenefitYearData, false, calendarYear, userFRA, false), age: i });
                    }
                } else {
                    output.push({ type: 'not-chosen' });
                }
            }
            j++;
        }

        return output;
    }

    getWidowAndDivorcedFRAData(spouse, userFRAAmount) {
        let ssCalc = this.mainSSCalc;
        let yearBorn = ssCalc.getYearBornForDob(this.answers.mainDob);
        let after_70_year = ssCalc.getAgeAfter70YearForDecease(this.answers.mainDob).format("X");

        let userFRA = ssCalc.getAddedFRAMoment(this.answers.mainDob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let mainHeaders = this.getYearMainHeaders();

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            let year_and_month = { year: i, months: [] };

            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    let fraDate = k + "/01/" + i;

                    if(is_fra == true) {
                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        let benefitAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, spouse);
                        let earningValue = ssCalc.earningsTestAnnual(benefitAmount, month, spouse, userFRA);

                        benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        if(parseInt(month_index) == 48 && month_48_index == "") {
                            month_48_index = month_index;
                            month_48_amount = benefitAmount;
                        }

                        if(month_48_amount !== "") {
                            benefitAmount = month_48_amount;
                        }

                        additionalYear.push({ date: moment(fraDate).format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        reductionYear.push(moment(fraDate, "MM/DD/YYYY").format('X'));
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        month_48_index = "";
        month_48_amount = "";

        reductionYear.map((element, index) => {
            let correctFRAAmount = ssCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.339, reductionSpousePercent);

            correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), spouse);
            let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

            let reductionBenefitAmount = Math.max(earningValue[0], 0);
            let bg_color = earningValue[1];

            if(bg_color != "")
                reductionBenefitAmount = correctFRAAmount;

            yearArray.push({ date: element, bg_color: bg_color, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getMainWidowFRAData(spouse, userFRAAmount, takingAmount) {
        let retireDate = moment(this.answers.mainRetireDate, "MM/DD/YYYY").format("X");

        let ssCalc = this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let after_70_year = ssCalc.getAgeAfter70Year().format("X");

        let userDob = this.answers.mainDob;

        let userFRA = ssCalc.getAddedFRAMoment(userDob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let age_seventy = moment(userDob).add(70, "years");

        let mainHeaders = this.getYearMainHeaders();

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            let year_and_month = { year: i, months: [] };

            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                let fraDate = k + "/01/" + i;

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    if(is_fra == true) {
                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, spouse);
                        let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, month, spouse, userFRA);

                        let benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        if(parseInt(month_index) == 48 && month_48_index == "") {
                            month_48_index = month_index;
                            month_48_amount = benefitAmount;
                        }

                        if(month_48_amount !== "") {
                            benefitAmount = month_48_amount;
                        }

                        if(takingAmount != undefined) {
                            benefitAmount =  takingAmount;
                        }

                        additionalYear.push({ date: moment(fraDate).format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        reductionYear.push(moment(fraDate, "MM/DD/YYYY").format('X'));
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        month_48_index = "";
        month_48_amount = "";

        reductionYear.map((element, index) => {
            // if(element > retireDate) {
                let correctFRAAmount = this.mainSSCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.994, reductionMainPercent);

                correctFRAAmount = this.mainSSCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), spouse);
                let earningValue = this.mainSSCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

                let reductionBenefitAmount = Math.max(earningValue[0], 0);
                let bg_color = earningValue[1];

                if(bg_color != "")
                    reductionBenefitAmount = correctFRAAmount;

                if(takingAmount != undefined) {
                    reductionBenefitAmount =  takingAmount;
                }

                yearArray.push({ date: element, bg_color: bg_color, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
            // }
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getSurvivorAmount(spouse, userType) {
        let ssCalc = this.mainSSCalc;

        let userFRA = this.mainSSCalc.getAddedFRAMoment(this.mainSSCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let correctFRAAmount = this.mainSSCalc.adjustForGovernment(this.fraAmount, fra_year, false);

        return correctFRAAmount;
    }


    getWidowedMainFRAData(spouse, userSpouse, userFRAAmount, takingAmount) {
        let ssCalc = userSpouse ? this.spouseSSCalc : this.mainSSCalc;
        let yearBorn = ssCalc.getYearBorn();
        let after_70_year = ssCalc.getAgeAfter70Year().format("X");

        let userFRA = ssCalc.getAddedFRAMoment(ssCalc.dob);

        let fra_year = userFRA.format("YYYY");
        let fra_month = userFRA.format("MM");

        let mainHeaders = userSpouse ? this.getYearSpouseHeaders() : this.getYearMainHeaders();

        // let correctFRAAmount = userSpouse ? this.maritalStatus === 'divorced' ? this.divorcedFraAmount : this.spousalFraAmount : this.fraAmount;

        let additionalYear = [];
        let reductionYear = [];

        let fraTimestamp = userFRA.format("X");

        let monthIndex = 0;
        let fraMonthIndex = "";

        let yearArray = []

        let month_index = 0;

        let month_48_index = "";
        let month_48_amount = "";
        let benefit_after = "";

        for (let i = mainHeaders[0]; i <= mainHeaders[mainHeaders.length - 1]; i++) {
            let year_and_month = { year: i, months: [] };

            for (let k = 1; k <= 12; k++) {
                k = k < 10 ? "0" + k : k;

                let month = moment(k + "/01/" + i + " 12:59:59", "MM/DD/YYYY HH:mm:ss").format("X");

                if(month >= this.currentMomentNumber && month <= after_70_year) {
                    let is_fra = Number(fraTimestamp) < month ? true : false;

                    if(fraMonthIndex == "" && Number(fra_year) == i && Number(fra_month) == Number(k)) {
                        fraMonthIndex = monthIndex;
                    }

                    let fraDate = k + "/01/" + i;

                    if(is_fra == true) {
                        let correctFRAAmount = ssCalc.getFRAAddition(is_fra, userFRAAmount, spouse, 0.00667, monthIndex, fraMonthIndex);

                        let benefitAmount = ssCalc.adjustForGovernment(correctFRAAmount, i, true);
                        let earningValue = ssCalc.earningsTestAnnual(benefitAmount, month, spouse, userFRA);

                        benefitAmount = Number(earningValue[0], 0);
                        let bg_color = earningValue[1];

                        // if(fraMonthIndex != "" && month_48_amount == "") {
                        //     month_48_amount = benefitAmount;
                        // }

                        // if(month_48_amount !== "") {
                        //     benefitAmount = month_48_amount;
                        // }

                        if(takingAmount != undefined) {
                            benefitAmount = Number(takingAmount);
                        }

                        additionalYear.push({ date: moment(fraDate, "MM/DD/YYYY").format("X"), year: Number(moment(fraDate).format("YYYY")), month: moment(fraDate, "MM/DD/YYYY").format("MMMM"), fra: is_fra, amount: benefitAmount, index: monthIndex, bg_color: bg_color });
                    } else {
                        reductionYear.push(moment(fraDate, "MM/DD/YYYY").format('X'));
                    }

                    monthIndex++;
                }
            }
        }

        reductionYear = reductionYear.sort((a, b) => {
            if(a > b)
                return -1;

            if(a < b)
                return 1;
        });

        reductionYear.map((element, index) => {
            let correctFRAAmount = ssCalc.getFRAReduction(userFRAAmount, spouse, index, 0, 0.997, reductionSurvivorPercent);

            correctFRAAmount = ssCalc.adjustForGovernment(correctFRAAmount,  Number(moment(element, "X").format("YYYY")), true);
            let earningValue = ssCalc.earningsTestAnnual(correctFRAAmount, element, spouse, userFRA);

            let reductionBenefitAmount = Math.max(earningValue[0], 0);
            let bg_color = earningValue[1];

            if(bg_color != "")
                reductionBenefitAmount = correctFRAAmount;

            if(takingAmount != undefined) {
                reductionBenefitAmount = Number(takingAmount);
            }

            yearArray.push({ date: element, year: Number(moment(element, "X").format("YYYY")), month: moment(element, "X").format("MMMM"), fra: false, amount: reductionBenefitAmount, index: index, bg_color: bg_color });
        });


        yearArray = yearArray.sort((a, b) => {
            return a.date >= b.date ? 1 : -1; 
        });

        additionalYear.map((element) => {
            yearArray.push(element);
        });

        let reductionGroupResults = yearArray.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null));

        return reductionGroupResults;
    }

    getWidowedScenarioData(selectPersonal, selectSurvivors, selectMonthPersonal, selectMonthSurvivors) {
        if (this.mainSSCalc.takingAmount) {
            selectPersonal = undefined;
        }

        let yearBorn = this.mainSSCalc.getYearBorn();
        let userFRA = this.mainSSCalc.getAddedFRAMoment(this.mainSSCalc.dob);

        let retireDate = moment(this.answers.mainRetireDate, "MM/DD/YYYY").year();

        let passedAwaySpouseFRA = this.mainSSCalc.getAddedFRAMoment(this.answers.deceasedSpouseDob);

        let  mainReductionGroupResults = this.getWidowedMainFRAData(false, false, this.deceasedFRABenefit, this.survivorsTakingAmount);
        let  widowReductionGroupResults = this.getMainWidowFRAData(false, this.fraAmount, this.mainTakingAmount);

        let output = [[{ type: 'header', text: 'Survivors' }], [{ type: 'header', text: 'Age Based' }]];

        let deceasedSpouseMainDobDeltaYears = moment(this.answers.deceasedSpouseDob, 'MM/DD/YYYY').diff(moment(this.answers.mainDob, 'MM/DD/YYYY'), 'years');
        let deceasedSpouseMainDobDeltaDays = moment(this.answers.deceasedSpouseDob, 'MM/DD/YYYY').subtract(deceasedSpouseMainDobDeltaYears, 'years').diff(moment(this.answers.mainDob), 'days');
        // let deceasedSpouseMainDobDeltaDays = moment(this.answers.deceasedSpouseDob, 'MM/DD/YYYY').subtract(deceasedSpouseMainDobDeltaYears, 'years').diff(moment(this.answers.mainDob), 'days');

        let displayAgeRange = this.getAgeRange();
        for (let currentAge = displayAgeRange[0]; currentAge <= displayAgeRange[1]; currentAge++) {

            let calendarYear = Number(yearBorn) + Number(currentAge);

            let mainBenefitYearData = mainReductionGroupResults[calendarYear];
            let widowBenefitYearData = widowReductionGroupResults[calendarYear];

            let currentIndex = currentAge - displayAgeRange[0];
            if (currentAge - displayAgeRange[0] < selectSurvivors && selectSurvivors !== undefined) {
                output[0].push({ type: 'not-chosen' });
            } else if (currentAge < 60) {
                output[0].push({ type: 'not-chosen' });
            } else {
                if(selectSurvivors !== undefined) {
                    let ageYear = displayAgeRange[0] + selectSurvivors + Number(yearBorn);
                    output[0].push({ ...this.mainSSCalc.getBenefitInYear(displayAgeRange[0] + selectSurvivors, currentAge, mainReductionGroupResults[ageYear], false, calendarYear, userFRA, false, selectMonthSurvivors), age: currentAge });

                } else {
                    output[0].push({ ...this.mainSSCalc.getBenefitInYear(currentAge, currentAge, mainBenefitYearData, false, calendarYear, userFRA, false), age: currentAge });
                }
            }

            if (currentAge - displayAgeRange[0] < selectPersonal && selectPersonal !== undefined) {
                output[1].push({ type: 'not-chosen' })
            } else if (currentAge < 62) {
                output[1].push({ type: 'not-chosen' });
            } else {
                if(selectPersonal !== undefined) {
                    let ageYear = displayAgeRange[0] + selectPersonal + Number(yearBorn);
                    output[1].push({ ...this.mainSSCalc.getBenefitInYear(displayAgeRange[0] + selectPersonal, currentAge, widowReductionGroupResults[ageYear], false, calendarYear, userFRA, false, selectMonthPersonal), age: currentAge });
                } else {
                    output[1].push({ ...this.mainSSCalc.getBenefitInYear(currentAge, currentAge, widowBenefitYearData, false, calendarYear, userFRA, false), age: currentAge });
                }
            }
        }

        return output;
    }

    getMainHeaders() {
        let rawYears = this.getMainYearsForScenario();
        let fraYear = this.mainSSCalc.getUserFRAYear();
        let yearBorn = this.mainSSCalc.getYearBorn();

        rawYears.forEach((age, index) => {
            let calendarYear = Number(yearBorn) + Number(age);
            // if(Number(calendarYear) == Number(fraYear)) {
            //     rawYears[index] = "FRA"
            // }
        });

        return [this.answers.mainUserName + "'s Age: ", ...rawYears];
    }

    getYearMainHeaders() {
        let rawYears = this.getYearsForMainScenario();
        return [...rawYears];
    }

    getYearSpouseHeaders() {
        let rawYears = this.getYearsForSpouseScenario();
        return [...rawYears];
    }

    getSpouseHeaders() {
        let rawYears = this.getSpouseYearsForScenario();
        let fraYear = this.spouseSSCalc.getUserFRAYear();
        let yearBorn = this.spouseSSCalc.getYearBorn();

        rawYears.forEach((age, index) => {
            let calendarYear = Number(yearBorn) + Number(age);

            // if(Number(calendarYear) == Number(fraYear)) {
            //     rawYears[index] = "FRA"
            // }
        });

        return [this.answers.spouseUserName + "'s Age: ", ...rawYears];
    }

    buildMainProjections(allYears) {
        if (allYears) {
            return this.__buildProjectionsAllYears(this.mainSSCalc)
        }
        return this.__buildProjections(this.mainSSCalc);
    }

    buildSpouseProjections() {
        return this.__buildProjections(this.spouseSSCalc);
    }

    __buildProjectionsAllYears(calculator) {
        let outputObject = {};
        let years = this.getMainSocialSecurityAgeOptions();

        let age = calculator.getCurrentAgeYears();
        years.forEach((age, index) => {
            let tempArr = new Array(index).fill({ amount: 0 });
            outputObject[age] = [...tempArr, ...calculator.getCumulativeTaking(calculator.getYearBorn() + age, calculator.getBenefitForAge(age, false, true, index).amountYearly)]
        });

        let transformedOutputData = [];
        for (let i = 0; i < 40; i++) {
            let currentElem = { age: age + i, year: calculator.getYearBorn() + age + i };
            years.forEach((age, index) => {
                currentElem[age] = outputObject[age][i].amount
            })
            if (currentElem.age < 75 || currentElem.age > 85) {
                continue
            }
            transformedOutputData.push(currentElem)
        }

        return transformedOutputData
    }

    __buildThreeAgeOptions(currentAge, fraYears) {
        if (currentAge <= 62) {
            return [62, fraYears, 70];
        } else if (currentAge > 62 && currentAge < fraYears) { //Check against months if Myles decides he wants it displayed with months
            return [currentAge, fraYears, 70];
        } else if (currentAge >= fraYears && currentAge < 70) {
            return [currentAge, 70];
        } else {
            return [currentAge];
        }
    }

    __buildProjections(ssCalculator) {
        let outputObject = {};

        let fra = ssCalculator.getFRAAge(ssCalculator.getYearBorn());
        let currentAge = ssCalculator.getCurrentAgeYears();

        let options = this.__buildThreeAgeOptions(currentAge, fra.years);
        let translateOptions = ['youngOption', 'mediumOption', 'oldOption'];
        let yearBorn = ssCalculator.getYearBorn();

        options.forEach((elem, index) => {
            outputObject[translateOptions[(translateOptions.length - options.length) + index]] = {
                age: options[index],
                values: ssCalculator.getCumulativeTaking(yearBorn + options[index], ssCalculator.getBenefitForAge(options[index], false, true, index).amountYearly)
            }
        });

        const makeNumber = (age) => {
            let numberData = {
                age: age,
            }

            Object.keys(outputObject).forEach(optionKey => {
                numberData[optionKey] = outputObject[optionKey]['values'][age - outputObject[optionKey]['age']].amount
            })
            return numberData;
        }

        let bottomAge = Math.max(70, Math.floor(currentAge / 5) * 5 + (currentAge % 5 == 0 ? 0 : 5));

        let outputArray = [];

        for (let i = bottomAge; i <= 100; i += 5) {
            outputArray.push(makeNumber(i));
        }

        return {
            raw: outputObject,
            graphData: outputArray,
        };
    }

}

export default ScenarioDataBuilder;