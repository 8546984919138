export const SOCIAL_CHOICE_LINK = '/app/social-security/choice';
export const SOCIAL_BENEFITS_LINK = '/app/social-security/expected-benefits';
export const PROFILE_LINK = '/app/profile';
export const CASHFLOW_LINK = '/app/cash-flow';
export const ORDER_OF_WITHDRAWAL_LINK = CASHFLOW_LINK + '/order-of-withdrawal';
export const LIVING_LEDGER_LINK = CASHFLOW_LINK + '/livingledger';
export const PORTFOLIO_LINK = '/app/portfolio';
export const ACCOUNT_LINK = '/app/account';
export const INCOME_LINK = '/app/income';
export const HOUSING_LINK = '/app/expenses/housing'
export const INSURANCE_LINK = '/app/expenses/insurance';
export const FUTURE_EXPENSE_LINK = '/app/expenses/future-expenses';

export const OTHER_INCOME_LINK = '/app/income/other';
export const FUTURE_INCOME_LINK = '/app/income/future-income';
export const EMPLOYMENT_INCOME_LINK = '/app/income/employment';

export const ADMIN_PAGE_LINK = '/admin';
export const PURCHASE_LINK = '/purchase';

export function hasLinkUnlocked(location, params, flowStatus) {
    let splitPath = location.pathname.split('/');
    let subTab = splitPath.length > 3 ? splitPath[3] : undefined;
    let flow = !subTab ? params.tab : subTab;
    let flowHasBeenVisited = flowStatus[flow];
    if (flow !== 'account' && !flowHasBeenVisited) return [subTab, false];
    return [subTab, true]
}

