import { Input, SIZE } from 'baseui/input';
import React from 'react';
import DateInput from './DateInput';

const EndableIncome = ({ id, updateInput, value, displayText }) => {
  let amount = value.incomeAmount ? value.incomeAmount : '';
  let endDate = value.endDate ? value.endDate : '';

  const updateAmount = (amount) => {
    let newValue = { ...value };
    newValue.incomeAmount = amount;
    updateInput(newValue)
  }

  const updateEndDate = (end) => {
    let newValue = { ...value };
    newValue.endDate = end;
    updateInput(newValue)
  }

  return (
    <>
      <Input value={amount || '0'} onChange={e => updateAmount(e.target.value)} name={id} size={SIZE.large} type={'number'} startEnhancer='$' endEnhancer=".00" />
      {amount !== '' && Number(amount) !== 0 ?
        <div>{displayText ? displayText : 'When will this end: '} <DateInput value={endDate} updateInput={value => updateEndDate(value)} /> </div> : <div />
      }
    </>
  );
}

export default EndableIncome;
