import { SET_ALL_ANSWERS, SET_ALL_ANSWERS_AND_FLOWS, SET_ALL_DATA, SET_ANSWER, SET_FLOW_STATUS } from '../actions/userData';

function getDefaultState() {
    return {
        answers: {},
        flows: {}
    };
}

const userData = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SET_ANSWER:
            let newAnswers = { ...state.answers };
            newAnswers[action.answerKey] = action.answerValue;
            return {
                ...state,
                answers: newAnswers
            };
        case SET_ALL_ANSWERS:
            return {
                ...state,
                answers: action.newAnswers
            };
        case SET_ALL_ANSWERS_AND_FLOWS:
            return {
                ...state,
                answers: action.newAnswers,
                flows: action.flows,
            };
        case SET_FLOW_STATUS:
            return {
                ...state,
                flows: {
                    ...state.flows,
                    [action.name]: action.status
                }
            };
        case SET_ALL_DATA:
            return {
                ...action.data
            }
        default:
            return state;
    }
}

export default userData;