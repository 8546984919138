import {
  Container,
  FormControl, MenuItem, Select, TextField
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Label2, Label3, Label4 } from "baseui/typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Firebase from "../firebase/Firebase";

const SubscriptionLayout = ({ onCancel }) => {
  const [value, setValue] = useState({
    ableToCreateLL: "",
    reasonToNotCompleteLL: "",
    reachedSupportSystem: "",
    illustrationInsightful: "",
    profileQuestionsFeedback: "",
    reasonToCancleSubs: "",
  });
  const [errors, setErrors] = useState(false)

  const history = useHistory();

  const extensionApi = async (extend, value) => {
    const unsubscribe = await Firebase.fetch("/unsubscribe", {
      method: "POST",
      body: { extend, value },
    });
    if (!unsubscribe.success) {
      alert(unsubscribe.message);
    } else {
      alert(unsubscribe.message);
    }
    // return unsubscribe
  };

  const validate = () => {
    let valid = false;
    let validComplete = false
    if ((value.ableToCreateLL === "no" && value.reasonToNotCompleteLL) ||
      (value.ableToCreateLL === "yes" && value.illustrationInsightful && value.profileQuestionsFeedback)) {
      validComplete = true
    }

    valid = validComplete && !!value.reachedSupportSystem && !!value.reasonToCancleSubs;

    return valid
  }

  return (
    <Container
      style={{
        margin: "20px",
      }}
    >
      <MainContainer>
        <ImageContainer>
          <CloseImage src="/close.png" onClick={onCancel} />
        </ImageContainer>
        <LogoContainer>
          <LogoImage src="/LivingLedgerLogo.png" />
        </LogoContainer>
        <HeadingContainer>
          <Label3 style={{ marginTop: "5px" }}>
            We have received your request to cancel your Living Ledger
            subscription.
          </Label3>
        </HeadingContainer>
        <SubHeadingContainer>
          <Label4 style={{ marginTop: "13px" }}>
            We hope your experience was positive. Know that when we cancel your
            subscription, we will purge your profile completely from our system.
            Before we cancel your subscription, we would like to ask you a few
            questions about your experience:
          </Label4>
        </SubHeadingContainer>
        <FormContainer>
          <FormLeftContainer>
            {errors ? <HeadingContainer style={{ backgroundColor: 'rgba(140, 0, 0, .4)', width: '100%', margin: '15px 0px', padding: '5px 0px' }}>
              <Label3 style={{ marginTop: "5px" }}>
                Please fill out the fields.
              </Label3>
            </HeadingContainer> : undefined}
            <Form value={value} setValue={setValue} />
          </FormLeftContainer>
          <FormRightContainer>
            <DevicesImage src="/devices.png" />
          </FormRightContainer>
        </FormContainer>
        <FooterMainContainer>
          <Footer>
            <FooterQuestion>
              <Label2>
                Would you like a{" "}
                <span
                  style={{
                    color: "#2E671B",
                    fontWeight: 800,
                  }}
                >
                  FREE 30-day
                </span>{" "}
                extension to reconsider your subscription?
              </Label2>
            </FooterQuestion>
            <FooterButtonContainer>
              <CustomButton variant="contained" color="primary">
                <ButtonText
                  onClick={() => {
                    extensionApi("Yes", value).then(e => history.push("/"));
                  }}
                >
                  I accept your FREE 30-day extension
                </ButtonText>
              </CustomButton>
              <CustomButton variant="contained" color="primary">
                <ButtonText
                  onClick={() => {
                    let v = validate();
                    if (v) {
                      setErrors(undefined)
                      extensionApi("No", value).then(e => history.push("/purchase"));
                    } else {
                      setErrors(true)
                    }
                  }}
                >
                  <span
                    style={{
                      color: "#FFC700",
                    }}
                  >
                    CANCEL
                  </span>{" "}
                  my subscription and purge my profile
                </ButtonText>
              </CustomButton>
            </FooterButtonContainer>
          </Footer>
        </FooterMainContainer>
      </MainContainer>
    </Container>
  );
};

const Form = ({ value, setValue }) => {
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  console.log({ value });
  return (
    <>
      <FormControl fullWidth>
        <Label4>Were you able to create your Living Ledger?</Label4>
        <Select
          style={{
            background: "white",
            padding: "5px 15px",
            textAlign: "left",
            marginTop: "5px",
          }}
          name="ableToCreateLL"
          value={value.ableToCreateLL}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="">
            <em>Select...</em>
          </MenuItem>
          <MenuItem value={"no"}>NO</MenuItem>
          <MenuItem value={"yes"}>YES</MenuItem>
        </Select>
      </FormControl>
      {value.ableToCreateLL === "no" && (
        <>
          <FormControl
            fullWidth
            style={{
              marginTop: "15px",
            }}
          >
            <Label4>Why didn’t you complete your Living Ledger?</Label4>
            <TextField
              id="standard-multiline-static"
              label=""
              placeholder="Your answer"
              multiline
              rows={6}
              style={{
                background: "white",
                marginTop: "5px",
                padding: "10px",
              }}
              name="reasonToNotCompleteLL"
              value={value.reasonToNotCompleteLL}
              onChange={handleChange}
            />
          </FormControl>
        </>
      )}
      {value.ableToCreateLL === "yes" && (
        <>
          <FormControl
            fullWidth
            style={{
              marginTop: "15px",
            }}
          >
            <Label4>Did you find the illustration insightful?</Label4>
            <TextField
              id=""
              label=""
              placeholder="Type your answer here..."
              style={{
                background: "white",
                marginTop: "5px",
                padding: "10px",
              }}
              name="illustrationInsightful"
              value={value.illustrationInsightful}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl
            fullWidth
            style={{
              marginTop: "15px",
            }}
          >
            <Label4>
              Were the profile questions easy to understand and complete?
            </Label4>
            <TextField
              id="standard-multiline-static"
              label=""
              placeholder="Type your answer here..."
              style={{
                background: "white",
                marginTop: "5px",
                padding: "10px",
              }}
              name="profileQuestionsFeedback"
              value={value.profileQuestionsFeedback}
              onChange={handleChange}
            />
          </FormControl>
        </>
      )}
      <FormControl
        fullWidth
        style={{
          marginTop: "15px",
        }}
      >
        <Label4>Did you reach out to our Support Line for assistance?</Label4>
        <Select
          style={{
            background: "white",
            padding: "5px 15px",
            textAlign: "left",
            marginTop: "5px",
          }}
          name="reachedSupportSystem"
          value={value.reachedSupportSystem}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="">
            <em>Select...</em>
          </MenuItem>
          <MenuItem value={"no"}>NO</MenuItem>
          <MenuItem value={"yes"}>YES</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        style={{
          marginTop: "15px",
        }}
      >
        <Label4>Why are you canceling your subscription?</Label4>
        <TextField
          id="standard-multiline-static"
          label=""
          placeholder="Your answer"
          multiline
          rows={6}
          style={{
            background: "white",
            marginTop: "5px",
            padding: "10px",
          }}
          name="reasonToCancleSubs"
          value={value.reasonToCancleSubs}
          onChange={handleChange}
        />
      </FormControl>
    </>
  );
};

const CustomButton = styled("button")({
  background: "#6FA05E",
  height: "48px",
  width: "256px",
  padding: "0px",
  border: "none",
  color: "white",
});

const MainContainer = styled(Container)({
  width: "962px",
  background: "#E5E5E5",
  padding: "0 0 30px 0",
});

const ImageContainer = styled(Container)({
  width: "100%",
  height: "23px",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  padding: "0 10px",
});

const LogoContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CloseImage = styled("img")({
  width: "13px",
  height: "13px",
});

const LogoImage = styled("img")({});

const HeadingContainer = styled(Container)({
  width: "842px",
  padding: "0px",
  textAlign: "center",
});

const Heading = styled("p")({
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "20px",
  margin: "5px 0 0 0",
});

const SubHeadingContainer = styled(Container)({
  width: "800px",
  padding: "0px",
  textAlign: "center",
});

const SubHeading = styled("p")({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  margin: "13px 0 0 0",
});

const FormContainer = styled("div")({
  marginTop: "30px",
  marginBottom: "30px",
  display: "flex",
  justifyContent: "space-between",
});

const FormLeftContainer = styled("div")({
  width: "375px",
  marginLeft: "50px",
});

const FormRightContainer = styled("div")({
  width: "382px",
});

const DevicesImage = styled("img")({});

const FooterMainContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const Footer = styled("div")({});

const FooterQuestion = styled("p")({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "20px",
  margin: "0px",
});

const FooterButtonContainer = styled("p")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
});

const ButtonText = styled("p")({
  fontSize: "16px",
  fontWeight: "700",
  margin: "0px",
  padding: "0px",
});

export default SubscriptionLayout;
