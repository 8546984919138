import { HeadingMedium, Label1 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { CumulativeCalculator } from "../../calculations/social/CumulativeCalculator";
import ScenarioDataBuilder from "../../calculations/social/ScenarioBuilder";
import { formatter, formatterDecimal } from '../../calculations/SocialSecurityApi';
import {
    getMaritalStatus
} from '../../calculations/userDataTransformer';
import GraphTick from './GraphTick';


export const CumulativeJointGraph = ({ mainUserName, spouseUserName, projections, maritalStatus, width = 800 }) => {
    const height = Math.max(270, (3 / 8) * width);

    if (projections.empty) {
        return <div style={{ width: '100%', justifyContent: 'center', margin: 0 }}>
            <HeadingMedium>Cumulative Projections</HeadingMedium>
            <Label1>Select an option to start comparing your options</Label1>
        </div>
    }


    return <div style={{ width: '100%', justifyContent: 'center', margin: 0 }}>
        <HeadingMedium>Cumulative Projections</HeadingMedium>

        <Label1>Below is a chart with the cumulative amount we project you will receive, based on the options you have chosen.</Label1>
        <br></br>
        <BarChart
            width={width - 20}
            height={height}
            data={projections.graphData}
            margin={{
                top: 5, right: 10, left: 10, bottom: 25,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" tick={<GraphTick />} height={50} />
            <YAxis tickFormatter={(value) => '$' + formatterDecimal.format(value) + 'M'} />
            <Tooltip formatter={(value) => '$' + formatterDecimal.format(value) + 'M'} />
            <Legend margin={{ top: '5px' }} />
            <Bar dataKey="mainOption" fill="#8884d8" name={mainUserName + " - $" + formatter.format(projections.mainMonthly) + "/yr"} stackId={1} />
            {maritalStatus === 'married' ? <Bar dataKey="spouseOption" fill="#a832a6" name={spouseUserName + " - $" + formatter.format(projections.spouseMonthly) + "/yr"} stackId={1} /> : undefined}
            {maritalStatus === 'widowed' ? <Bar dataKey="widowedOption" fill="#82ca9d" name={"Survivor Benefit - $" + formatter.format(projections.survivorYearly) + "/yr"} stackId={1} /> : undefined}
        </BarChart>
    </div>
}

export default connect(state => {
    let scenarioBuilder = new ScenarioDataBuilder(state.userData.answers);
    let maritalStatus = getMaritalStatus(state.userData.answers);
    let cumulativeCalculator = new CumulativeCalculator(scenarioBuilder);

    return {
        mainUserName: state.userData.answers.mainUserName,
        spouseUserName: state.userData.answers.spouseUserName,
        projections: cumulativeCalculator.buildProjections(),
        maritalStatus
    }
}, {})(CumulativeJointGraph);