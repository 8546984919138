import firebase from "firebase";
import Firebase from './Firebase';

class BillingApi {
    async purchase(stripe) {
        //Let our backend figure out what kind of payment this needs to be
        let sessionResponse = await Firebase.fetch('/stripe/startPaymentSession', {
            method: 'POST'
        });

        if (!sessionResponse.sessionId) {
            throw new Error('Failed communicating with payment system. Please try later')
        }
        console.log(sessionResponse)

        return await stripe.redirectToCheckout({ sessionId: sessionResponse.sessionId });
    }

    async getStripeDetails(detail) {
        try {
            let details = await firebase.functions().httpsCallable(detail)();
            return details;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getPortalLink() {
        let portal = await Firebase.fetch('/stripe/portalLink', {
            method: 'POST'
        });
        window.location.href = portal.url;
    }

    async paidBefore() {
        try {
            let billingDoc = await firebase.firestore().collection('billing').doc(firebase.currentUser.claims.uid).get()
            return billingDoc.data();
        } catch (e) {
            return Promise.reject(e);
        }
    }
}

export default new BillingApi();
