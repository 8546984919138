import { Button } from "baseui/button";
import { Card } from 'baseui/card';
import { FileUploader } from 'baseui/file-uploader';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {
    ListItem,
    ListItemLabel
} from "baseui/list";
import { KIND, Notification } from "baseui/notification";
import { StatefulSelect as Search, TYPE } from 'baseui/select';
import { HeadingXSmall, Label2 } from 'baseui/typography';
import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { setAllData } from '../actions/userData';
import UserDetails from '../components/admin/UserDetails';
import ToolBar from '../components/Toolbar';
import UserApi from '../firebase/UserApi';


export const AdminPage = ({ setAllData }) => {
    const [user, setUser] = React.useState([]);
    const [admins, setAdmins] = React.useState([]);
    const [crashes, setCrashes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [showUpload, setShowUpload] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);

    const [options, setOptions] = React.useState([]);

    function startProgress() {
        setIsUploading(true);
        timeoutId.current = setTimeout(reset, 4000);
    }

    function copyUploadedDataToMyData(uploadedJSON) {
        uploadedJSON.text().then(value =>
            // console.log(typeof (JSON.parse(value))))
            UserApi.submitData(JSON.parse(value)).then(() => {
                window.alert("Successfully set user data to match data from " + uploadedJSON.name)
                window.location.reload()
            }).catch((err) => {
                window.alert("Unable to load data from " + uploadedJSON.name)
                console.error(err);
            }));
    }


    React.useEffect(() => {
        UserApi.getAdmins()
            .then(data => setAdmins(data))
            .catch(e => setError(e.message))
    }, [])

    let setUserSelected = (userSelection) => {
        setUser(userSelection)
        setCrashes([]);
        if (userSelection.length > 0) {
            UserApi.getCrashReports(userSelection[0].id).then(e => setCrashes(e)).catch(e => setError(e));
        }
    }

    const handleSetOptions = (users) => {
        if (!users) return;
        setOptions(users.map(([id, email]) => ({ label: `${email}`, id })));
    }

    const debounce = (func, delay) => {
        let timeoutId;
        const debounced = (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        }
        return debounced;
    }

    const timeoutId = React.useRef(null);

    function reset() {
        setIsUploading(false);
        clearTimeout(timeoutId.current);
    }

    const handleInputChange = debounce(keyword => {
        if (!keyword) {
            setOptions([]);
            return;
        }
        setIsLoading(true);
        UserApi.search(keyword)
            .then(handleSetOptions)
            .catch(e => setError(e.message))
            .finally(() => setIsLoading(false));
    }, 400);

    return <div>
        <ToolBar />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: 1200, width: 1200 }}>
                <FlexGrid gridRowStart={300} flexGridColumnCount={3} flexGridColumnGap='scale100'>
                    <FlexGridItem >
                        <Card title='Search for User...'>
                            <Search
                                value={user}
                                isLoading={isLoading}
                                options={options}
                                type={TYPE.search}
                                onInputChange={({ target }) => handleInputChange(target.value)}
                                onChange={({ value }) => setUserSelected(value)} />
                            <UserDetails userDetails={user.length > 0 ? user[0] : {}} />
                            {error ? <Notification notificationType={'toast'} kind={KIND.negative}>{error}</Notification> : undefined}
                        </Card>
                    </FlexGridItem>
                    <FlexGridItem>
                        <HeadingXSmall>Crashes</HeadingXSmall>
                        <ul>
                            {crashes.map(e => <ListItem>
                                <ListItemLabel description={firebase.firestore.Timestamp.fromMillis(e.when.seconds * 1000).toDate().toString()}>
                                    {e.stackTrace}
                                </ListItemLabel></ListItem>)}
                        </ul>
                    </FlexGridItem>
                    <FlexGridItem>
                        <Label2>Admins:</Label2>
                        <ul style={{
                            width: '375px',
                            paddingLeft: 0,
                            paddingRight: 0,
                        }}>
                            {
                                admins.map(adminUser => <ListItem>
                                    <ListItemLabel>{adminUser.email}</ListItemLabel>
                                    <Button onClick={() => UserApi.removeAdmin(adminUser.id).catch(e => setError(e.message))}>Remove Admin</Button>
                                </ListItem>)
                            }
                        </ul>
                    </FlexGridItem>
                    <FlexGridItem>
                        Download
                        <Button onClick={() => UserApi.getAllUsers().then(e => console.log(e)).catch(e => setError(e.message))}>Get All Users</Button>
                    </FlexGridItem>
                    <FlexGridItem>

                        <div>
                            <Button style={{ width: '150px' }}
                                onClick={() => {
                                    setShowUpload(true);
                                    window.alert("NOTE: This will overwrite your current user data!");
                                }}>
                                Import Data
         </Button>
                        </div>
                        {
                            showUpload ?
                                <FileUploader
                                    accept='application/json'
                                    onCancel={reset}
                                    onDrop={(acceptedFiles, rejectedFiles) => {
                                        // show some uploading UI
                                        startProgress();
                                    }}
                                    onDropAccepted={(acceptedFiles, rejectedFiles) => {
                                        reset();
                                        copyUploadedDataToMyData(acceptedFiles[0]); // only do the first one since we can't have more than 1 user.json anyways
                                    }}
                                    progressMessage={
                                        isUploading ? `Uploading... hang tight.` : ''
                                    }
                                /> : null
                        }
                    </FlexGridItem>
                </FlexGrid>
            </div>
        </div>
    </div>
}

export default connect(state => ({}), { setAllData })(AdminPage);