import { Block } from 'baseui/block';
import { Button, SIZE } from 'baseui/button';
import Alert from 'baseui/icon/alert';
import { PLACEMENT, Popover, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Label1, Label2, Label3, Label4, ParagraphXSmall } from 'baseui/typography';
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setAnswer } from "../../actions/userData";
import SocialSecurityCalculator from '../../calculations/SocialSecurityApi';
import { getMaritalStatus } from "../../calculations/userDataTransformer";
import { formatter } from '../../calculations/SocialSecurityApi';

export const EarningTestDetaillTable = ({ exemptionAmount, benefitAmount, earnedIncome }) => {
    return <>
        <div className="b4">
            <table style={{ borderCollapse: 'collapse', width: '100%', border: "1px solid lightgrey" }}>
                <tbody>
                    <tr>
                        <th></th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Jan</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Feb</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Mar</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Apr</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>May</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Jun</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>July</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Aug</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Sep</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Oct</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Nov</th>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Dec</th>
                    </tr>
                    <tr>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Benefit</th>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                    </tr>
                    <tr>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left", color: "red" }}>Earnings Penalty</th>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format((earnedIncome - exemptionAmount.preFRA) * 0.5)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 2)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 3)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 4)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 5)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>${formatter.format(((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 6)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}></td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}></td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}></td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}></td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}></td>
                    </tr>
                    <tr>
                        <th style={{ padding: 10, border: '1px solid lightgrey', textAlign: "left" }}>Benefit</th>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center", color: "red" }}>0</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount - (((earnedIncome - exemptionAmount.preFRA) * 0.5) - benefitAmount * 6))}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                        <td style={{ padding: 10, border: '1px solid lightgrey', textAlign: "center" }}>${formatter.format(benefitAmount)}</td>
                    </tr>
                </tbody>
            </table >
        </div>
    </>
}

export default connect(state => ({
    answers: state.userData.answers,
    maritalStatus: getMaritalStatus(state.userData.answers),
    currentYear: moment().year(),
    benefitAmount: 2000,
    earnedIncome: 50000,
    exemptionAmount: new SocialSecurityCalculator('main', state.userData.answers).getExemptionAmounts(moment().year())
}), {
    setAnswer
})(EarningTestDetaillTable);

