import UserApi from "../firebase/UserApi";

export const SET_LOADING = 'SET_LOADING';
export const SET_BILLING_STATUS = 'SET_BILLING_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const START_CONFIRM_MODAL = 'START_CONFIRM_MODAL';
export const END_CONFIRM_MODAL = 'END_CONFIRM_MODAL';


export const setAdminMode = (adminMode, impersonating) => {
    return async function (dispatch, getState) {
        dispatch(setUserDataLoading(true));
        if (adminMode) {
            await UserApi.impersonate(impersonating.id);
        } else {
            await UserApi.unimpersonate();
        }
    }
}

export const setMenuOpen = (open) => ({
    type: SET_MENU_OPEN,
    open
})

export const setUserDataLoading = (loading) => ({
    type: SET_LOADING,
    loading
});

export const setRecoverableError = (error) => {
    return function (dispatch) {
        dispatch({ type: SET_ERROR, error });
    }
}

export const setIrrecoverableError = (error) => {
    return function (dispatch) {
        dispatch({ type: SET_ERROR, error });
    }
}

export const setBillingStatus = (billing) => ({
    type: SET_BILLING_STATUS,
    billing
});

export const startConfirmModal = (text, actionToDispatch, actionToDispatchCancel) => ({
    type: START_CONFIRM_MODAL,
    text,
    actionToDispatch,
    actionToDispatchCancel
});

export const finishConfirmModal = (applyAction) => {
    return function (dispatch, getState) {
        if (applyAction) {
            let dispatchObject = getState().ui.confirmingAction.actionToDispatch;
            dispatch(dispatchObject)
        } else {
            let dispatchCancelObject = getState().ui.confirmingAction.actionToDispatchCancel;
            if (dispatchCancelObject) {
                dispatch(dispatchCancelObject)
            }
        }

        dispatch({
            type: END_CONFIRM_MODAL,
        });
    }
}
