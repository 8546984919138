import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import {
    arrayMove,
    arrayRemove, List
} from "baseui/dnd-list";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import Check from 'baseui/icon/check';
import { Input, SIZE } from 'baseui/input';
import { HeadingSmall, Label1, Label2, Label4, Paragraph3 } from 'baseui/typography';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { setAnswer, setFlowStatus, submitData } from '../../actions/userData';
import { scrollTop } from '../../animation/scrollTo';
import { getMaritalStatus } from '../../calculations/userDataTransformer';
import ArrowButton from '../../onboard/ArrowButton';
import { LIVING_LEDGER_LINK, ORDER_OF_WITHDRAWAL_LINK, SOCIAL_CHOICE_LINK } from '../flows/linkConstants';
import CashFlow from '../ledger/CashFlow';

const CustomDragHandle = () => {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                marginRight: '1em',
                width: '24px',
                display: 'flex',
                alignItems: 'center',
            })}
        >
            <Check size={24} color="#CCC" />
        </div>
    );
};

const mapFromGenerics = (answers, items) => {

    const getUI = (index, text, taxed) => {
        let options = ['2nd', '3rd', '4th', '5th'];
        return <div style={{ display: 'flex' }}>
            <div style={{ width: 15 }}>{options[index]}</div>
            <div style={{ width: '100', margin: 'auto' }}>{text}</div>
            <div style={{ width: 25, flexDirection: 'row-reverse' }}>{taxed ? 'Yes' : 'No'}</div>
        </div>
    }

    let x = items.map((elem, index) => {
        switch (elem) {
            case 'mainRetirement':
                return getUI(index, answers.mainUserName + " Retirement", true);
            case 'mainRoth':
                return getUI(index, answers.mainUserName + " Roth", false);
            case 'spouseRetirement':
                return getUI(index, answers.spouseUserName + " Retirement", true);
            case 'spouseRoth':
                return getUI(index, answers.spouseUserName + " Roth", false);
        }
    })

    return x;
}

const ReconcilePicker = ({ answers, setAnswer }) => {
    const defaultItems = [
        'mainRetirement',
        'mainRoth',
    ];

    if (getMaritalStatus(answers) === 'married') {
        defaultItems.push('spouseRetirement')
        defaultItems.push('spouseRoth')
    }

    const getUI = ({ index, text, taxed }) => {
        return [<div style={{ display: 'flex' }}>
            <div style={{ width: 40 }}>1st</div>
            <div style={{ width: '100', margin: 'auto' }}><Label1>Above the minimum balance amount</Label1></div>
            <Label4 style={{ width: 75, marginRight: -15, flexDirection: 'row-reverse' }}>Above Cost Basis Only</Label4>
        </div>]
    }

    const [firstItem,] = React.useState(["Combined Non-Retirement (Always First)"]);
    const [items, setItems] = React.useState(
        answers.cashFlowOrder === undefined ? defaultItems : [...answers.cashFlowOrder]);
    const [minCash, setMinCash] = React.useState(answers.minCashAmount === undefined ? 5000 : answers.minCashAmount);
    const history = useHistory();

    React.useEffect(() => {
        if (!answers['cashFlowOrder']) {
            updateItems(defaultItems)
        }

        if (!answers['minCashAmount']) {
            setMinimumCash(5000)
        }
    }, [])

    const setMinimumCash = (amount) => {
        setMinCash(amount)
        setAnswer('minCashAmount', amount)
    }

    const updateItems = (items) => {
        setItems(items)
        setAnswer('cashFlowOrder', items)
    }

    let uiItems = getUI(firstItem)

    return (
        <div style={{ width: '100%' }}>
            <div style={{ maxWidth: 800, margin: 'auto', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}><HeadingSmall margin='10px'>Reconcile Accounts</HeadingSmall></div>

                <FlexGrid gridRowStart={15} flexGridColumnCount={2} flexGridColumnGap='scale90' flexGridRowGap={20} marginLeft="scale1000" marginRight="scale1000" marginTop="scale50" marginBottom="scale1000" maxWidth="800px">
                    <FlexGridItem>
                        <Paragraph3>
                            When there is NET Income from your annual Cash Flow, we deposit it into your “non-retirement” account; when there is a shortfall we withdraw from the account accordingly to supplement your income.
                </Paragraph3>
                        <Paragraph3>
                            What is the minimum balance you want to maintain in your “non-retirement” account, which
                            includes your bank account, before we withdraw any shortfall from other accounts?
                </Paragraph3>
                        <Input
                            overrides={{
                                Root: {
                                    style: ({
                                        $theme,
                                    }) => {
                                        return {
                                            maxWidth: "250px"
                                        };
                                    }
                                }
                            }}
                            value={minCash || ''}
                            onChange={(e) => setMinimumCash(e.target.value)}
                            name='minCashInputField'
                            size={SIZE.compact}
                            type='number'
                            endEnhancer='.00'
                            startEnhancer='$'
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Paragraph3>
                            If your “non-retirement” account reaches your minimum balance the default order of accounts
                            that we withdraw from to supplement your income is below. You can change the order at any
                            time.
                        <br /><br />
                        To change the order we draw a shortfall from, simply drag the account box to the position
                        you choose for that account.
                </Paragraph3>
                        <Button style={{ backgroundColor: "#eda107", color: "#FFFFFF" }} onClick={() => updateItems(defaultItems)}>Reset to Defaults</Button>
                        <br />
                        <br />
                        <div style={{ display: 'flex', maxWidth: "400px" }}>
                            <div style={{ width: 60 }}></div>
                            <Label2 style={{ width: 25, marginRight: 30 }}>Order</Label2>
                            <Label2 style={{ width: '100px', margin: 'auto' }}>Accounts</Label2>
                            <Label2 style={{ width: 60, flexDirection: 'row-reverse' }}>Taxed</Label2>
                        </div>
                        <List
                            items={uiItems}
                            overrides={{
                                DragHandle: CustomDragHandle,
                                Root: {
                                    style: ({
                                        $theme,
                                    }) => {
                                        return {
                                            maxWidth: "400px"
                                        };
                                    }
                                },
                                Item: {
                                    style: ({
                                        $theme,
                                    }) => {
                                        return {
                                            backgroundColor: '#A9A9A9'
                                        };
                                    }
                                },
                                Label: {
                                    style: ({
                                        $theme,
                                    }) => {
                                        return {
                                            color: '#FFFFFF'
                                        };
                                    }
                                },
                            }}
                        />
                        <List
                            items={mapFromGenerics(answers, items)}
                            onChange={({ oldIndex, newIndex }) => {
                                updateItems(
                                    newIndex === -1
                                        ? arrayRemove(items, oldIndex)
                                        : arrayMove(items, oldIndex, newIndex)
                                )
                            }}
                            overrides={{
                                Root: {
                                    style: ({
                                        $theme,
                                    }) => {
                                        return {
                                            maxWidth: "400px"
                                        };
                                    }
                                },
                            }}
                        />
                        <Paragraph3 color='#FF0000'>
                            Note: Taxes are calculated and included with any withdrawals from Retirement Accounts
                </Paragraph3>
                    </FlexGridItem>
                    <FlexGridItem>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', margin: '15px 0px' }}>
                            <ArrowButton
                                action={() => {
                                    scrollTop();
                                    history.push(SOCIAL_CHOICE_LINK);
                                    submitData()
                                }}
                                disabled={false}
                                type='left'
                            />
                            <ArrowButton
                                action={() => {

                                    setFlowStatus({ name: 'choice', status: 'finished' })
                                    setFlowStatus({ name: 'cash-flow', status: 'finished' })
                                    setFlowStatus({ name: 'order-of-withdrawal', status: 'finished' })
                                    setFlowStatus({ name: 'livingledger', status: 'pending' })
                                    scrollTop();
                                    history.push(LIVING_LEDGER_LINK);
                                    submitData()
                                }}
                                message={'Next'}
                                disabled={false}
                                type='right'
                            />
                        </div>
                    </FlexGridItem>
                </FlexGrid >
            </div>
        </div>
    );
}

const CashFlowFlow = ({ answers, subTab, setAnswer }) => {

    if (subTab === 'order-of-withdrawal') {
        return <ReconcilePicker answers={answers} setAnswer={setAnswer} />
    } else if (subTab === 'livingledger') {
        return <CashFlow />
    }
    return <Redirect to={ORDER_OF_WITHDRAWAL_LINK} />
}

export default connect(state => {
    return {
        answers: state.userData.answers
    }
}, {
    setFlowStatus,
    setAnswer,
    submitData,
})(CashFlowFlow);
