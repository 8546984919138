import Account from "../Account";
import {
    EMPLOYMENT_INCOME_LINK,
    ORDER_OF_WITHDRAWAL_LINK,
    SOCIAL_CHOICE_LINK
} from "../flows/linkConstants";
import { defaultFlowValidator } from "../flows/validate/defaultValidator";
import PortfolioFlow from "../interrogate/AssetFlow";
import CashFlowFlow from "../interrogate/CashFlowFlow";
import ExpenseFlow, { flows } from "../interrogate/ExpenseFlow";
import IncomeFlow, { makeFlows } from "../interrogate/IncomeFlow";
import OnboardingFlowView, { profileQuestions } from "../interrogate/ProfileFlow";
import SocialSecurityFlow, { makeFlows as makeSSFlows } from "../interrogate/SocialSecurityFlow";



export const Pages = (subTab) => ({
    'profile': {
        Component: OnboardingFlowView,
        validator: (answers) => defaultFlowValidator(profileQuestions, undefined, answers),
    },
    'expenses': {
        defaultProps: {
            subTab: subTab
        },
        Component: ExpenseFlow,
        validator: (answers) => {
            let pageData = flows(answers)[subTab];
            return defaultFlowValidator(pageData.questionData, pageData.validator, answers)
        }
    },
    'social-security': {
        defaultProps: { subTab },
        Component: SocialSecurityFlow,
        validator: (answers) => {
            let pageData = makeSSFlows(answers)[subTab];
            return defaultFlowValidator(pageData.questionData, undefined, answers);
        }
    },
    'income': {
        defaultProps: { subTab },
        Component: IncomeFlow,
        validator: (answers) => {
            let pageData = makeFlows(answers)[subTab];
            return defaultFlowValidator(pageData.questionData, pageData.validator, answers)
        },
        defaultLink: EMPLOYMENT_INCOME_LINK,
    },
    'portfolio': {
        Component: PortfolioFlow,
        validator: () => console.log('yeet')
    },
    'cash-flow': {
        defaultProps: { subTab },
        Component: CashFlowFlow,
        validator: (answers) => {
            console.log('yeet')
        },
        defaultLink: ORDER_OF_WITHDRAWAL_LINK,
    },
    'account': {
        Component: Account,
        validator: () => console.log('yeet')
    }
})